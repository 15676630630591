import { PayloadAction } from '@reduxjs/toolkit';
import { AseguradoOferta } from '../../types/offers/asegurado_oferta';
import { RootState } from '../store';

export const selectCapitalModification = (state: RootState) =>
  state.capitalModification;

export const selectCapitalModificationAsegurado = (
  state: RootState,
  action: PayloadAction<AseguradoOferta>
) =>
  state.capitalModification ? state.capitalModification[action.payload] : null;
