import { CInputs } from 'ibs-cengine/dist/types/Calculator';
import { ModificacionCapitales } from '../../types/offers/tabulated_offers';
import {
  Asegurado,
  Conyuge,
  Hijo,
} from '../../valoracion/types/dataCollection/dataCollection';
import { CargasFamiliares } from '../../valoracion/types/familyResponsabilities/familyResponsabilites';
import { CapitalModificationState } from '../capitalModification/capitalModificationSlice';
import { InputData } from './sagas';
import { transformPath } from './util';
import { CodPeriodicidadEnum, CodRevalorizacionEnum } from '../../types/offers/offers';

export function toCInputs(data: InputData): CInputs {
  const c_inputs: CInputs = {};
  if (data.principal.necesidades === 0) {
    return c_inputs;
  }
  fillFamilyData(c_inputs, data);
  fillInsuredData(c_inputs, data.principal);
  fillConyugeData(c_inputs, data.conyuge);
  fillOtrosDatos(c_inputs, data.principal);
  fillChildrenData(c_inputs, data.hijos);
  fillCapitals(c_inputs, data.modificacion_capitales);
  fillCargas(c_inputs, data.cargas);
  fillDiscounts(c_inputs, data.modificacion_capitales);
  return c_inputs;
}

function fillFamilyData(c_inputs: CInputs, data: InputData) {
  const asegurado = data.principal;
  const hijos = data.hijos;
  const conyuge = data.conyuge;
  //datos de la familia
  let estado_civil = 'S';
  switch (asegurado.estado_civil) {
    case 'casado':
      estado_civil = 'C';
      break;
    case 'soltero':
      estado_civil = 'S';
      break;
    case 'separado':
      estado_civil = 'SP';
      break;
    case 'viudo':
      estado_civil = 'V';
      break;
    case 'pareja_hecho':
      estado_civil = 'P';
      break;
  }
  c_inputs[transformPath('.a.estado_civil')] = estado_civil;
  c_inputs[transformPath('.a.gme')] = asegurado.necesidades
    ? asegurado.necesidades.toString()
    : '';
  c_inputs[transformPath('.a.necesidades_compartidas')] =
    conyuge.necesidades_compartidas ? '1' : '0';
  if (conyuge.necesidades_compartidas) {
    c_inputs[transformPath('.a.pct_conyuge')] = conyuge.aportacion_porcentaje
      ? conyuge.aportacion_porcentaje.toString()
      : '';
  }
  c_inputs[transformPath('.a.n_hijos')] = hijos.length.toString();
}

function fillInsuredData(c_inputs: CInputs, asegurado: Asegurado) {
  //datos del asegurado principal
  c_inputs[transformPath('.a.p.d.sexo')] =
    asegurado.sexo === 'hombre' ? 'h' : 'm';
  c_inputs[transformPath('.a.p.d.nombre')] = asegurado.nombre;
  c_inputs[transformPath('.a.p.d.apellidos')] =
    asegurado.apellido1 +
    (asegurado.apellido2 ? ' ' + asegurado.apellido2 : '');
  c_inputs[transformPath('.a.p.d.profesion')] =
    asegurado.profesion_id != null && asegurado.profesion_id >= 0
      ? asegurado.profesion_id.toString()
      : '';
  c_inputs[transformPath('.a.p.d.nacimiento')] = asegurado.fecha_nacimiento
    ? asegurado.fecha_nacimiento.toString()
    : '';
  c_inputs[transformPath('.a.p.d.fumador')] = asegurado.fumador ? '1' : '0';
  c_inputs[transformPath('.a.p.d.estatura')] = asegurado.altura
    ? asegurado.altura.toString()
    : '';
  c_inputs[transformPath('.a.p.d.peso')] = asegurado.peso
    ? asegurado.peso.toString()
    : '';
  c_inputs[transformPath('.a.p.d.moto')] = asegurado.moto ? '1' : '0';
  c_inputs[transformPath('.a.p.d.moto_cil')] = asegurado.cilindrada
    ? asegurado.cilindrada
    : '';
  fillIncomeData(c_inputs, asegurado);
}

function fillOtrosDatos(c_inputs: CInputs, asegurado: Asegurado) {
  c_inputs[transformPath('.a.revalorizacion')] = asegurado.revalorizacion || CodRevalorizacionEnum.SIN_REVALORIZACION;
  c_inputs[transformPath('.a.periodicidad')] = asegurado.periodicidad || CodPeriodicidadEnum.ANUAL;
}

function fillConyugeData(c_inputs: CInputs, conyuge: Conyuge) {
  //datos del cónyuge
  c_inputs[transformPath('.a.c.d.sexo')] =
    conyuge.sexo === 'hombre' ? 'h' : 'm';
  c_inputs[transformPath('.a.c.d.nombre')] = conyuge.nombre;
  c_inputs[transformPath('.a.c.d.apellidos')] =
    conyuge.apellido1 + (conyuge.apellido2 ? ' ' + conyuge.apellido2 : '');
  c_inputs[transformPath('.a.c.d.profesion')] =
    conyuge.profesion_id != null && conyuge.profesion_id >= 0
      ? conyuge.profesion_id.toString()
      : '';
  c_inputs[transformPath('.a.c.d.nacimiento')] = conyuge.fecha_nacimiento
    ? conyuge.fecha_nacimiento.toString()
    : '';
  c_inputs[transformPath('.a.c.d.edad_u')] = conyuge.edad
    ? conyuge.edad.toString()
    : '0';
  c_inputs[transformPath('.a.c.d.fumador')] = conyuge.fumador ? '1' : '0';
  c_inputs[transformPath('.a.c.d.estatura')] = conyuge.altura
    ? conyuge.altura.toString()
    : '';
  c_inputs[transformPath('.a.c.d.peso')] = conyuge.peso
    ? conyuge.peso.toString()
    : '';
  c_inputs[transformPath('.a.c.d.moto')] = conyuge.moto ? '1' : '0';
  c_inputs[transformPath('.a.c.d.moto_cil')] = conyuge.cilindrada
    ? conyuge.cilindrada
    : '';
  fillIncomeData(c_inputs, conyuge);
}

function fillIncomeData(c_inputs: CInputs, person: Asegurado | Conyuge) {
  let who = 'necesidades_compartidas' in person ? 'c' : 'p';
  const cotizaRG =
    person.cotizacion.rg.importe || person.cotizacion.rg.porcentaje;
  const cotizaRETA =
    person.cotizacion.reta.cuota || person.cotizacion.reta.base_cotizacion;
  const initDate = person.cotizacion.fecha_alta;

  if (initDate) {
    c_inputs[transformPath('.a.' + who + '.d.alta_ss')] = initDate.toString();
  }
  if (cotizaRG) {
    c_inputs[transformPath('.a.' + who + '.i.cotiza_rg')] = '1';
    if (person.cotizacion.rg.importe) {
      c_inputs[transformPath('.a.' + who + '.i.rg.importe_u')] =
        person.cotizacion.rg.importe.toString();
    }
    if (person.cotizacion.rg.porcentaje) {
      c_inputs[transformPath('.a.' + who + '.i.rg.pct_u')] =
        person.cotizacion.rg.porcentaje.toString();
    }
  } else {
    c_inputs[transformPath('.a.' + who + '.i.cotiza_rg')] = '0';
  }
  if (cotizaRETA) {
    c_inputs[transformPath('.a.' + who + '.i.cotiza_reta')] = '1';
    if (person.cotizacion.reta.cuota) {
      c_inputs[transformPath('.a.' + who + '.i.reta.cuota_u')] =
        person.cotizacion.reta.cuota.toString();
    }
    if (person.cotizacion.reta.base_cotizacion) {
      c_inputs[transformPath('.a.' + who + '.i.reta.bc_u')] =
        person.cotizacion.reta.base_cotizacion.toString();
    }
  } else {
    c_inputs[transformPath('.a.' + who + '.i.cotiza_reta')] = '0';
  }
  if (person.cotizacion.otros.importe) {
    c_inputs[transformPath('.a.' + who + '.i.otros.importe')] =
      person.cotizacion.otros.importe.toString();
    c_inputs[transformPath('.a.' + who + '.i.otros.cotiza')] = person.cotizacion
      .otros.cotiza
      ? '1'
      : '0';
  }
}

function fillChildrenData(c_inputs: CInputs, children: Hijo[]) {
  children.forEach((child, i) => {
    if (child.edad !== null) {
      c_inputs[`.0.a.0.h.${i}.edad_u`] = child.edad.toString();
    }
    if (child.fecha_nacimiento !== null) {
      c_inputs[`.0.a.0.h.${i}.nacimiento`] = child.fecha_nacimiento;
    }
    c_inputs[`.0.a.0.h.${i}.nombre`] = child.nombre;
    c_inputs[`.0.a.0.h.${i}.apellidos`] =
      child.apellido1 + (child.apellido2 ? ' ' + child.apellido2 : '');
  });
}

function fillCapitals(
  c_inputs: CInputs,
  modificacionCapitales: CapitalModificationState
) {
  Object.keys(modificacionCapitales).forEach((name) => {
    if (name === 'applyGuarantees') {
      fillAplicar(c_inputs, modificacionCapitales.applyGuarantees);
    } else if (name === 'principal') {
      fillAplicarGarantias(c_inputs, modificacionCapitales.principal, 'p');
    } else if (name === 'conyuge') {
      fillAplicarGarantias(c_inputs, modificacionCapitales.conyuge, 'c');
    }
  });
}

function fillDiscounts(
  c_inputs: CInputs,
  modificacionCapitales: CapitalModificationState
) {
  const people: ('p' | 'c')[] = [];

  if (
    modificacionCapitales.principal.pct_desc_com ||
    modificacionCapitales.principal.pct_desc_col ||
    modificacionCapitales.principal.pct_desc_tec
  ) {
    people.push('p');
  }
  if (
    modificacionCapitales.conyuge.pct_desc_com ||
    modificacionCapitales.conyuge.pct_desc_col ||
    modificacionCapitales.conyuge.pct_desc_tec
  ) {
    people.push('c');
  }

  const descuentoComercial = Number(
    modificacionCapitales.principal.pct_desc_com ||
      modificacionCapitales.conyuge.pct_desc_com ||
      0
  );
  const descuentoTecnico = Number(
    modificacionCapitales.principal.pct_desc_tec ||
      modificacionCapitales.conyuge.pct_desc_tec ||
      0
  );
  const descuentoColectivo = Number(
    modificacionCapitales.principal.pct_desc_col ||
      modificacionCapitales.conyuge.pct_desc_col ||
      0
  );

  people.forEach((person) => {
    // descuento comercial
    if (descuentoComercial) {
      c_inputs[transformPath(`.o.${person}.w12.resumen.pct_desc_com`)] =
        descuentoComercial.toString();
    }
    // descuento colectivo
    if (descuentoColectivo) {
      c_inputs[transformPath(`.o.${person}.w12.resumen.pct_desc_col`)] =
      descuentoColectivo.toString();
    }
    // descuento técnico
    if (descuentoTecnico) {
      c_inputs[transformPath(`.o.${person}.w12.f.cc.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.f.a.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.f.ac.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.i.pa.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.i.paa.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.i.pac.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.i.pt.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.o.it.pct_desc_tec`)] =
        descuentoTecnico.toString();
      c_inputs[transformPath(`.o.${person}.w12.o.eg.pct_desc_tec`)] =
        descuentoTecnico.toString();
    }
  });
}

function fillAplicarGarantias(
  c_inputs: CInputs,
  importesCapitales: ModificacionCapitales,
  who: string
) {
  Object.keys(importesCapitales).forEach((name) => {
    const value = importesCapitales[name];
    if (value !== undefined) {
      c_inputs[capitalPathByName(name, who)] = value.toString();
    }
  });
}

export function capitalPathByName(name: string, who: string = 'p'): string {
  switch (name) {
    case '501.capital_fijo':
      return transformPath(`.o.${who}.w12.f.cc.cfi.ci_m`);
    case '501.viudedad.renta':
      return transformPath(`.o.${who}.w12.f.cc.v.renta_mensual_u`);
    case '501.viudedad.duracion':
      return transformPath(`.o.${who}.w12.f.cc.v.duracion_u`);
    case '501.orfandad.renta':
      return transformPath(`.o.${who}.w12.f.cc.o.rm_u`);
    case '501.orfandad.duracion':
      return transformPath(`.o.${who}.w12.f.cc.o.anos_u`);
    case '503.capital':
      return transformPath(`.o.${who}.w12.f.a.cf_u`);
    case '504.capital':
      return transformPath(`.o.${who}.w12.f.ac.cf_u`);
    case '502.capital_fijo':
      return transformPath(`.o.${who}.w12.i.pa.capital.cf`);
    case '502.capital derivado de renta mensual.renta':
      return transformPath(`.o.${who}.w12.i.pa.rm.mensual_u`);
    case '502.capital derivado de renta mensual.duracion':
      return transformPath(`.o.${who}.w12.i.pa.rm.anos_u`);
    case '510.capital':
      return transformPath(`.o.${who}.w12.i.paa.cf_u`);
    case '511.capital':
      return transformPath(`.o.${who}.w12.i.pac.cf_u`);
    case '509.capital':
      return transformPath(`.o.${who}.w12.i.pt.cf_u`);
    case '516.renta_diaria':
      return transformPath(`.o.${who}.w12.o.it.diaria_u`);
    case '516.franquicia':
      return transformPath(`.o.${who}.w12.o.it.franquicia`);
    case '507.capital':
      return transformPath(`.o.${who}.w12.o.eg.cf_u`);
    case '5FJ.capital':
      return transformPath(`.o.${who}.w12.f.con.cf`);
    default:
      return '';
  }
}

function fillBoth(c_inputs: CInputs, path: string, value: string) {
  c_inputs[transformPath(path.replaceAll('{who}', 'p'))] = value;
  c_inputs[transformPath(path.replaceAll('{who}', 'c'))] = value;
}

function fillAplicar(c_inputs: CInputs, aplicar: ModificacionCapitales) {
  Object.keys(aplicar).forEach((name) => {
    const value = aplicar[name];
    if (value !== undefined) {
      fillBoth(c_inputs, aplicaPathByName(name), value ? '1' : '0');
    }
  });
}

export function aplicaPathByName(name: string): string {
  switch (name) {
    case '501.viudedad.renta_aplicar':
      return `.o.{who}.w12.f.cc.v.desea_aplicar`;
    case '501.orfandad.renta_aplicar':
      return `.o.{who}.w12.f.cc.o.desea_aplicar`;
    case '502.capital derivado de renta mensual.renta_aplicar':
      return `.o.{who}.w12.i.pa.rm.desea_aplicar`;
    case '503.capital_aplica':
      return `.o.{who}.w12.f.a.desea_aplicar`;
    case '504.capital_aplica':
      return `.o.{who}.w12.f.ac.desea_aplicar`;
    case '5FJ.capital_aplica':
      return `.o.{who}.w12.f.con.desea_aplicar`;
    case '502.capital_suma':
      return `.o.{who}.w12.i.pa.capital.cf_suma`;
    case '502.capital_aplica':
      return `.o.{who}.w12.i.pa.desea_aplicar`;
    case '510.capital_aplica':
      return `.o.{who}.w12.i.paa.desea_aplicar`;
    case '511.capital_aplica':
      return `.o.{who}.w12.i.pac.desea_aplicar`;
    case '509.capital_aplica':
      return `.o.{who}.w12.i.pt.desea_aplicar`;
    case '516.capital_aplica':
      return `.o.{who}.w12.o.it.desea_aplicar`;
    case '507.capital_aplica':
      return `.o.{who}.w12.o.eg.desea_aplicar`;
    default:
      return '';
  }
}

function fillCargas(c_inputs: CInputs, cargas: CargasFamiliares) {
  c_inputs[transformPath('.cargas.residencia.importe')] = cargas
    .residencia_padres.importe
    ? cargas.residencia_padres.importe.toString()
    : '0';
  c_inputs[transformPath('.cargas.residencia.anos')] = cargas.residencia_padres
    .duracion
    ? cargas.residencia_padres.duracion.toString()
    : '0';

  c_inputs[transformPath('.cargas.n_e_h.importe')] = cargas
    .hijos_necesidades_especiales.importe
    ? cargas.hijos_necesidades_especiales.importe.toString()
    : '0';
  c_inputs[transformPath('.cargas.n_e_h.anos_u')] = cargas
    .hijos_necesidades_especiales.duracion
    ? cargas.hijos_necesidades_especiales.duracion.toString()
    : '0';

  c_inputs[transformPath('.cargas.otros.n_otros')] =
    cargas.otras_cargas.length.toString();
  cargas.otras_cargas.forEach((carga, i) => {
    c_inputs[transformPath('.cargas.otros.carga', `.${i}.nombre`)] =
      carga.nombre;
    c_inputs[transformPath('.cargas.otros.carga', `.${i}.importe`)] =
      carga.importe.toString();
    c_inputs[transformPath('.cargas.otros.carga', `.${i}.anos`)] =
      carga.duracion.toString();
  });

  c_inputs[transformPath('.cargas.hipoteca.capital')] = cargas.hipoteca.capital
    ? cargas.hipoteca.capital.toString()
    : '0';
  c_inputs[transformPath('.cargas.hipoteca.regimen')] = cargas.hipoteca.regimen
    ? cargas.hipoteca.regimen
    : '';
  c_inputs[transformPath('.cargas.hipoteca.pct_ap_u')] = cargas.hipoteca
    .porcentaje
    ? cargas.hipoteca.porcentaje.toString()
    : '0';
  c_inputs[transformPath('.cargas.hipoteca.pct_c_u')] = cargas.hipoteca
    .porcentaje
    ? (100 - cargas.hipoteca.porcentaje).toString()
    : '0';
  c_inputs[transformPath('.cargas.hipoteca.seguro')] = cargas.hipoteca.seguro
    ? '1'
    : '0';
  c_inputs[transformPath('.cargas.hipoteca.seguro_aseguradora')] = cargas
    .hipoteca.banco
    ? cargas.hipoteca.banco.toString()
    : '';
  c_inputs[transformPath('.cargas.hipoteca.seguro_vencimiento')] = cargas
    .hipoteca.vencimiento
    ? cargas.hipoteca.vencimiento.toString()
    : '';

  c_inputs[transformPath('.cargas.tc_1a')] = cargas.aplicar_total_cargas
    ? '1'
    : '0';

  c_inputs[transformPath('.cargas.tc_qa')] = cargas.aplicar_total_cargas
    && cargas.aplicar_total_cargas_a
    ? cargas.aplicar_total_cargas_a.toString()
    : '';
}
