import { selectAverageExpenditure } from '../averageExpenditure/selectors';
import { selectCapitalModification } from '../capitalModification/selectors';
import { selectDataCollection } from '../dataCollection/selectors';
import { selectFamilyResponsabilities } from '../familyResponsabilites/selectors';
import { RootState } from '../store';
import { NewOfferInputs } from '../../types/offers/offers';

export const selectValoracionState = (state: RootState) =>
  state.valoracion.state;

export const selectValoracionReady = (state: RootState) =>
  state.valoracion.ready;

export const selectValoracionProject = (state: RootState) =>
  //!!state.valoracion to avoid define valoracion state in many tests
  !!state.valoracion && state.valoracion.state !== 'initial'
    ? state.valoracion.origin.project
    : null;

export const selectValoracionFromOffer = (state: RootState) =>
  state.valoracion.state !== 'initial' &&
  state.valoracion.origin.type === 'offer'
    ? state.valoracion.origin.from_offer
    : null;

export const selectValoracionInputs = (state: RootState) => {
  return {
    datos: selectDataCollection(state),
    cargas: selectFamilyResponsabilities(state),
    gastos: selectAverageExpenditure(state),
    capitales: selectCapitalModification(state),
  };
};

export const selectValoracionOfferInputs = (state: RootState) => {
  const inputs: NewOfferInputs = {
    datos: state.dataCollection,
    cargas: state.familyResponsabilities.data,
    gastos: state.averageExpenditure.data,
    capitales: state.capitalModification,
  };
  return inputs;
};

export const selectInsuredOfferSaved = (state: RootState) =>
  !!state.valoracion &&
  state.valoracion.ready &&
  state.valoracion.asegurado_principal_offer_saved;

export const selectConyugeOfferSaved = (state: RootState) =>
  !!state.valoracion &&
  state.valoracion.ready &&
  state.valoracion.conyuge_offer_saved;
