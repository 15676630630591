import { getAllProjects } from '../../api/projects/getAllProjects';
import createApiModule from '../createApiModule';
import { ProjectsDTO, Project } from '../../types/projects/projects';
import { GenericState } from '../../types/apiModule';
import { PageInfo, LinksPage } from '../../types/pagination/pagination';

export interface ProjectsModule extends GenericState<unknown> {
  data: ProjectsDTO;
}

const initialState: ProjectsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Project[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const projectsModule = createApiModule({
  name: 'projects',
  apiMethod: getAllProjects,
  initialState,
  parse: (project) => project,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar los proyectos',
    path: '/proyectos',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar los proyectos',
    path: '/proyectos',
  }),
});

export const {
  start: getProjects,
  success: getProjectsOk,
  error: getProjectsKo,
  reset: resetProjects,
} = projectsModule.slice.actions;

export default projectsModule;
