// Esto es un hack terrible para gestionar el orden en que se
// importan los componentes cuando webpack genera bundles.
//
// Sin este hack cuando webpack extrae los CSS resulta que no
// consigue garantizar en qué orden van a terminar incluyéndose
// en la página, así que minicss-extract-plugin lanza warnings
// al respecto y el build falla por eso.
//
// See: https://github.com/facebook/create-react-app/issues/5372

import React from 'react';
import PopupMenu from './components/PopupMenu/PopupMenu';
import Table from './components/Table/Table/Table';
import Td from './components/Table/Td/Td';
import Th from './components/Table/Th/Th';
import Tr from './components/Table/Tr/Tr';
import InputField from './valoracion/components/Form/InputField/InputField/InputField';

const ImportOrderEnforcer = () => () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _popup = () => <PopupMenu children={[]} />;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _field = () => <InputField input={{}} />;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _table = () => (
    <Table>
      <Tr>
        <Th>|</Th>
        <Td>|</Td>
      </Tr>
    </Table>
  );
};

export default ImportOrderEnforcer;
