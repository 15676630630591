import {
  all,
  put,
  select,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { call } from 'redux-saga/effects';
import { setToken } from '../../api/api';
import {
  getProfile,
  login,
  logout,
  updateAvatar,
  updatePassword,
  updateProfile,
} from '../../api/auth/auth';
import { Await } from '../../types/api/api';
import {
  ChangeAvatarDTO,
  ChangePasswordDTO,
  LoginDTO,
} from '../../types/auth/auth';
import { User } from '../../types/users/users';
import snackBarSlice from '../snackBar/snackBarSlice';
import authSlice from './authSlice';
import restrictionsSagas from './restrictions/sagas';
import {
  selectAuthSlice,
  selectIsLoggedIn,
  selectUpdatedAt,
} from './selectors';

const avatarValidityHours = 24;
let reloadingProfile = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginSaga(
  action: PayloadAction<LoginDTO>
): Generator<any, void, any> {
  console.log('login!');
  try {
    const result = (yield call(login, {
      email: action.payload.email,
      password: action.payload.password,
    })) as Await<ReturnType<typeof login>>;
    switch (result.type) {
      case 'ok':
        setToken(result.value.token.plainTextToken);
        yield put(
          authSlice.actions.loginOk({ ...result.value, updated_at: new Date() })
        );
        return;
      case 'validation-error':
        yield put(authSlice.actions.loginKo(result.value));
        return;
    }
  } catch (e) {
    yield put(authSlice.actions.loginKo(e));
  }
}

function* logoutSaga() {
  // This is only to delete the token from the server
  // ... if fails, well... so be it.
  try {
    yield call(logout);
  } catch (e) {
  } finally {
    setToken(null);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* editProfile(action: PayloadAction<User>): Generator<any, void, any> {
  try {
    const result = (yield call(updateProfile, action.payload)) as Await<
      ReturnType<typeof updateProfile>
    >;
    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Perfil editado correctamente.',
            path: '/perfil',
            severity: 'success',
          })
        );
        yield put(authSlice.actions.editProfileOk(result.value));
        return;
      case 'validation-error':
        yield put(authSlice.actions.editProfileKo(result.value));
    }
  } catch (e) {
    yield put(authSlice.actions.editProfileKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'Error al actualizar perfil',
        severity: 'error',
      })
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changePasswordSaga(
  action: PayloadAction<ChangePasswordDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(updatePassword, action.payload)) as Await<
      ReturnType<typeof updatePassword>
    >;

    switch (result.type) {
      case 'ok':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Contraseña actualizada correctamente.',
            path: '/perfil',
            severity: 'success',
          })
        );
        yield put(authSlice.actions.changePasswordOk());
        return;
      case 'validation-error':
        yield put(authSlice.actions.changePasswordKo(result.value));
        return;
    }
  } catch (e) {
    yield all([
      put(authSlice.actions.changePasswordKo(e)),
      put(
        snackBarSlice.actions.showSnackBar({
          message: 'Error al actualizar contraseña',
          severity: 'error',
        })
      ),
    ]);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changeAvatar(
  action: PayloadAction<ChangeAvatarDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(updateAvatar, action.payload)) as Await<
      ReturnType<typeof updateProfile>
    >;
    switch (result.type) {
      case 'ok':
        yield all([
          put(authSlice.actions.changeAvatarOk(result.value)),
          put(
            snackBarSlice.actions.showSnackBar({
              message: 'Avatar actualizado correctamente.',
              severity: 'success',
            })
          ),
        ]);
        return;
      case 'validation-error':
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Error al actualizar el avatar',
            severity: 'error',
          })
        );
        yield put(authSlice.actions.changeAvatarKo(result.value));
        return;
    }
  } catch (e) {
    yield all([
      put(authSlice.actions.changeAvatarKo(e)),
      put(
        snackBarSlice.actions.showSnackBar({
          message: 'Error al actualizar el avatar',
          severity: 'error',
        })
      ),
    ]);
  }
}
function* watchUserProfileImage(
  change: LocationChangeAction
): Generator<any, void, any> {
  if (reloadingProfile || change.payload.location.pathname.endsWith('/login')) {
    return;
  }
  if (!(yield select(selectIsLoggedIn))) {
    return;
  }
  const loadedAt = yield select(selectUpdatedAt);
  if (!loadedAt) {
    const authState = yield select(selectAuthSlice);
    yield put(
      authSlice.actions.updateProfileImage({
        imagen_perfil: authState.user.date.imagen_perfil,
        updated_at: new Date(),
      })
    );
    return;
  }
  const now = new Date();
  const validTo = new Date(loadedAt);
  validTo.setHours(validTo.getHours() + avatarValidityHours);
  validTo.setMinutes(validTo.getMinutes() - 30);
  if (validTo < now) {
    reloadingProfile = true;
    try {
      const result = (yield call(getProfile)) as Await<
        ReturnType<typeof getProfile>
      >;
      switch (result.type) {
        case 'ok':
          yield put(
            authSlice.actions.updateProfileImage({
              imagen_perfil: result.value.imagen_perfil,
              updated_at: new Date(),
            })
          );
          reloadingProfile = false;
          return;
        case 'validation-error':
          yield put(authSlice.actions.logout());
          reloadingProfile = false;
          return;
      }
    } catch (e) {
      yield put(authSlice.actions.loginKo(e));
      reloadingProfile = false;
    }
  }
}

const sagas = [
  takeLatest<PayloadAction<LoginDTO>>(authSlice.actions.login.type, loginSaga),
  takeEvery<PayloadAction<never>>(authSlice.actions.logout.type, logoutSaga),
  takeEvery<PayloadAction<User>>(
    authSlice.actions.editProfile.type,
    editProfile
  ),
  takeEvery<PayloadAction<ChangePasswordDTO>>(
    authSlice.actions.changePassword.type,
    changePasswordSaga
  ),
  takeEvery<PayloadAction<ChangeAvatarDTO>>(
    authSlice.actions.changeAvatar.type,
    changeAvatar
  ),
  takeEvery(LOCATION_CHANGE, watchUserProfileImage),
  ...restrictionsSagas,
];

export default sagas;
