import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  changeArea,
  hideChangeArea,
} from '../../../store/changeArea/changeAreaSlice';
import {
  selectAvailableAreas,
  selectChangeAreaActive,
  selectChangeAreaErrors,
  selectChangeAreaLoading,
  selectChangeAreaUser,
} from '../../../store/changeArea/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { ChangeAreaDTO } from '../../../types/actions/actions';
import { APIError } from '../../../types/api/api';
import { Area } from '../../../types/areas/areas';
import { User } from '../../../types/users/users';
import Button from '../../Buttons/Button/Button';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';

interface ChangeAreaProps {
  selectedUser: User | null;
  areas: Area[];
  serverErrors: APIError | null;
  loading: boolean;
  changeArea: (data: ChangeAreaDTO) => void;
  closeModal: () => void;
}

const changeAreaSchema = yup.object().shape({
  area_id: yup.string(),
});

const ChangeArea: React.FC<ChangeAreaProps> = ({
  selectedUser,
  areas,
  changeArea,
  serverErrors,
  loading,
  closeModal,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeAreaSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  const onSubmit = (data: ChangeAreaDTO) => {
    if (selectedUser) {
      data.user_id = selectedUser.id;
    }
    changeArea(data);
  };
  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Cambiar área
        </h1>
        <p className={styles.description}>
          Se va a realizar un cambio de área.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          <ValidatedSelect
            schema={changeAreaSchema}
            errors={errors}
            label=""
            select={{
              name: 'area_id',
              ref: register,
            }}
            placeholder="Selecciona área"
          >
            {selectedUser && areas
              ? areas.map((area) => (
                  <option key={area.id} value={area.id}>
                    {area.nombre}
                  </option>
                ))
              : null}
          </ValidatedSelect>
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeArea = connect(
  (state: RootState) => ({
    areas: selectAvailableAreas(state),
    selectedUser: selectChangeAreaUser(state),
    serverErrors: selectChangeAreaErrors(state),
    loading: selectChangeAreaLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changeArea: (payload: ChangeAreaDTO) => {
      dispatch(changeArea(payload));
    },
    closeModal: () => {
      return dispatch(hideChangeArea());
    },
  })
)(ChangeArea);

export default renderWhen(ConnectedChangeArea, selectChangeAreaActive);
