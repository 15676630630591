import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { Client, GetClientsDTO, ClientsDTO } from '../../types/clients/clients';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';

export type ClientsState = {
  loading: boolean;
  errors: APIError | null;
  clients: Client[];
  links: LinksPage | null;
  meta: PageInfo | null;
};

const initialState: ClientsState = {
  loading: false,
  errors: null,
  clients: [],
  links: null,
  meta: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClients(state, _action: PayloadAction<GetClientsDTO>) {
      state.loading = true;
      state.errors = null;
    },
    getClientsOk(state, action: PayloadAction<ClientsDTO>) {
      state.loading = false;
      state.errors = null;
      state.clients = action.payload.data;
      state.links = action.payload.links;
      state.meta = action.payload.meta;
    },
    getClientsKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.clients = [];
      state.links = null;
      state.meta = null;
    },
  },
});

export const { getClients, getClientsOk, getClientsKo } = clientsSlice.actions;

export default clientsSlice;
