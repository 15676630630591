import { resetUserPassword as resetUserPasswordApiMethod } from '../../api/users/resetUserPassword';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import usersSlice from './usersSlice';

const resetUserPasswordModule = createApiModule({
  name: 'resetUserPassword',
  apiMethod: resetUserPasswordApiMethod,
  onSuccess: (user) => [
    showSnackBar({
      message: `Contraseña reseteada. ${user.nombre} ${user.apellido1} recibirá un correo con la nueva contraseña.`,
      severity: 'success',
    }),
    usersSlice.actions.getUsers(),
  ],
  onError: () => ({
    message:
      'Error reseteando la contraseña. Asegúrese de tener los permisos adecuados.',
  }),
});

export const {
  start: resetUserPassword,
  success: resetUserPasswordOk,
  error: resetUserPasswordKo,
} = resetUserPasswordModule.slice.actions;

export default resetUserPasswordModule;
