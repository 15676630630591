import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';
import { Client } from '../../types/clients/clients';
import { NewAppointmentDTO } from '../../types/appointments/appointments';

export const newAppointment = async (
  data: NewAppointmentDTO
): Promise<Result<Client, APIValidationError>> => {
  return validatedApiCall<Client>(`/api/citas`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
