import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AverageExpenditures } from '../../valoracion/types/averageExpenditure/averageExpenditure';

export type AverageExpenditureState = {
  data: AverageExpenditures | null;
};

const initialState: AverageExpenditureState = {
  data: null,
};

const averageExpenditureSlice = createSlice({
  name: 'averageExpenditure',
  initialState,
  reducers: {
    reset(state) {
      return { ...initialState };
    },
    saveAverageExpenditureData(
      state,
      action: PayloadAction<AverageExpenditures>
    ) {
      state.data = action.payload;
    },
    saveAverageExpenditureDataWithoutSnackbar(
      state,
      action: PayloadAction<AverageExpenditures>
    ) {
      state.data = action.payload;
    },
  },
});

export const { saveAverageExpenditureData, reset } =
  averageExpenditureSlice.actions;

export default averageExpenditureSlice;
