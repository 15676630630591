import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Values } from 'ibs-cengine/dist/types/Calculator';
import { TableMap } from 'ibs-cengine/dist/types/Database';
import { APIError } from '../../types/api/api';
import { CalculatorErrors } from '../../types/schemas/schemas';
import { ComputedOffer } from './sagas';

export type SchemasState = EngineState & OfferState;

export type EngineState =
  | { engineState: 'initial' | 'loading' }
  | {
      engineState: 'loaded';
      version_id: string;
      tables: TableMap;
      initialValues: Values;
    }
  | {
      engineState: 'loadFail';
      error: APIError;
    };
export type OfferState =
  | { offerState: 'initial' | 'computing' }
  | {
      offerState: 'computed';
      computedOffer: ComputedOffer;
    }
  | {
      offerState: 'computeError';
      computeErrors: CalculatorErrors;
      computedOffer: ComputedOffer;
    };
export interface EngineLoadedDTO {
  tables: TableMap;
  initialValues: Values;
  version_id: string;
}

export interface OfferWithErrorsDTO {
  computedOffer: ComputedOffer;
  errors: CalculatorErrors;
}

export const initialState: SchemasState = {
  engineState: 'initial',
  offerState: 'initial',
};

const schemasSlice = createSlice({
  name: 'schemas',
  initialState: initialState as SchemasState,
  reducers: {
    loadEngine() {
      return { engineState: 'loading', offerState: 'initial' };
    },
    loadEngineOk(status, action: PayloadAction<EngineLoadedDTO>) {
      return {
        engineState: 'loaded',
        version_id: action.payload.version_id,
        tables: action.payload.tables,
        initialValues: action.payload.initialValues,
        offerState: 'initial',
      };
    },
    loadEngineKo(status, action: PayloadAction<APIError>) {
      return {
        engineState: 'loadFail',
        error: action.payload,
        offerState: 'initial',
      };
    },
    computeOffer(status) {
      if (status.engineState !== 'loaded') {
        return status;
      }
      if (status.offerState === 'computing') {
        return status;
      }
      return {
        engineState: status.engineState,
        initialValues: status.initialValues,
        tables: status.tables,
        offerState: 'computing',
        version_id: status.version_id,
      };
    },
    offerComputed(status, action: PayloadAction<ComputedOffer>): SchemasState {
      if (status.engineState !== 'loaded') {
        return status;
      }
      if (status.offerState !== 'computing') {
        return status;
      }
      return {
        engineState: status.engineState,
        initialValues: status.initialValues,
        tables: status.tables,
        offerState: 'computed',
        computedOffer: action.payload,
        version_id: status.version_id,
      };
    },
    offerComputedWithErrors(
      status,
      action: PayloadAction<OfferWithErrorsDTO>
    ): SchemasState {
      if (status.engineState !== 'loaded') {
        return status;
      }
      if (status.offerState !== 'computing') {
        return status;
      }
      return {
        engineState: status.engineState,
        offerState: 'computeError',
        initialValues: status.initialValues,
        tables: status.tables,
        computedOffer: action.payload.computedOffer,
        computeErrors: action.payload.errors,
        version_id: status.version_id,
      };
    },
    clearOffer(status) {
      return {
        ...status,
        offerState: 'initial',
      };
    },
  },
});

export const {
  loadEngine,
  loadEngineOk,
  loadEngineKo,
  computeOffer,
  offerComputed,
  offerComputedWithErrors,
  clearOffer,
} = schemasSlice.actions;

export default schemasSlice;
