import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import { selectAreas } from '../../../store/areas/selectors';
import {
  changeRole,
  hideChangeRole,
} from '../../../store/changeRole/changeRoleSlice';
import {
  selectChangeRoleActive,
  selectChangeRoleErrors,
  selectChangeRoleLoading,
  selectChangeRoleUser,
  selectSelectedUserHasRole,
} from '../../../store/changeRole/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { selectUsersByRole } from '../../../store/users/selectors';
import { ChangeRoleDTO } from '../../../types/actions/actions';
import { APIError } from '../../../types/api/api';
import { Area } from '../../../types/areas/areas';
import { User } from '../../../types/users/users';
import Button from '../../Buttons/Button/Button';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';

interface ChangeRoleProps {
  selectedUser: User | null;
  areas: Area[];
  responsables: User[];
  serverErrors: APIError | null;
  loading: boolean;
  changeRole: ({ user_id, responsable_id }: ChangeRoleDTO) => void;
  closeModal: () => void;
  isComercial: boolean;
  isGestorArea: boolean;
}

const changeRoleSchema = yup.object().shape({
  area_id: yup.string(),
  responsable_id: yup.string(),
});

const ChangeRole: React.FC<ChangeRoleProps> = ({
  selectedUser,
  areas,
  responsables,
  changeRole,
  serverErrors,
  loading,
  closeModal,
  isComercial,
  isGestorArea,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeRoleSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  const onSubmit = (data: ChangeRoleDTO) => {
    if (selectedUser) {
      data.user_id = selectedUser.id;
      data.role_id = isComercial ? 'gestor_area' : 'comercial';
    }
    changeRole(data);
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Cambiar rol
        </h1>

        {isGestorArea && (
          <p className={styles.description}>
            Se va a realizar un cambio de rol de Gestor de Área a Comercial.
            Selecciona nuevo responsable.
          </p>
        )}
        {isComercial && (
          <p className={styles.description}>
            Se va a realizar un cambio de rol de Comercial a Gestor de Área.
            Selecciona nueva área.
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          {isGestorArea && (
            <ValidatedSelect
              schema={changeRoleSchema}
              errors={errors}
              label=""
              select={{
                name: 'responsable_id',
                ref: register,
              }}
              placeholder="Seleccionar responsable"
            >
              {selectedUser && responsables
                ? responsables
                    .filter((resp) => resp.id !== selectedUser.id)
                    .map((resp) => (
                      <option key={resp.id} value={resp.id}>
                        {`${resp.nombre} ${resp.apellido1} ${resp.apellido2}`}
                      </option>
                    ))
                : null}
            </ValidatedSelect>
          )}

          {isComercial && (
            <ValidatedSelect
              schema={changeRoleSchema}
              errors={errors}
              label=""
              select={{
                name: 'area_id',
                ref: register,
              }}
              placeholder="Seleccionar área"
            >
              {selectedUser && areas
                ? areas.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.nombre}
                    </option>
                  ))
                : null}
            </ValidatedSelect>
          )}
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeRole = connect(
  (state: RootState) => ({
    areas: selectAreas(state),
    responsables: selectUsersByRole(state, 'gestor_area'),
    selectedUser: selectChangeRoleUser(state),
    serverErrors: selectChangeRoleErrors(state),
    loading: selectChangeRoleLoading(state),
    isGestorArea: selectSelectedUserHasRole(state, ['gestor_area']),
    isComercial: selectSelectedUserHasRole(state, ['comercial']),
  }),
  (dispatch: AppDispatch) => ({
    changeRole: (payload: ChangeRoleDTO) => {
      dispatch(changeRole(payload));
    },
    closeModal: () => {
      return dispatch(hideChangeRole());
    },
  })
)(ChangeRole);

export default renderWhen(ConnectedChangeRole, selectChangeRoleActive);
