import React from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../../../store/auth/authSlice';
import { AppDispatch } from '../../../../../store/store';

interface MenuLogoutProps {
  styleRoute: (route: string) => string;
  logout: () => void;
}

const MenuLogout: React.FC<MenuLogoutProps> = ({ styleRoute, logout }) => null;

export default connect(null, (dispatch: AppDispatch) => ({
  logout: () => {
    dispatch(logout());
  },
}))(MenuLogout);
