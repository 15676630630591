import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { allRoles } from '../../components/LoginRestrictions/LoginRestrictions';

const NotFound = lazy(() => import('../../routes/NotFound/NotFound'));

const PrivateRoute = lazy(
  () => import('../../components/Router/PrivateRoute/PrivateRoute')
);

const Segurpedia = lazy(() => import('./Segurpedia/Segurpedia'));

const AppRoutes = () => (
  <Switch>
    <PrivateRoute
      allowedRoles={allRoles}
      path="/valoracion/segurpedia/:page"
      component={Segurpedia}
    />
    <PrivateRoute
      allowedRoles={allRoles}
      path="/valoracion/segurpedia"
      component={Segurpedia}
    />

    <Route path="/" component={NotFound} />
  </Switch>
);

export default AppRoutes;
