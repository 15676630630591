import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconAdmin } from '../../../../../assets/icon_admin.svg';
import { ReactComponent as IconLogout } from '../../../../../assets/icon_logout.svg';
import logoFacilsure from '../../../../../assets/logo_facilsure.svg';
import styles from '../../../../../components/Layouts/Layout/layout.module.scss';

interface NavTopRightProps {
  isValoracion: boolean;
  isExposicionMotivos: boolean;
  isSegurpedia: boolean;
  title: string | undefined;
  logout: () => void;
}

const NavTopRight: React.FC<NavTopRightProps> = ({
  isValoracion,
  isExposicionMotivos,
  isSegurpedia,
  title,
  logout,
}) => (
  <>
    {isExposicionMotivos || isSegurpedia ? (
      <>
        <h1 className={styles.exposicionMotivosTitle}>{title}</h1>
        <ul className={`${styles.headerMenu} ${styles.isExposicion}`}>
          <li className={`${styles.headerMenuItem}`}>
            <Link className={styles.logoLink} to="/">
              <img
                className={styles.logoFacilsureImg}
                src={logoFacilsure}
                alt="Logotipo de Facilsure"
              />
            </Link>
          </li>
        </ul>
      </>
    ) : (
      <ul className={styles.headerMenu}>
        <li className={`${styles.headerMenuItem} ${styles.isLogo}`}>
          <Link className={styles.logoLink} to="/">
            <img
              className={styles.logoFacilsureImg}
              src={logoFacilsure}
              alt="Logotipo de Facilsure"
            />
          </Link>
        </li>
        {isValoracion && (
          <li className={styles.headerMenuItem}>
            <Link className={styles.headerMenuLink} to="/">
              <IconAdmin className={styles.headerMenuIcon} />
              Administración
            </Link>
          </li>
        )}
        <li className={styles.headerMenuItem}>
          <button className={styles.headerMenuButton} onClick={logout}>
            <IconLogout className={styles.headerMenuIcon} />
            Cerrar sesión
          </button>
        </li>
      </ul>
    )}
  </>
);

export default NavTopRight;
