import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ResetPasswordDTO,
  UserInfo,
  AuthDTO,
} from '../../../../types/auth/auth';
import { APIError } from '../../../../types/api/api';
import authSlice from '../../authSlice';

export type PasswordRestrictionState = {
  loading: boolean;
  errors: APIError | null;
  restricted: boolean;
};

export const initialState: PasswordRestrictionState = {
  loading: false,
  errors: null,
  restricted: false,
};

const passwordRestrictionSlice = createSlice({
  name: 'passwordRestriction',
  initialState,
  reducers: {
    resetPassword(state, _action: PayloadAction<ResetPasswordDTO>) {
      state.loading = true;
      state.errors = null;
      state.restricted = true;
    },
    resetPasswordOk(state, action: PayloadAction<UserInfo>) {
      state.loading = false;
      state.errors = null;
      state.restricted = false;
    },
    resetPasswordKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.restricted = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      authSlice.actions.loginOk,
      (state, action: PayloadAction<AuthDTO>) => {
        state.restricted = 'change_password' in action.payload.restrictions;
      }
    );
    builder.addCase(authSlice.actions.logout, (state) => {
      state.restricted = false;
    });
  },
});

export const { resetPassword, resetPasswordOk, resetPasswordKo } =
  passwordRestrictionSlice.actions;

export default passwordRestrictionSlice;
