import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import iconPassword from '../../../assets/icon_password.svg';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import styles from '../../../routes/Login/login.module.scss';
import { resetPassword } from '../../../store/auth/restrictions/password/passwordRestrictionSlice';
import {
  selectPasswordRestrictionErrors,
  selectPasswordRestrictionLoading,
} from '../../../store/auth/restrictions/password/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import LoginHeading from '../../../themes/active/components/LoginHeading/LoginHeading';
import LoginLeftPanel from '../../../themes/active/components/LoginLeftPanel/LoginLeftPanel';
import { APIError } from '../../../types/api/api';
import { ResetPasswordDTO } from '../../../types/auth/auth';
import Button from '../../Buttons/Button/Button';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedInput from '../../Form/ValidatedInput/ValidatedInput';
import Loading from '../../Loading/Loading';

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Introduce tu nueva contraseña')
    .min(8, 'La contraseña debe tener mínimo 8 carácteres'),
  password_confirmation: yup
    .string()
    .required('Repite tu nueva contraseña')
    .oneOf([yup.ref('password')], 'Las contraseñas no coinciden'),
});

interface ChangePasswordProps {
  changePassword: (payload: ResetPasswordDTO) => void;
  serverErrors: APIError | null;
  loading: boolean;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  changePassword,
  serverErrors,
  loading,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<ResetPasswordDTO>({
      mode: 'onSubmit',
      validationSchema: resetPasswordSchema,
    });
  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  return (
    <main className={styles.root}>
      <LoginLeftPanel />
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit(changePassword)}>
          <LoginHeading showWelcome={false} />
          <p className={styles.mainText}>
            Tienes que cambiar tu contraseña para poder acceder a la aplicación.
          </p>
          <p className={styles.mainText}>
            Por favor, introduce tu nueva contraseña.
          </p>
          <GlobalError message={globalError} />
          <div className={styles.inputWrapper}>
            <img
              src={iconPassword}
              className={`${styles.iconInput} ${styles.isPassword}`}
              alt="Cambio de contraseña"
            />
            <ValidatedInput
              variant="login"
              schema={resetPasswordSchema}
              errors={errors}
              label="Nueva contraseña"
              input={{ name: 'password', type: 'password', ref: register }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <img
              src={iconPassword}
              className={`${styles.iconInput} ${styles.isPassword}`}
              alt="icon password"
            />
            <ValidatedInput
              schema={resetPasswordSchema}
              errors={errors}
              label="Repite la nueva contraseña"
              input={{
                name: 'password_confirmation',
                type: 'password',
                ref: register,
              }}
            />
          </div>

          {/* Client-side errors */}
          {errors.password && (
            <p className={styles.errorText}>{errors.password.message}</p>
          )}
          {errors.password_confirmation && (
            <p className={styles.errorText}>
              {errors.password_confirmation.message}
            </p>
          )}

          {/* Server-side global error */}
          {globalError && <p className={styles.errorText}>{globalError}</p>}
          <Button variant="positive">Cambiar Contraseña</Button>
          {loading && <Loading />}
        </form>
      </div>
    </main>
  );
};

export default connect(
  (state: RootState) => ({
    serverErrors: selectPasswordRestrictionErrors(state),
    loading: selectPasswordRestrictionLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changePassword: (payload: ResetPasswordDTO) => {
      dispatch(resetPassword(payload));
    },
  })
)(ChangePassword);
