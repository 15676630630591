import { RootState } from '../store';
import { Role } from '../../types/auth/roles';

export const selectChangeRole = (state: RootState) => state.changeRole;

export const selectChangeRoleErrors = (state: RootState) =>
  selectChangeRole(state)?.errors;

export const selectChangeRoleLoading = (state: RootState) =>
  selectChangeRole(state)?.loading;

export const selectChangeRoleActive = (state: RootState) =>
  selectChangeRole(state)?.active;

export const selectChangeRoleUser = (state: RootState) =>
  selectChangeRole(state)?.user;

export const selectSelectedUserHasRole = (state: RootState, roles: Role[]) => {
  const role = selectChangeRoleUser(state)?.role_id;
  return role ? roles.includes(role) : false;
};
