import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { ExtendedOffer } from '../../types/offers/offers';
import { APIValidationError } from '../../types/api/api';

export const getOfferById = async (
  id: UUID
): Promise<Result<ExtendedOffer, APIValidationError>> => {
  return validatedApiCall<ExtendedOffer>(`/api/ofertas/${id}`, {
    method: 'GET',
    auth: true,
  });
};
