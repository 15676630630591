import { Values } from 'ibs-cengine/dist/types/Calculator';
import { Guarantee } from '../../../types/offers/offers';
import { getValueAsBoolean, getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaIPT(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.i.pt.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Invalidez Permanente Total',
    categoria: 'invalidez',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.i.pt.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.i.pt.prima', values),
    capital: getValueAsNumber(
      '.a.' + who + '.e.n.i.pt.capital_recomendado',
      values
    ),
    capital_manual: getValueAsNumber('.o.' + who + '.w12.i.pt.cf', values),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.i.pt.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.i.pt.aplicar', values),
    editable: true,
    obligatoria: false,
    bookPath: 'incapacidad-permanente-total-ipt',
  };
}
