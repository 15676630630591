import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { ChangeRoleDTO } from '../../types/actions/actions';
import { User } from '../../types/users/users';

export type ChangeRoleState = {
  loading: boolean;
  errors: APIError | null;
  active: boolean;
  user: User | null;
};

const initialState: ChangeRoleState = {
  loading: false,
  errors: null,
  active: false,
  user: null,
};

const changeRoleSlice = createSlice({
  name: 'changeRole',
  initialState,
  reducers: {
    showChangeRole(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.active = true;
    },
    hideChangeRole() {
      return { ...initialState };
    },
    changeRole(state, _action: PayloadAction<ChangeRoleDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changeRoleOk(state) {
      state.loading = false;
      state.errors = null;
    },
    changeRoleKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  showChangeRole,
  hideChangeRole,
  changeRole,
  changeRoleOk,
  changeRoleKo,
} = changeRoleSlice.actions;

export default changeRoleSlice;
