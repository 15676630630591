import { Result, validatedApiCall } from '../api';
import { ChangeRoleDTO } from '../../types/actions/actions';
import { User } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';

export const changeRole = async (
  data: ChangeRoleDTO
): Promise<Result<User, APIValidationError>> => {
  const { user_id, role_id, responsable_id, area_id } = data;

  return validatedApiCall<User>(`/api/users/${user_id}/role`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({
      role_id,
      responsable_id,
      area_id,
    }),
  });
};
