import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CargasFamiliares } from '../../valoracion/types/familyResponsabilities/familyResponsabilites';

type familyResponsabilitiesState = {
  data: CargasFamiliares;
};

export const initialState: familyResponsabilitiesState = {
  data: {
    residencia_padres: {
      importe: null,
      duracion: null,
    },
    hijos_necesidades_especiales: {
      importe: null,
      duracion: null,
    },
    hipoteca: {
      seguro: false,
      banco: null,
      capital: null,
      regimen: null,
      porcentaje: null,
      vencimiento: null,
    },
    otras_cargas: [],
    aplicar_total_cargas: false,
    aplicar_total_cargas_a: null,
  },
};

const familyResponsabilitiesSlice = createSlice({
  name: 'familyResponsabilities',
  initialState,
  reducers: {
    reset(state) {
      return { ...initialState };
    },
    saveFamilyResponsabilitiesData(
      state,
      action: PayloadAction<CargasFamiliares>
    ) {
      state.data = action.payload;
    },
    saveFamilyResponsabilitiesNoMessage(
      state,
      action: PayloadAction<CargasFamiliares>
    ) {
      state.data = action.payload;
    },
  },
});

export const {
  saveFamilyResponsabilitiesData,
  saveFamilyResponsabilitiesNoMessage,
  reset,
} = familyResponsabilitiesSlice.actions;

export default familyResponsabilitiesSlice;
