import { APIValidationError } from '../../types/api/api';
import {
  ChallengesDTO,
  ChallengeURLDTO,
} from '../../types/challenges/challenges';
import { Result, validatedApiCall } from '../api';

export const getChallenges = async (
  data: ChallengeURLDTO
): Promise<Result<ChallengesDTO, APIValidationError>> => {
  const { user_id, filter_user_id } = data;

  return validatedApiCall<ChallengesDTO>(
    `/api/retos?user_id=${user_id || filter_user_id}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
