import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeClientStateDTO } from '../../types/actions/actions';
import { APIError } from '../../types/api/api';
import { Client } from '../../types/clients/clients';

export type ChangeClientState = {
  loading: boolean;
  errors: APIError | null;
  active: boolean;
  client: Client | null;
};

const initialState: ChangeClientState = {
  loading: false,
  errors: null,
  active: false,
  client: null,
};

const changeClientStateSlice = createSlice({
  name: 'changeClientState',
  initialState,
  reducers: {
    showChangeClientState(state, action: PayloadAction<Client>) {
      state.client = action.payload;
      state.active = true;
    },
    hideChangeClientState() {
      return { ...initialState };
    },
    changeClientState(state, _action: PayloadAction<ChangeClientStateDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changeClientStateOk(state) {
      state.loading = false;
      state.errors = null;
    },
    changeClientStateKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  showChangeClientState,
  hideChangeClientState,
  changeClientState,
  changeClientStateOk,
  changeClientStateKo,
} = changeClientStateSlice.actions;

export default changeClientStateSlice;
