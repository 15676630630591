import { Values } from 'ibs-cengine/dist/types/Calculator';
import { AseguradoOferta } from '../../types/offers/asegurado_oferta';
import { CodPeriodicidad, CodRevalorizacion, NewOffer, PriceDetail } from '../../types/offers/offers';
import { ConyugeFromOffer } from '../../valoracion/types/dataCollection/dataCollection';
import {
  getGarantiaFallecimiento,
  getGarantiaAccidente,
  getGarantiaAccidenteCirculacion,
  getGarantiaFallecimientoConjunto,
} from './garantias/Fallecimiento';
import {
  getGarantiaInvalidezAccidente,
  getGarantiaInvalidezAccidenteCirculacion,
  getGarantiaIPA,
} from './garantias/IPA';
import { getGarantiaEnfermedadGrave } from './garantias/EnfermedadGrave';
import { getGarantiaHelvetiaBienestar } from './garantias/HelvetiaBienestar';
import { getGarantiaIncapacidadTemporal } from './garantias/IT';
import { getGarantiaIPT } from './garantias/IPT';

import { getValueAsNumber, getValueAsString } from './util';
import { getDescuento } from './garantias/Descuento';

export function getOferta(values: Values, target: AseguradoOferta): NewOffer {
  const who = target === 'conyuge' ? 'c' : 'p';
  let order = 1;
  const offer: NewOffer = {
    proyecto_id: '',
    asegurado: target,
    nombre: getValueAsString(`.a.${who}.d.nombre`, values),
    importe: getValueAsNumber(
      '.o.' + who + '.w12.resumen.fr.a.prima_total',
      values
    ),
    garantias: [],
    precio: getPrecio(values, who),
    descuentos: getDescuento(values),
    revalorizacion: getValueAsString(`.a.revalorizacion`, values) as CodRevalorizacion,
    periodicidad: getValueAsString(`.a.periodicidad`, values) as CodPeriodicidad,
  };
  const funcs = [
    getGarantiaFallecimiento,
    getGarantiaAccidente,
    getGarantiaAccidenteCirculacion,
    getGarantiaFallecimientoConjunto,
    getGarantiaIPA,
    getGarantiaInvalidezAccidente,
    getGarantiaInvalidezAccidenteCirculacion,
    getGarantiaIPT,
    getGarantiaIncapacidadTemporal,
    getGarantiaEnfermedadGrave,
    getGarantiaHelvetiaBienestar,
  ];
  funcs.forEach((func) => {
    const garantia = func(values, who, ++order);
    if (garantia) {
      order++;
      offer.garantias.push(garantia);
    }
  });
  return offer;
}

export function getClientDataFromOffer(
  values: Values,
  target: AseguradoOferta
): ConyugeFromOffer {
  const who = target === 'conyuge' ? 'c' : 'p';
  return {
    nombre: getValueAsString(`.a.${who}.d.nombre`, values),
    apellidos: getValueAsString(`.a.${who}.d.apellidos`, values),
    sexo:
      getValueAsString(`.a.${who}.d.sexo`, values) === 'h' ? 'hombre' : 'mujer',
    profesion_id: getValueAsNumber(`.a.${who}.d.profesion`, values),
    fecha_nacimiento: getValueAsString(`.a.${who}.d.nacimiento`, values),
    edad: getValueAsNumber(`.a.${who}.d.edad`, values),
    estatura: getValueAsNumber(`.a.${who}.d.estatura`, values),
    peso: getValueAsNumber(`.a.${who}.d.peso`, values),
    fumador:
      getValueAsNumber(`.a.${who}.d.fumador`, values) === 1 ? true : false,
    moto: getValueAsNumber(`.a.${who}.d.moto`, values) === 1 ? true : false,
    cilindrada: getValueAsNumber(`.a.${who}.d.moto_cil`, values),
    alta_ss: getValueAsString(`.a.${who}.d.alta_ss`, values),
    email: getValueAsString(`.a.${who}.d.email`, values),
  };
}

function getPrecio(values: Values, who: string): PriceDetail {
  const prefix = '.o.' + who + '.w12.resumen.fr.';
  return {
    anual: getValueAsNumber(prefix + 'a.prima_total', values),
    semestral: getValueAsNumber(prefix + 's.suc_recibo', values),
    semestralPrimerRecibo: getValueAsNumber(prefix + 's.primer_recibo', values),
    trimestral: getValueAsNumber(prefix + 't.suc_recibo', values),
    trimestralPrimerRecibo: getValueAsNumber(
      prefix + 't.primer_recibo',
      values
    ),
    mensual: getValueAsNumber(prefix + 'm.suc_recibo', values),
    mensualPrimerRecibo: getValueAsNumber(prefix + 'm.primer_recibo', values),
    neto: {
      anual: getValueAsNumber(prefix + 'a.prima_neta', values),
      semestral: getValueAsNumber(prefix + 's.prima_neta', values),
      trimestral: getValueAsNumber(prefix + 't.prima_neta', values),
      mensual: getValueAsNumber(prefix + 'm.prima_neta', values),
    },
  };
}
