import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { RegisterClientDTO } from '../../types/clients/register';

export type RegisterClientState = {
  loading: boolean;
  errors: APIError | null;
};

const initialState: RegisterClientState = {
  loading: false,
  errors: null,
};

const registerClientSlice = createSlice({
  name: 'registerClient',
  initialState,
  reducers: {
    register(state, _action: PayloadAction<RegisterClientDTO>) {
      state.loading = true;
      state.errors = null;
    },
    registerOk(state) {
      state.loading = false;
      state.errors = null;
    },
    registerKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const { register, registerOk, registerKo } = registerClientSlice.actions;

export default registerClientSlice;
