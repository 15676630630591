import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { changeProjectState } from '../../api/projects/changeProjectState';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { getProjectOk } from './projectModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Project } from '../../types/projects/projects';

export interface ChangeProjectState extends GenericState<unknown> {
  visible: boolean;
}
const initialState: ChangeProjectState = {
  errors: null,
  loading: false,
  visible: false,
  data: null,
};

const changeProjectStateModule = createApiModule({
  name: 'changeProjectState',
  apiMethod: changeProjectState,
  initialState,
  onSuccess: (project: Project) => [
    getProjectOk(project),
    showSnackBar({
      severity: 'success',
      message: 'Proyecto actualizado correctamente',
    }),
  ],
  onError: () => ({
    message: 'No hemos podido cambiar el estado del proyecto',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cambiar el estado del proyecto',
  }),
  reducers: {
    show(state) {
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    },
  },
});

export const {
  start: changeState,
  success: changeStateOk,
  error: changeStateKo,
  show,
  hide,
} = changeProjectStateModule.slice.actions;

function* hideSaga() {
  yield put(hide());
}
changeProjectStateModule.sagas = [
  takeLatest<PayloadAction<unknown>>(changeStateOk.type, hideSaga),
  ...changeProjectStateModule.sagas,
];

export default changeProjectStateModule;
