import styles from './loginLeftPanel.module.scss';
import logoFacilsure from '../../../../assets/logo_facilsure.png';
const LoginLeftPanel: React.FC = () => (
  <div className={styles.titleWrapper}>
    <img className={styles.logoFacilsure} src={logoFacilsure} alt="Facilsure" />
    <h1 className={styles.title}>
      Partner especialista en captación, formación y seguimiento de equipos de
      ventas de alto rendimiento para Helvetia.
    </h1>
  </div>
);

export default LoginLeftPanel;
