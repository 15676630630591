import { getOfferById } from '../../api/offers/getOfferById';
import createApiModule from '../createApiModule';

const requestOfferModule = createApiModule({
  name: 'requestOffer',
  apiMethod: getOfferById,
  parse: (offer) => offer,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar la oferta',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar la oferta',
  }),
});

export const {
  start: requestOffer,
  success: requestOfferOk,
  error: requestOfferKo,
  reset: resetrequestOffer,
} = requestOfferModule.slice.actions;

export default requestOfferModule;
