import React from 'react';
import { connect } from 'react-redux';
import styles from './tr.module.scss';
import { AppDispatch } from '../../../store/store';
import { push } from 'connected-react-router';
import { RowVariant } from '../types';

export interface TrProps {
  children: React.ReactNode;
  key?: string | number;
  path?: string;
  navigateTo: (x: string) => void;
  variant?: RowVariant;
}

const rowStyle = (variant: RowVariant): string => {
  switch (variant) {
    case 'selected':
      return styles.isSelected;
    case 'default':
      return '';
  }
};

const Tr: React.FC<TrProps> = ({
  children,
  key,
  navigateTo,
  path,
  variant = 'default',
}) => (
  <tr
    onClick={() => {
      path && navigateTo(path);
    }}
    className={`${styles.tableRow} ${path ? styles.isClickable : ''} ${rowStyle(
      variant
    )}`}
    key={key}
  >
    {children}
  </tr>
);

export default connect(null, (dispatch: AppDispatch) => ({
  navigateTo: (payload: string) => {
    return dispatch(push(payload));
  },
}))(Tr);
