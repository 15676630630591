import { getClientById } from '../../api/clients/getClientById';
import createApiModule from '../createApiModule';

const clientModule = createApiModule({
  name: 'client',
  apiMethod: getClientById,
  initialData: null,
  parse: (client) => client,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar el cliente',
    path: '/clientes',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar el cliente',
    path: '/clientes',
  }),
});

export const {
  start: getClient,
  success: getClientOk,
  error: getClientKo,
  reset: resetClient,
} = clientModule.slice.actions;

export default clientModule;
