import React, { ReactElement, useState, useCallback } from 'react';
import { FieldError } from 'react-hook-form';
import isFieldError from '../../../../../helpers/isFieldError';
import Tooltip from '../Tooltip/Tooltip';
import styles from './inputField.module.scss';

export type RowVariant = 'default' | 'noMargin' | 'inline';
export type Variant = 'small' | 'tiny' | 'default' | 'readOnly';

export interface InputFieldProps {
  input: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  label?: string | ReactElement;
  error?: string | FieldError;
  variant?: Variant;
  rowVariant?: RowVariant;
  placeholder?: string;
  currency?: string;
  tooltip?: string;
}

const stylePicker = (variant: Variant): string => {
  switch (variant) {
    case 'small':
      return styles.isSmall;
    case 'tiny':
      return styles.isTiny;
    case 'readOnly':
      return styles.isReadOnly;
    case 'default':
      return '';
  }
};

const rowStylePicker = (variant: RowVariant): string => {
  switch (variant) {
    case 'noMargin':
      return styles.isNoMargin;
    case 'inline':
      return styles.isInline;
    case 'default':
      return '';
  }
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  error,
  input,
  variant = 'default',
  rowVariant = 'default',
  placeholder,
  currency,
  tooltip,
}) => {
  const [hasValue, setHasValue] = useState(input.defaultValue || '');
  const checkIfHasValue = useCallback((val) => setHasValue(val), [setHasValue]);

  const id = input.id || input.name;
  const errorMessage: string | JSX.Element = isFieldError(error)
    ? error.message || ''
    : error || '';
  const tooltipText = tooltip
    ? tooltip === 'error'
      ? errorMessage
      : tooltip
    : '';

  return (
    <div className={`${styles.row} ${rowStylePicker(rowVariant)} `}>
      <label
        className={`${styles.label} ${stylePicker(variant)} `}
        htmlFor={id}
        data-testid={`${id}-label`}
      >
        {label}
      </label>
      <div
        className={
          (currency ? styles.inputContainer : '') +
          (tooltipText ? styles.hasTooltip : '')
        }
      >
        {tooltipText && <Tooltip text={tooltipText} />}
        <input
          onChange={(e) => {
            checkIfHasValue(e.target.value);
            input.onChange && input.onChange(e);
          }}
          data-testid={id}
          className={`${styles.inputText} ${stylePicker(variant)}  ${
            hasValue || placeholder ? styles.hasValue : ''
          } ${error && tooltip !== 'error' ? styles.hasError : ''}`}
          id={id}
          {...input}
        />
        <span className={styles.currency}>{currency}</span>
      </div>
      {error && tooltip !== 'error' && (
        <p className={styles.errorText}>{errorMessage}</p>
      )}
    </div>
  );
};
export default InputField;
