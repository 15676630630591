import { delay, put, select, take, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { call } from 'redux-saga/effects';
import { getUsers } from '../../api/users/getUsers';
import { Await } from '../../types/api/api';
import authSlice from '../auth/authSlice';
import { selectHasAnyPermission } from '../auth/selectors';
import changeAreaSlice from '../changeArea/changeAreaSlice';
import changeResponsableSlice from '../changeResponsable/changeResponsableSlice';
import changeRoleSlice from '../changeRole/changeRoleSlice';
import snackBarSlice from '../snackBar/snackBarSlice';
import usersSlice from './usersSlice';

function* getUsersSaga(): Generator<any, void, any> {
  try {
    const result = (yield call(getUsers)) as Await<ReturnType<typeof getUsers>>;
    switch (result.type) {
      case 'ok':
        yield put(usersSlice.actions.getUsersOk(result.value.data));
        return;
      case 'validation-error':
        yield put(usersSlice.actions.getUsersKo(result.value));
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Se ha producido un error.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(usersSlice.actions.getUsersKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'Se ha producido un error.',
        severity: 'error',
      })
    );
  }
}

/**
 * Load the list of users when the page is refreshed.
 */
function* reloadUsers(): Generator<any, void, any> {
  yield take(LOCATION_CHANGE);
  if (yield select(selectHasAnyPermission, ['list_users'])) {
    yield getUsersSaga();
  }
}

/**
 * Refresh the list of users.
 */
function* refreshUsers(): Generator<any, void, any> {
  if (yield select(selectHasAnyPermission, ['list_users'])) {
    yield put(usersSlice.actions.getUsers());
  }
}

/**
 * Refreses the list of users every minute.
 *
 * The correct way to do this would be for the API to notify us
 * when there is a change and then refresh, but we are not there
 * yet.
 */
function* getUsersEveryMinute(): Generator<any, void, any> {
  while (true) {
    try {
      // We only make the request when the user is logged in and has
      // permission to do so.
      if (yield select(selectHasAnyPermission, ['list_users'])) {
        yield refreshUsers();
      }

      // This saga should not stop even if there are errors
    } catch (err) {}

    yield delay(60 * 1000);
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    authSlice.actions.loginOk.type,
    refreshUsers
  ),
  takeLatest<PayloadAction<never>>(
    usersSlice.actions.getUsers.type,
    getUsersSaga
  ),
  reloadUsers(),
  getUsersEveryMinute(),

  // retrieve users when the user performs modification actions
  takeLatest<PayloadAction<never>>(
    changeResponsableSlice.actions.changeResponsableOk.type,
    refreshUsers
  ),
  takeLatest<PayloadAction<never>>(
    changeRoleSlice.actions.changeRoleOk.type,
    refreshUsers
  ),
  takeLatest<PayloadAction<never>>(
    changeAreaSlice.actions.changeAreaOk.type,
    refreshUsers
  ),
];

export default sagas;
