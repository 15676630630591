import { RootState } from '../store';

export const selectClientSlice = (state: RootState) => state.client;
export const selectClient = (state: RootState) => selectClientSlice(state).data;
export const selectClientName = (state: RootState) => {
  const client = selectClient(state);
  return (
    (client && `${client.nombre} ${client.apellido1} ${client.apellido2}`) || ''
  );
};
export const selectClientLoading = (state: RootState): boolean =>
  state.client.loading;

export const selectClientCommentsLoading = (state: RootState) =>
  state.clientComments.loading;
export const selectClientComments = (state: RootState) =>
  state.clientComments.data;
export const selectNewCommentLoading = (state: RootState) =>
  state.newClientComment.loading;

export const selectEditClient = (state: RootState) => state.editClient;
export const selectEditClientLoading = (state: RootState) =>
  selectEditClient(state).loading;
export const selectEditClientErrors = (state: RootState) =>
  selectEditClient(state).errors;

export const selectClientSearch = (state: RootState) => state.clientSearch;
export const selectClientSearchLoading = (state: RootState) =>
  selectClientSearch(state).loading;
export const selectClientSearchErrors = (state: RootState) =>
  selectClientSearch(state).errors;
export const selectClientSearchFound = (state: RootState) =>
  selectClientSearch(state).data;

export const selectClientProjectsSlice = (state: RootState) =>
  state.clientProjects;
export const selectClientProjectsLoading = (state: RootState) =>
  selectClientProjectsSlice(state).loading;
export const selectClientProjectsErrors = (state: RootState) =>
  selectClientProjectsSlice(state).errors;
export const selectClientProjects = (state: RootState) =>
  selectClientProjectsSlice(state).data.data;
export const selectActiveClientProject = (state: RootState) =>
  selectClientProjects(state).find(
    (project) =>
      project.estado !== 'finalizado' && project.estado !== 'cancelado'
  );
