import { APIValidationError } from '../../types/api/api';
import { Offer } from '../../types/offers/offers';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';

export const sendPdfByEmail = async (
  id: UUID
): Promise<Result<Offer, APIValidationError>> => {
  return validatedApiCall<Offer>(`/api/ofertas/${id}/send`, {
    method: 'GET',
    auth: true,
  });
};
