import { User } from '../../types/users/users';
import { validatedApiCall, Result } from '../api';
import { APIValidationError } from '../../types/api/api';

export const updateUser = async (
  data: User
): Promise<Result<User, APIValidationError>> =>
  validatedApiCall<User>(`/api/users/${data.id}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });
