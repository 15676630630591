import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  changeState,
  hide,
} from '../../../store/project/changeProjectStateModule';
import {
  selectChangeProjectStateErrors,
  selectChangeProjectStateLoading,
  selectChangeProjectStateVisible,
} from '../../../store/project/selectors';
import { selectProjectData } from '../../../store/project/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { APIError } from '../../../types/api/api';
import {
  EstadoProyecto,
  EstadoProyectoEnum,
} from '../../../types/projects/estado_proyecto.d';
import {
  ChangeProjectStateDTO,
  Project,
} from '../../../types/projects/projects';
import Button from '../../Buttons/Button/Button';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

const changeProjectStateSchema = yup.object().shape({
  estado: yup.string(),
});

interface ChangeProjectStateProps {
  project: Project | null;
  serverErrors: APIError | null;
  loading: boolean;
  closeModal: () => void;
  changeProjectState: (payload: ChangeProjectStateDTO) => void;
}

const ChangeProjectState: React.FC<ChangeProjectStateProps> = ({
  project,
  loading,
  serverErrors,
  closeModal,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeProjectStateSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);
  const allProjectStates = Object.keys(EstadoProyectoEnum) as EstadoProyecto[];

  const onSubmit = (data: any) => {
    project && console.log('cambio de estado', { ...data, id: project.id });
    // changeProjectState({...data, id: project.id})
    closeModal();
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Cambiar estado proyecto
        </h1>

        <p className={styles.description}>
          Se va a realizar un cambio de estado en el proyecto. Selecciona nuevo
          estado.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          <ValidatedSelect
            schema={changeProjectStateSchema}
            errors={errors}
            label=""
            select={{
              defaultValue: project?.estado,
              name: 'estado',
              ref: register,
            }}
            placeholder="Seleccionar estado"
          >
            {allProjectStates.map((state) => (
              <option key={state} value={state}>
                {EstadoProyectoEnum[state]}
              </option>
            ))}
          </ValidatedSelect>
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeProjectState = connect(
  (state: RootState) => ({
    loading: selectChangeProjectStateLoading(state),
    serverErrors: selectChangeProjectStateErrors(state),
    project: selectProjectData(state),
  }),
  (dispatch: AppDispatch) => ({
    changeProjectState: (payload: ChangeProjectStateDTO) =>
      dispatch(changeState(payload)),
    closeModal: () => dispatch(hide()),
  })
)(ChangeProjectState);

export default renderWhen(
  ConnectedChangeProjectState,
  selectChangeProjectStateVisible
);
