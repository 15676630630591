import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layouts/Layout/Layout';
import PrivateRoute from '../../components/Router/PrivateRoute/PrivateRoute';
import { canAccessAverageExpenditure } from '../../store/averageExpenditure/gates';
import {
  canAccessAnalisis,
  canAccessDataCollection,
  canModifyOfferCapital,
  canViewComputedOffer,
  canViewOfferList,
} from '../../store/dataCollection/gates';

const DataCollection = lazy(
  () => import('./DataCollection/NewFamily/NewFamily')
);

const NewBeneficiaryInsured = lazy(
  () => import('./DataCollection/NewBeneficiaryInsured/NewBeneficiaryInsured')
);
const NewBeneficiaryConyuge = lazy(
  () => import('./DataCollection/NewBeneficiaryConyuge/NewBeneficiaryConyuge')
);
const NewBeneficiaryChild = lazy(
  () => import('./DataCollection/NewBeneficiaryChild/NewBeneficiaryChild')
);
const FamilyResponsabilities = lazy(
  () => import('./FamilyResponsibilities/FamilyResponsabilities')
);
const Analysis = lazy(() => import('./Analysis/Analysis'));
const AverageExpenditure = lazy(
  () => import('./AverageExpenditure/AverageExpenditure')
);
const OffersList = lazy(() => import('./OffersList/OffersList'));
const NotFound = lazy(() => import('../../routes/NotFound/NotFound'));

const CapitalModification = lazy(() => import('./Offers/CapitalModification'));
const RequestOffer = lazy(
  () => import('../../routes/Project/RequestOffer/RequestOffer')
);
const ViewOffer = lazy(() => import('./Offers/ViewOffer'));

const ValoracionRoutes = () => (
  <Layout>
    <Switch>
      <PrivateRoute
        exact
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/toma-datos"
        component={DataCollection}
        gate={canAccessDataCollection}
      />
      <PrivateRoute
        exact
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/toma-datos/principal"
        component={NewBeneficiaryInsured}
        gate={canAccessDataCollection}
      />
      <PrivateRoute
        exact
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/toma-datos/conyuge"
        component={NewBeneficiaryConyuge}
        gate={canAccessDataCollection}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/toma-datos/hijo/:id"
        component={NewBeneficiaryChild}
        gate={canAccessDataCollection}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/cargas"
        component={FamilyResponsabilities}
        gate={canAccessAnalisis}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/analisis"
        component={Analysis}
        gate={canAccessAnalisis}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/gasto-medio"
        component={AverageExpenditure}
        gate={canAccessAverageExpenditure}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/oferta"
        component={ViewOffer}
        gate={canViewComputedOffer}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/capitales"
        component={CapitalModification}
        gate={canModifyOfferCapital}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/proyectos/:id/ofertas"
        component={OffersList}
        gate={canViewOfferList}
      />
      <PrivateRoute
        allowedRoles={['comercial', 'gestor_area']}
        path="/valoracion/ver-oferta/:id"
        component={RequestOffer}
      />
      <Route path="/" component={NotFound} />
    </Switch>
  </Layout>
);

export default ValoracionRoutes;
