import { APIValidationError } from '../../types/api/api';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';
import { GetOffersDTO } from '../../types/offers/offers';

export const getProjectOffers = async (
  id: UUID,
  extended?: 'extended'
): Promise<Result<GetOffersDTO, APIValidationError>> => {
  return validatedApiCall<GetOffersDTO>(
    `/api/proyectos/${id}/ofertas?extended=${extended === 'extended' ? 1 : 0}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
