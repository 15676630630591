import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';
import { Client } from '../../types/clients/clients';
import { APIValidationError } from '../../types/api/api';

export const getClientById = async (
  id: UUID
): Promise<Result<Client, APIValidationError>> => {
  return validatedApiCall<Client>(`/api/clientes/${id}`, {
    method: 'GET',
    auth: true,
  });
};
