import { Result, validatedApiCall } from '../api';
import { RegisterUserDTO } from '../../types/users/register';
import { User } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';

export const registerUser = async (
  data: RegisterUserDTO
): Promise<Result<User, APIValidationError>> => {
  return validatedApiCall<User>(`/api/users`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
