import { put, takeLatest } from '@redux-saga/core/effects';
import snackBarSlice from '../snackBar/snackBarSlice';
import familyResponsabilitiesSlice from './familyResponsabilitiesSlice';

function* saveFamilySaga(): Generator<any, void, any> {
  yield put(
    snackBarSlice.actions.showSnackBar({
      message: 'Se han añadido las cargas familiares correctamente.',
      severity: 'success',
    })
  );
}

const sagas = [
  takeLatest(
    familyResponsabilitiesSlice.actions.saveFamilyResponsabilitiesData.type,
    saveFamilySaga
  ),
];

export default sagas;
