import { APIValidationError } from '../../types/api/api';
import { Offer, SaveOfferData } from '../../types/offers/offers';
import { Result, validatedApiCall } from '../api';

export const postOffer = async (
  data: SaveOfferData
): Promise<Result<Offer, APIValidationError>> => {
  return validatedApiCall<Offer>(
    `/api/proyectos/${data.oferta.proyecto_id}/ofertas`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify(data),
    }
  );
};
