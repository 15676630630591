import { RootState } from '../store';

export const selectProjectSlice = (state: RootState) => state.project;

export const selectProject = (state: RootState) =>
  selectProjectSlice(state).data;

export const selectProjectLoading = (state: RootState): boolean =>
  selectProjectSlice(state).loading;

export const selectProjectData = (state: RootState) =>
  selectProjectSlice(state).data;

export const selectProjectClient = (state: RootState) => {
  return state.project.data && state.project.data.cliente;
};
export const selectChangeProjectStateSlice = (state: RootState) =>
  state.changeProjectState;

export const selectChangeProjectStateLoading = (state: RootState) =>
  selectChangeProjectStateSlice(state).loading;

export const selectChangeProjectStateVisible = (state: RootState) =>
  selectChangeProjectStateSlice(state).visible;

export const selectChangeProjectStateErrors = (state: RootState) =>
  selectChangeProjectStateSlice(state).errors;

export const selectNewProject = (state: RootState) => state.newProject;
export const selectNewProjectData = (state: RootState) =>
  state.newProject && state.newProject.data;
export const selectNewProjectVisible = (state: RootState) =>
  selectNewProject(state).visible;
export const selectNewProjectErrors = (state: RootState) =>
  selectNewProject(state).errors;
export const selectNewProjectLoading = (state: RootState) =>
  selectNewProject(state).loading;

export const selectClientFromProject = (state: RootState) =>
  selectNewProjectData(state)?.cliente || selectProjectData(state)?.cliente;
