import { Values } from 'ibs-cengine/dist/types/Calculator';
import { Guarantee } from '../../../types/offers/offers';
import { getValueAsBoolean, getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaIPA(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.i.pa.aplica', values)) {
    return null;
  }
  const garantia: Guarantee = {
    garantia: 'Invalidez Permanente Absoluta',
    categoria: 'invalidez',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.i.pa.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.i.pa.prima', values),
    capital: getValueAsNumber(
      '.a.' + who + '.e.n.i.pa.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.i.pa.ci', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.i.pa.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.i.pa.aplicar', values),
    editable: true,
    obligatoria: false,
    bookPath: 'incapacidad-permanente-absoluta',
  };
  garantia.lineas.push({
    concepto: 'Capital fijo',
    tipo: 'capital',
    codigo_linea: '01',
    capital: 0,
    capital_manual: getValueAsNumber(
      '.o.' + who + '.w12.i.pa.capital.cf',
      values
    ),
    orden: 1,
    detalle: {},
  });
  garantia.lineas.push({
    concepto: 'Capital derivado de renta mensual',
    tipo: 'renta',
    codigo_linea: '02',
    renta: getValueAsNumber('.o.' + who + '.w12.i.pa.rm.recomendada', values),
    renta_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.i.pa.rm.mensual', values)
    ),
    duracion: getValueAsNumber(
      '.o.' + who + '.w12.i.pa.rm.duracion_recomendada',
      values
    ),
    duracion_manual: getValueAsNumber(
      '.o.' + who + '.w12.i.pa.rm.anos',
      values
    ),
    capital: getValueAsNumber(
      '.a.' + who + '.e.n.i.pa.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.i.pa.rm.ci', values)
    ),
    orden: 2,
    detalle: {},
    aplicable: getValueAsBoolean('.o.' + who + '.w12.i.pa.rm.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.i.pa.rm.aplicar', values),
  });
  return garantia;
}

export function getGarantiaInvalidezAccidente(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.i.paa.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Invalidez Permanente Absoluta por Accidente',
    categoria: 'invalidez',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.i.paa.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.i.paa.prima', values),
    capital: getValueAsNumber('.a.' + who + '.e.n.i.pa.cf_recomendado', values),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.i.paa.cf', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.i.paa.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.i.paa.aplicar', values),
    editable: true,
    obligatoria: false,
  };
}

export function getGarantiaInvalidezAccidenteCirculacion(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.i.pac.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Invalidez Permanente Absoluta por Accidente de Circulación',
    categoria: 'invalidez',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.i.pac.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.i.pac.prima', values),
    capital: getValueAsNumber('.a.' + who + '.e.n.i.pa.cf_recomendado', values),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.i.pac.cf', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.i.pac.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.i.pac.aplicar', values),
    editable: true,
    obligatoria: false,
  };
}
