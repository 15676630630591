import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Icon40 } from '../../../../assets/asideMenu/icon_40.svg';
import { ReactComponent as IconAgenda } from '../../../../assets/asideMenu/icon_agenda.svg';
import { ReactComponent as IconChallenges } from '../../../../assets/asideMenu/icon_challenges.svg';
import { ReactComponent as IconClients } from '../../../../assets/asideMenu/icon_clients.svg';
import { ReactComponent as IconControlPanel } from '../../../../assets/asideMenu/icon_control_panel.svg';
import { ReactComponent as IconDashboard } from '../../../../assets/asideMenu/icon_dashboard.svg';
import { ReactComponent as IconNotifications } from '../../../../assets/asideMenu/icon_notifications.svg';
import { ReactComponent as IconOval } from '../../../../assets/asideMenu/icon_oval.svg';
import { ReactComponent as IconPlus } from '../../../../assets/asideMenu/icon_plus.svg';
import { ReactComponent as IconProjects } from '../../../../assets/asideMenu/icon_projects.svg';
import { ReactComponent as IconSales } from '../../../../assets/asideMenu/icon_sales.svg';
import { ReactComponent as IconUsers } from '../../../../assets/asideMenu/icon_users.svg';
import iconUser from '../../../../assets/icon_user.svg';
import styles from '../../../../components/Layouts/Layout/layout.module.scss';
import { selecthasUnreadAlerts } from '../../../../store/alerts/selectors';
import { isAreaGestion } from '../../../../store/app/selectors';
import { selectHasAnyPermission } from '../../../../store/auth/selectors';
import { show } from '../../../../store/project/newProjectModule';
import { AppDispatch, RootState } from '../../../../store/store';
import MenuLogout from '../../../../themes/active/components/Layouts/MenuLogout/MenuLogout';

interface MenuProps {
  isActive: boolean;
  isOpenMenu: boolean;
  userAvatar: string | null;
  canListUsers: boolean;
  canListClients: boolean;
  canListProjects: boolean;
  canCreateProjects: boolean;
  canCreateAppointments: boolean;
  canViewSettingsAndSales: boolean;
  canViewNotifications: boolean;
  canViewChallenges: boolean;
  hasUnreadAlerts: boolean;
  setIsOpenMenu: (open: boolean) => void;
  styleRoute: (route: string) => string;
  showNewProject: () => void;
}

const Menu: React.FC<MenuProps> = ({
  isActive,
  isOpenMenu,
  userAvatar,
  canListUsers,
  canListClients,
  canListProjects,
  canCreateProjects,
  canCreateAppointments,
  canViewSettingsAndSales,
  canViewNotifications,
  canViewChallenges,
  hasUnreadAlerts,
  setIsOpenMenu,
  styleRoute,
  showNewProject,
}) => {
  if (!isActive) return null;

  return (
    <nav
      data-testid={isOpenMenu ? 'isMenuVisible' : 'isMenuHidden'}
      className={`${styles.navigation} ${isOpenMenu && styles.isOpen}`}
    >
      <button
        aria-label={isOpenMenu ? 'Cerrar menú' : 'Abrir menú'}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        className={`${styles.burger} ${styles.icon} ${
          isOpenMenu ? styles.isOpen : ''
        }`}
        type="button"
      >
        <span className={styles.burgerBox}>
          <span className={styles.burgerInner}></span>
        </span>
      </button>
      <ul className={styles.mainMenu}>
        <li className={styleRoute('/')}>
          <Link data-testid="dashboardLink" className={styles.menuLink} to="/">
            <div className={styles.iconWrapper}>
              <IconDashboard className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isDashboardVisible' : 'isDashboardHidden'
              }
              className={styles.menuText}
            >
              Dashboard
            </span>
          </Link>
        </li>
        {canListClients && (
          <li className={styleRoute('/clientes')}>
            <Link
              data-testid="clientsLink"
              className={styles.menuLink}
              to="/clientes"
            >
              <div className={styles.iconWrapper}>
                <IconClients className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isClientsVisible' : 'isClientsHidden'
                }
                className={styles.menuText}
              >
                Clientes
              </span>
            </Link>
          </li>
        )}
        {canListUsers && (
          <li className={styleRoute('/usuarios')}>
            <Link
              data-testid="usersLink"
              className={styles.menuLink}
              to="/usuarios"
            >
              <div className={styles.iconWrapper}>
                <IconUsers className={styles.menuIcon} />
              </div>
              <span
                data-testid={isOpenMenu ? 'isUsersVisible' : 'isUsersHidden'}
                className={styles.menuText}
              >
                Usuarios
              </span>
            </Link>
          </li>
        )}
        {canListProjects && (
          <li className={styleRoute('/proyectos')}>
            <Link
              data-testid="projectsLink"
              className={styles.menuLink}
              to="/proyectos"
            >
              <div className={styles.iconWrapper}>
                <IconProjects className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isProjectsVisible' : 'isProjectsHidden'
                }
                className={styles.menuText}
              >
                Proyectos
              </span>
            </Link>
          </li>
        )}
        <li className={styleRoute('/estadisticas')}>
          <Link
            data-testid="main40Link"
            className={styles.menuLink}
            to="/estadisticas"
          >
            <div className={styles.iconWrapper}>
              <Icon40 className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isMain40Visible' : 'isMain40Hidden'}
              className={styles.menuText}
            >
              40 Principales
            </span>
          </Link>
        </li>
        {canViewSettingsAndSales && (
          <li className={styleRoute('/ventas')}>
            <Link
              data-testid="salesLink"
              className={styles.menuLink}
              to="/ventas"
            >
              <div className={styles.iconWrapper}>
                <IconSales className={styles.menuIcon} />
              </div>
              <span
                data-testid={isOpenMenu ? 'isSalesVisible' : 'isSalesHidden'}
                className={styles.menuText}
              >
                Ventas
              </span>
            </Link>
          </li>
        )}
        {canViewSettingsAndSales && (
          <li className={styleRoute('/ajustes')}>
            <Link
              data-testid="settingsLink"
              className={styles.menuLink}
              to="/ajustes"
            >
              <div className={styles.iconWrapper}>
                <IconControlPanel className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isSettingsVisible' : 'isSettingsHidden'
                }
                className={styles.menuText}
              >
                Ajustes
              </span>
            </Link>
          </li>
        )}
        {canCreateProjects && (
          <li className={`${styles.isProject} ${styleRoute('/proyecto')}`}>
            <div
              data-testid="projectLink"
              className={styles.menuLink}
              onClick={showNewProject}
            >
              <div className={styles.iconWrapper}>
                <IconPlus className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isProjectVisible' : 'isProjectHidden'
                }
                className={`${styles.new_project_description} ${styles.menuText}`}
              >
                Proyecto
              </span>
            </div>
          </li>
        )}
        {canViewNotifications && (
          <li
            className={`${styleRoute('/notificaciones')} ${
              !canCreateProjects ? styles.isNotification : ''
            }`}
          >
            <Link
              data-testid="notificationsLink"
              className={styles.menuLink}
              to="/notificaciones"
            >
              <div
                className={`${styles.iconWrapper} ${
                  hasUnreadAlerts ? styles.alarmAnimation : ''
                }`}
              >
                {hasUnreadAlerts && <IconOval className={styles.alarmOval} />}
                <IconNotifications className={styles.menuIcon} />
              </div>
              <span className={styles.menuText}>Alertas</span>
            </Link>
          </li>
        )}
        {canViewChallenges && (
          <li className={styleRoute('/retos')}>
            <Link
              data-testid="challengesLink"
              className={styles.menuLink}
              to="/retos"
            >
              <div className={styles.iconWrapper}>
                <IconChallenges className={styles.menuIcon} />
              </div>
              <span className={styles.menuText}>Retos</span>
            </Link>
          </li>
        )}
        {canCreateAppointments && (
          <li className={`${styleRoute('/agenda')}`}>
            <Link
              data-testid="agendaLink"
              className={styles.menuLink}
              to="/agenda"
            >
              <div className={styles.iconWrapper}>
                <IconAgenda className={styles.menuIcon} />
              </div>
              <span className={styles.menuText}>Agenda</span>
            </Link>
          </li>
        )}
        <MenuLogout styleRoute={styleRoute} />
        <li className={`${styleRoute('/perfil')}`}>
          <Link
            className={styles.menuLink}
            data-testid="perfilLink"
            to="/perfil"
          >
            <div className={styles.iconWrapper}>
              <img
                src={userAvatar || iconUser}
                alt="Enlace al perfil"
                className={styles.icon}
              />
            </div>
            <span className={styles.menuText}>Mi perfil</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default connect(
  (state: RootState) => ({
    isActive: isAreaGestion(state),
    canListUsers: selectHasAnyPermission(state, ['list_users']),
    canListClients: selectHasAnyPermission(state, ['list_clients']),
    canListProjects: selectHasAnyPermission(state, ['list_projects']),
    canCreateProjects: selectHasAnyPermission(state, ['create_project']),
    canCreateAppointments: selectHasAnyPermission(state, [
      'create_client_appointment',
    ]),
    hasUnreadAlerts: selecthasUnreadAlerts(state),
    // TODO: cambiar por permisos cuando se hayan creado
    canViewSettingsAndSales: false,
    canViewNotifications: true,
    canViewChallenges: true,
  }),
  (dispatch: AppDispatch) => ({
    showNewProject: () => dispatch(show()),
  })
)(Menu);
