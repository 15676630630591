import { Result, validatedApiCall } from '../api';
import { DictionaryData } from '../../types/dictionary/dictionary';
import { APIValidationError } from '../../types/api/api';

export const getDefinitions = async (): Promise<
  Result<DictionaryData, APIValidationError>
> => {
  return validatedApiCall<DictionaryData>(`/api/dictionary`, {
    method: 'GET',
    auth: true,
  });
};
