import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../../components/Layouts/Layout/Layout';
import PrivateRoute from '../../components/Router/PrivateRoute/PrivateRoute';
import { canViewComputedOffer } from '../../store/dataCollection/gates';

const IncapacidadTemporalRoute = lazy(
  () =>
    import('./ExplanatoryStatements/IncapacidadTemporal/IncapacidadTemporalRoute')
);
const OrfandadRoute = lazy(
  () => import('./ExplanatoryStatements/Orfandad/OrfandadRoute')
);
const OrfandadHijoRoute = lazy(
  () => import('./ExplanatoryStatements/Orfandad/OrfandadHijoRoute')
);
const IncapacidadPermanenteTotalRoute = lazy(
  () =>
    import(
      './ExplanatoryStatements/IncapacidadPermanenteTotal/IncapacidadPermanenteTotalRoute'
    )
);
const IPTCapitalFijoRoute = lazy(
  () =>
    import('./ExplanatoryStatements/IncapacidadPermanenteTotal/IPTCapitalFijoRoute')
);
const IncapacidadPermanenteAbsolutaRoute = lazy(
  () =>
    import(
      './ExplanatoryStatements/IncapacidadPermanenteAbsoluta/IncapacidadPermanenteAbsolutaRoute'
    )
);
const IPARentaMensualRoute = lazy(
  () =>
    import(
      './ExplanatoryStatements/IncapacidadPermanenteAbsoluta/IPARentaMensualRoute'
    )
);
const GastoMensualEfectivoRoute = lazy(
  () =>
    import('./ExplanatoryStatements/GastoMensualEfectivo/GastoMensualEfectivoRoute')
);
const BaseCotizacionRoute = lazy(
  () => import('./ExplanatoryStatements/BaseCotizacion/BaseCotizacionRoute')
);
const BaseReguladoraMediaRoute = lazy(
  () =>
    import('./ExplanatoryStatements/BaseReguladoraMedia/BaseReguladoraMediaRoute')
);
const EnfermedadGraveRoute = lazy(
  () => import('./ExplanatoryStatements/EnfermedadGrave/EnfermedadGraveRoute')
);
const ViudedadRoute = lazy(
  () => import('./ExplanatoryStatements/Viudedad/ViudedadRoute')
);
const ViudedadHijosRoute = lazy(
  () => import('./ExplanatoryStatements/Viudedad/ViudedadHijosRoute')
);
const Cargas = lazy(() => import('./ExplanatoryStatements/Cargas/Cargas'));
const NotFound = lazy(() => import('../../routes/NotFound/NotFound'));

const ExposicionRoutes = () => (
  <Switch>
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/base-cotizacion/:asegurado/:cobertura"
      component={BaseCotizacionRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/gasto-mensual-efectivo/:asegurado/:cobertura"
      component={GastoMensualEfectivoRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/base-reguladora-media/:asegurado/:cobertura"
      component={BaseReguladoraMediaRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/incapacidad-temporal/:tipoCapitalOferta/:asegurado"
      component={IncapacidadTemporalRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/incapacidad-permanente-total/:tipoCapitalOferta/:asegurado/capital-fijo"
      component={IPTCapitalFijoRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/incapacidad-permanente-total/:tipoCapitalOferta/:asegurado"
      component={IncapacidadPermanenteTotalRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/IPA-renta-mensual/:tipoCapitalOferta/:asegurado"
      component={IPARentaMensualRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/incapacidad-permanente-absoluta/:tipoCapitalOferta/:asegurado"
      component={IncapacidadPermanenteAbsolutaRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/orfandad/hijos/:tipoCapitalOferta/:asegurado"
      component={OrfandadHijoRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/orfandad/:tipoCapitalOferta/:asegurado"
      component={OrfandadRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/enfermedad-grave/:tipoCapitalOferta/:asegurado"
      component={EnfermedadGraveRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/viudedad/hijos/:tipoCapitalOferta/:asegurado"
      component={ViudedadHijosRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/viudedad/:tipoCapitalOferta/:asegurado"
      component={ViudedadRoute}
      gate={canViewComputedOffer}
    />
    <PrivateRoute
      allowedRoles={[
        'admin_ibs',
        'admin_int',
        'comercial',
        'director_comercial',
        'gestor_area',
      ]}
      path="/valoracion/exposicion-motivos/cargas"
      component={Cargas}
      gate={canViewComputedOffer}
    />
    <Layout>
      <Route path="/" component={NotFound} />
    </Layout>
  </Switch>
);

export default ExposicionRoutes;
