import React from 'react';
import styles from './loading.module.scss';

const Loading: React.FC = () => (
  <div data-testid="spinner" className={styles.sk_chase}>
    <div className={styles.sk_chase_dot}></div>
    <div className={styles.sk_chase_dot}></div>
    <div className={styles.sk_chase_dot}></div>
    <div className={styles.sk_chase_dot}></div>
    <div className={styles.sk_chase_dot}></div>
    <div className={styles.sk_chase_dot}></div>
  </div>
);

export default Loading;
