import { RootState } from '../store';

export const selectChangeOfferState = (state: RootState) =>
  state.changeOfferState;

export const selectChangeOfferStateData = (state: RootState) =>
  selectChangeOfferState(state).data;

export const selectChangeOfferStateLoading = (state: RootState) =>
  selectChangeOfferState(state).loading;

export const selectChangeOfferStateVisible = (state: RootState) =>
  selectChangeOfferState(state).visible;

export const selectChangeOfferStateErrors = (state: RootState) =>
  selectChangeOfferState(state).errors;

export const selectHasOffer = (state: RootState) => !!state.newOffer?.data;

export const selectNewOfferLoading = (state: RootState) =>
  state.newOffer.loading;

export const selectSendPdf = (state: RootState) => state.sendPdf;

export const selectSendPdfData = (state: RootState) =>
  selectSendPdf(state).data;

export const selectSendPdfLoading = (state: RootState) =>
  selectSendPdf(state).loading;

export const selectSendPdfVisible = (state: RootState) =>
  selectSendPdf(state).visible;
