import { Result, validatedApiCall } from '../api';
import { GetProjectsDTO, ProjectsDTO } from '../../types/projects/projects';
import { APIValidationError } from '../../types/api/api';
import toQueryParams from '../../utils/toQueryParams';

export const getAllProjects = async (
  data: GetProjectsDTO
): Promise<Result<ProjectsDTO, APIValidationError>> => {
  return validatedApiCall<ProjectsDTO>(
    `/api/proyectos?${toQueryParams(data as Record<string, string>)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
