import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { changeClientState } from '../../api/clients/changeClientState';
import { ChangeClientStateDTO } from '../../types/actions/actions';
import { Await } from '../../types/api/api';
import snackBarSlice from '../snackBar/snackBarSlice';
import changeClientStateSlice from './changeClientStateSlice';

function* registerSaga(
  action: PayloadAction<ChangeClientStateDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(changeClientState, action.payload)) as Await<
      ReturnType<typeof changeClientState>
    >;
    switch (result.type) {
      case 'ok':
        yield put(changeClientStateSlice.actions.changeClientStateOk());
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Cliente actualizado correctamente.',
            severity: 'success',
          })
        );
        yield put(changeClientStateSlice.actions.hideChangeClientState());
        document.location.reload();
        return;
      case 'validation-error':
        yield put(
          changeClientStateSlice.actions.changeClientStateKo(result.value)
        );
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'No hemos podido actualizar el cliente.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(changeClientStateSlice.actions.changeClientStateKo(e));
  }
}

const sagas = [
  takeLatest<PayloadAction<ChangeClientStateDTO>>(
    changeClientStateSlice.actions.changeClientState.type,
    registerSaga
  ),
];

export default sagas;
