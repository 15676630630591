import { Result, validatedApiCall } from '../api';
import { User } from '../../types/users/users';
import { ChangeResponsableDTO } from '../../types/actions/actions';
import { APIValidationError } from '../../types/api/api';

export const changeResponsable = async (
  data: ChangeResponsableDTO
): Promise<Result<User, APIValidationError>> => {
  const { user_id, responsable_id } = data;

  return validatedApiCall<User>(`/api/users/${user_id}/manager`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ responsable_id }),
  });
};
