import { getProjectOffers } from '../../api/offers/getProjectOffers';
import { Offer } from '../../types/offers/offers';
import createApiModule from '../createApiModule';

const getOffersModule = createApiModule({
  name: 'offers',
  apiMethod: getProjectOffers,
  initialData: [] as Offer[],
  parse: (offers) => offers.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar las ofertas',
  }),
});

export const {
  start: getOffers,
  success: getOffersOk,
  error: getOffersKo,
  reset: resetOffers,
} = getOffersModule.slice.actions;

export default getOffersModule;
