import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { changeArea } from '../../api/users/changeArea';
import { ChangeAreaDTO } from '../../types/actions/actions';
import { Await } from '../../types/api/api';
import snackBarSlice from '../snackBar/snackBarSlice';
import changeAreaSlice from './changeAreaSlice';

function* changeAreaSaga(
  action: PayloadAction<ChangeAreaDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(changeArea, action.payload)) as Await<
      ReturnType<typeof changeArea>
    >;
    switch (result.type) {
      case 'ok':
        yield put(changeAreaSlice.actions.changeAreaOk());
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'El cambio se ha realizado correctamente.',
            severity: 'success',
          })
        );
        yield put(changeAreaSlice.actions.hideChangeArea());
        return;
      case 'validation-error':
        yield put(changeAreaSlice.actions.changeAreaKo(result.value));
        return;
    }
  } catch (e) {
    yield put(changeAreaSlice.actions.changeAreaKo(e));
  }
}

const sagas = [
  takeLatest<PayloadAction<ChangeAreaDTO>>(
    changeAreaSlice.actions.changeArea.type,
    changeAreaSaga
  ),
];

export default sagas;
