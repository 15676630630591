import React, { Suspense } from 'react';
import { Router, Switch } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import LoginRestrictions from '../components/LoginRestrictions/LoginRestrictions';
import PublicRoute from '../components/Router/PublicRoute/PublicRoute';
import { history } from '../store/store';
import Login from './Login/Login';

const Index = () => (
  <Router history={history}>
    <Suspense fallback={<Loading />}>
      <Switch>
        <PublicRoute exact path="/login" component={Login} />
        <LoginRestrictions />
      </Switch>
    </Suspense>
  </Router>
);

export default Index;
