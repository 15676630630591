import { APIValidationError } from '../../types/api/api';
import { GetProjectsDTO, ProjectsDTO } from '../../types/projects/projects';
import { Result, validatedApiCall } from '../api';

export const getProjects = async (
  data: GetProjectsDTO
): Promise<Result<ProjectsDTO, APIValidationError>> => {
  const {
    page = 0,
    size = 5,
    buscar = '',
    user_id = '',
    estado = '',
    cliente_id = '',
  } = data;
  return validatedApiCall<ProjectsDTO>(
    `/api/proyectos?page=${page}&size=${size}&buscar=${buscar}&user_id=${user_id}&estado=${estado}&cliente_id=${cliente_id}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
