import { delay, put, select, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { EditOfferAmount } from '../../types/offers/tabulated_offers';
import schemasSlice from '../schemas/schemasSlice';
import { selectEngineState } from '../schemas/selectors';
import capitalModificationSlice from './capitalModificationSlice';

function* computeNewOffer(action: PayloadAction<EditOfferAmount>) {
  try {
    //wait for engine loaded
    let engineState = yield select(selectEngineState);
    while (engineState !== 'loaded') {
      yield delay(500);
      engineState = yield select(selectEngineState);
    }
    yield put(schemasSlice.actions.computeOffer());
  } catch (e) {}
}

const sagas = [
  takeEvery<PayloadAction<never>>(
    capitalModificationSlice.actions.saveCapitalModificationAndCompute.type,
    computeNewOffer
  ),
  takeEvery<PayloadAction<never>>(
    capitalModificationSlice.actions.saveApplyGuaranteeAndCompute.type,
    computeNewOffer
  ),
];

export default sagas;
