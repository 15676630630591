import { Values } from 'ibs-cengine/dist/types/Calculator';
import { APIError } from '../../types/api/api';
import { AseguradoOferta } from '../../types/offers/asegurado_oferta';
import { TipoCapitalOferta, TipoCapitalOfertaEnum } from '../../types/offers/offers';
import { CalculatorErrors, PersonErrors } from '../../types/schemas/schemas';
import { CoberturaType } from '../../valoracion/types/explanatoryStatements/explanatoryStatements';
import { selectComputeDoubleOffer } from '../dataCollection/selectors';
import { RootState } from '../store';
import {
  getEstudioBaseCotizacion,
  getEstudioBaseReguladoraMediaIPA,
  getEstudioBaseReguladoraMediaIPT,
  getEstudioBaseReguladoraMediaIT,
  getEstudioBaseReguladoraMediaOrfandad,
  getEstudioBaseReguladoraMediaViudedad,
  getEstudioEnfermedadGrave,
  getEstudioGastoMensualEfectivo,
  getEstudioIncapacidadTemporal,
  getEstudioIPA,
  getEstudioIPT,
  getEstudioOrfandad,
  getEstudioViudedad,
} from './Estudio';
import { getOferta } from './Oferta';
import {
  getAverageExpediture,
  getConfigRETA,
  getValueAsBoolean,
  getValueAsString,
  hasErrors,
  hasGlobalErrors,
  hasGlobalMessages,
  hasGlobalWarnings,
  hasLocalErrors,
} from './util';

export const empty_errors: CalculatorErrors = {
  principal: { local: {}, global: {} },
  conyuge: { local: {}, global: {} },
};

export const selectEngineState = (state: RootState) =>
  state.schemas.engineState;

export const selectEngineLoaded = (state: RootState) =>
  state.schemas.engineState === 'loaded';

export const selectOfferState = (state: RootState) => state.schemas.offerState;

export const selectLoadError: APIError | any = (state: RootState) =>
  state.schemas.engineState === 'loadFail' ? state.schemas.error : null;

export const selectVersionId = (state: RootState) =>
  state.schemas.engineState === 'loaded' ? state.schemas.version_id : null;

export const selectComputedOffer = (state: RootState) =>
  (state.schemas.offerState === 'computed' ||
    state.schemas.offerState === 'computeError') &&
  state.schemas.computedOffer
    ? getOferta(state.schemas.computedOffer.values, 'principal')
    : null;

export const selectBaseCotizacion = (
  state: RootState,
  asegurado: AseguradoOferta
) =>
  getDataForExplanatoryStatements(state, getEstudioBaseCotizacion, asegurado);

export const selectGastoMensualEfectivo = (
  state: RootState,
  asegurado: AseguradoOferta
) =>
  getDataForExplanatoryStatements(
    state,
    getEstudioGastoMensualEfectivo,
    asegurado
  );

export const selectBaseReguladoraMedia = (
  state: RootState,
  asegurado: AseguradoOferta,
  cobertura: CoberturaType
) =>
  getDataForExplanatoryStatements(
    state,
    baseReguladoraMediaFnEstudioByCobertura(cobertura),
    asegurado
  );

const baseReguladoraMediaFnEstudioByCobertura = (cobertura: CoberturaType) => {
  switch (cobertura) {
    case 'it':
      return getEstudioBaseReguladoraMediaIT;
    case 'ipa':
      return getEstudioBaseReguladoraMediaIPA;
    case 'ipt':
      return getEstudioBaseReguladoraMediaIPT;
    case 'viudedad':
      return getEstudioBaseReguladoraMediaViudedad;
    case 'orfandad':
      return getEstudioBaseReguladoraMediaOrfandad;
  }
};

export const selectComputedOfferConyuge = (state: RootState) => {
  return selectComputeDoubleOffer(state) &&
    (state.schemas.offerState === 'computed' ||
      state.schemas.offerState === 'computeError') &&
    state.schemas.computedOffer
    ? getOferta(state.schemas.computedOffer.values, 'conyuge')
    : null;
};

export const selectComputedOfferInputs = (state: RootState) =>
  state.schemas.offerState === 'computed' ||
  state.schemas.offerState === 'computeError'
    ? state.schemas.computedOffer.inputs
    : null;
export const selectComputedOfferOutputs = (state: RootState) =>
  state.schemas.offerState === 'computed' ||
  state.schemas.offerState === 'computeError'
    ? state.schemas.computedOffer.values
    : null;

export const selectOfferComputeErrors = (state: RootState): CalculatorErrors =>
  state.schemas?.offerState === 'computeError'
    ? state.schemas.computeErrors
    : empty_errors;

export const selectPrincipalErrors = (state: RootState): PersonErrors =>
  state.schemas.offerState === 'computeError'
    ? state.schemas.computeErrors.principal
    : empty_errors.principal;

export const selectConyugeErrors = (state: RootState): PersonErrors =>
  state.schemas.offerState === 'computeError' &&
  !!state.schemas.computedOffer &&
  getValueAsBoolean(
    '.a.necesidades_compartidas',
    state.schemas.computedOffer?.values
  )
    ? state.schemas.computeErrors.conyuge
    : empty_errors.conyuge;

export const selectHasGlobalMessages = (
  state: RootState,
  who?: AseguradoOferta
): boolean => {
  if (state.schemas.offerState !== 'computeError') {
    return false;
  }
  if (who) {
    if (who === 'conyuge') {
      return hasGlobalMessages(selectConyugeErrors(state));
    } else {
      return hasGlobalMessages(selectPrincipalErrors(state));
    }
  }
  return (
    hasGlobalMessages(selectConyugeErrors(state)) ||
    hasGlobalMessages(selectPrincipalErrors(state))
  );
};

export const selectHasGlobalErrors = (
  state: RootState,
  who?: AseguradoOferta
): boolean => {
  if (state.schemas.offerState !== 'computeError') {
    return false;
  }
  if (who) {
    if (who === 'conyuge') {
      return hasGlobalErrors(selectConyugeErrors(state));
    } else {
      return hasGlobalErrors(selectPrincipalErrors(state));
    }
  }
  return (
    hasGlobalErrors(selectConyugeErrors(state)) ||
    hasGlobalErrors(selectPrincipalErrors(state))
  );
};

export const selectHasGlobalWarnings = (
  state: RootState,
  who?: AseguradoOferta
): boolean => {
  if (state.schemas.offerState !== 'computeError') {
    return false;
  }
  if (who) {
    if (who === 'conyuge') {
      return hasGlobalWarnings(selectConyugeErrors(state));
    } else {
      return hasGlobalWarnings(selectPrincipalErrors(state));
    }
  }
  return (
    hasGlobalWarnings(selectConyugeErrors(state)) ||
    hasGlobalWarnings(selectPrincipalErrors(state))
  );
};

export const selectHasLocalErrors = (
  state: RootState,
  who?: AseguradoOferta
): boolean => {
  if (state.schemas.offerState !== 'computeError') {
    return false;
  }
  if (who) {
    if (who === 'conyuge') {
      return hasLocalErrors(selectConyugeErrors(state));
    } else {
      return hasLocalErrors(selectPrincipalErrors(state));
    }
  }
  return (
    hasLocalErrors(selectConyugeErrors(state)) ||
    hasLocalErrors(selectPrincipalErrors(state))
  );
};

export const selectHasErrors = (state: RootState, who?: AseguradoOferta) => {
  if (who === 'conyuge') {
    return hasErrors(selectConyugeErrors(state));
  } else if (who === 'principal') {
    return hasErrors(selectPrincipalErrors(state));
  }
  return (
    hasErrors(selectConyugeErrors(state)) ||
    hasErrors(selectPrincipalErrors(state))
  );
};

export const selectIsOfferComputed = (state: RootState) =>
  state.schemas.offerState === 'initial' ||
  state.schemas.offerState === 'computed' ||
  state.schemas.offerState === 'computeError';

export const selectCanViewOfferList = (state: RootState) =>
  state.valoracion.ready;

const getDataForExplanatoryStatements = (
  state: RootState,
  fn: (values: Values, asegurado: AseguradoOferta, tipoCapitalOferta?: TipoCapitalOferta) => any,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta,
) => {
  if (
    state.schemas.offerState === 'computed' ||
    state.schemas.offerState === 'computeError'
  ) {
    if (!tipoCapitalOferta 
      || tipoCapitalOferta === TipoCapitalOfertaEnum.capitalesRecomendados
      || tipoCapitalOferta === TipoCapitalOfertaEnum.capitalesModificados) {
        return fn(state.schemas.computedOffer.values, asegurado, tipoCapitalOferta);
    }
  }
  if (state.requestOffer?.data?.outputs) {
    return fn(state.requestOffer.data.outputs, asegurado, tipoCapitalOferta);
  } else {
    return null;
  }
};

export const selectEstudioViudedad = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) => getDataForExplanatoryStatements(state, getEstudioViudedad, asegurado, tipoCapitalOferta);

export const selectEstudioEnfermedadGrave = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) =>
  getDataForExplanatoryStatements(state, getEstudioEnfermedadGrave, asegurado, tipoCapitalOferta);

export const selectEstudioIncapacidadTemporal = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta: TipoCapitalOferta
) =>
  getDataForExplanatoryStatements(
    state,
    getEstudioIncapacidadTemporal,
    asegurado,
    tipoCapitalOferta
  );

export const selectEstudioIPT = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) => getDataForExplanatoryStatements(state, getEstudioIPT, asegurado, tipoCapitalOferta);

export const selectEstudioIPA = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) => getDataForExplanatoryStatements(state, getEstudioIPA, asegurado, tipoCapitalOferta);

export const selectEstudioOrfandad = (
  state: RootState,
  asegurado: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) => getDataForExplanatoryStatements(state, getEstudioOrfandad, asegurado, tipoCapitalOferta);

export const selectAverageExpenditureIne = (state: RootState) =>
  state.schemas.offerState === 'computed' ||
  state.schemas.offerState === 'computeError'
    ? getAverageExpediture(state.schemas.computedOffer.values)
    : null;

export const selectSchemaTable = (state: RootState, tableId: string) =>
  state.schemas.engineState === 'loaded' ? state.schemas.tables[tableId] : [];

export const selectSchemaValue = (state: RootState, path: string) =>
  state.schemas.engineState === 'loaded'
    ? getValueAsString(path, state.schemas.initialValues)
    : '';

export const selectConfigRETA = (state: RootState) =>
  state.schemas.engineState === 'loaded'
    ? getConfigRETA(state.schemas.initialValues)
    : { base_min: 0, base_max: 0, cuota_min: 0, cuota_max: 0 };

export const selectCargas = (state: RootState) =>
  state.schemas.offerState === 'computed' ||
  state.schemas.offerState === 'computeError'
    ? state.schemas.computedOffer.data.cargas
    : null;
