import {
  AuthDTO,
  LoginDTO,
  ResetPasswordDTO,
  UserInfo,
  ChangePasswordDTO,
  ChangeAvatarDTO,
  UserDTO,
} from '../../types/auth/auth';
import { apiCall, Result, validatedApiCall } from '../api';
import { User } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';

export const login = async (
  data: LoginDTO
): Promise<Result<AuthDTO, APIValidationError>> =>
  validatedApiCall<AuthDTO>(`/api/auth/login`, {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const logout = async (): Promise<void> =>
  apiCall<never>(`/api/auth/logout`, {
    method: 'POST',
    auth: true,
  });

export const resetPassword = async (
  data: ResetPasswordDTO
): Promise<Result<UserInfo, APIValidationError>> =>
  validatedApiCall<UserInfo>(`/api/auth/password`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });

export const updateProfile = async (
  data: User
): Promise<Result<User, APIValidationError>> =>
  validatedApiCall<User>(`/api/auth/profile`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });

export const updatePassword = async (
  data: ChangePasswordDTO
): Promise<Result<ChangePasswordDTO, APIValidationError>> =>
  validatedApiCall<ChangePasswordDTO>(`/api/auth/profile`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });

export const updateAvatar = async (
  data: ChangeAvatarDTO
): Promise<Result<User, APIValidationError>> =>
  validatedApiCall<User>(`/api/auth/profile`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  });

export const getProfile = async (): Promise<
  Result<UserDTO, APIValidationError>
> =>
  validatedApiCall<UserDTO>(`/api/auth/profile/`, {
    auth: true,
    method: 'GET',
  });
