import { updateClient } from '../../api/clients/updateClient';
import createApiModule from '../createApiModule';
import { getClientOk } from './clientModule';

const updateClientModule = createApiModule({
  name: 'updateClient',
  apiMethod: updateClient,
  onSuccess: (client) => getClientOk(client),
  onError: () => ({
    message: 'Error al actualizar el cliente',
  }),
  onValidationError: () => ({
    message: 'Error al actualizar el cliente',
  }),
});

export const {
  start: updateClientData,
  success: updateClientDataKo,
  error: updateClientDataOk,
} = updateClientModule.slice.actions;

export default updateClientModule;
