import { RootState } from '../store';
import { formatTime } from '../../helpers/formatTime';
import { format } from 'date-fns';
import {
  Appointment,
  UpcomingAppointment,
} from '../../types/appointments/appointments';

export const selectAppointments = (state: RootState) => state.schedule.data;

export const selectAppointmentsLoading = (state: RootState) =>
  state.schedule.loading;

export const selectNewAppointmentLoading = (state: RootState) =>
  state.newAppointment.loading;

export const selectNewAppointmentErrors = (state: RootState) =>
  state.newAppointment.errors;

export const selectTakenAppointmentsLoading = (state: RootState) =>
  state.takenAppointments.loading;

export const selectTakenAppointments = (state: RootState) =>
  state.takenAppointments.data;

export const selectRemoveAppointment = (state: RootState) =>
  state.removeAppointment?.selectedAppointment;

export const selectRemoveAppointmentActive = (state: RootState) =>
  state.removeAppointment?.active;

export const selectRemoveAppointmentLoading = (state: RootState) =>
  state.removeAppointment?.loading;

export const selectUpcomingAppointmentsLoading = (state: RootState) =>
  state.upcomingAppointments?.loading;

export const selectUpcomingAppointments = (state: RootState) =>
  state.upcomingAppointments.data.reduce(
    (list: UpcomingAppointment[], appointment: Appointment) => {
      const index = list.findIndex((e: { date: string }) => {
        return (
          e.date === format(new Date(appointment.fecha_hora), 'dd-MM-yyyy')
        );
      });

      const dataAppointment = {
        name: `${appointment.nombre} ${appointment.apellido1} ${appointment.apellido2}`,
        time: formatTime(appointment.fecha_hora),
        id: appointment.id,
      };

      if (index >= 0) {
        list[index].data = [...list[index].data, dataAppointment];
        return list;
      } else {
        return [
          ...list,
          {
            date: format(new Date(appointment.fecha_hora), 'dd-MM-yyyy'),
            data: [dataAppointment],
          },
        ];
      }
    },
    []
  );
