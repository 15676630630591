import { Client } from '../../types/clients/clients';
import { UpdateClientDTO } from '../../types/clients/update';
import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export const updateClient = async (
  data: UpdateClientDTO
): Promise<Result<Client, APIValidationError>> =>
  validatedApiCall<Client>(`/api/clientes/${data.id}`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
