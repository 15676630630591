import { Values } from 'ibs-cengine/dist/types/Calculator';
import { CapitalModificationState } from '../../store/capitalModification/capitalModificationSlice';
import { DataCollectionState } from '../../store/dataCollection/dataCollectionSlice';
import { AverageExpenditures } from '../../valoracion/types/averageExpenditure/averageExpenditure';
import { CargasFamiliares } from '../../valoracion/types/familyResponsabilities/familyResponsabilites';
import { Policy } from '../policies/policies';
import { ISO8601DateTimeString, UUID } from '../standard';
import { AseguradoOferta } from './asegurado_oferta';
import { EstadoOferta } from './estado_oferta';
import { OfferAction } from './offer_actions';

export type CodigoEstudioHelvetia = string;

//plain offer used on lists
export interface Offer extends OfferCommon {
  id: UUID;
  human_id: UUID;
  orden: number;
  estado: EstadoOferta;
  created_at: ISO8601DateTimeString;
  poliza: Policy | null;
  allowed_actions: [];
  pdf: string;
  download_pdf: string;
  permissions: {
    canUpdate: boolean;
  };
}
export interface Discount {
  comercial: number;
  tecnico: number;
  colectivo: number;
}
//offer with additional data used to create offer from another one
export interface ExtendedOffer extends OfferCommon {
  inputs: NewOfferInputs;
  outputs: Values;
}

//offer when not yet saved
export interface NewOffer extends OfferCommon {
  precio: PriceDetail;
  inputs?: NewOfferInputs;
}

export interface NewOfferInputs {
  datos: DataCollectionState;
  cargas: CargasFamiliares | null;
  gastos: AverageExpenditures | null;
  capitales: CapitalModificationState;
}

interface OfferCommon {
  proyecto_id: UUID;
  nombre: string;
  asegurado: AseguradoOferta;
  importe: number;
  garantias: Guarantee[];
  descuentos: Discount;
  revalorizacion: CodRevalorizacion;
  periodicidad: CodPeriodicidad;
  codigo_estudio_helvetia?: CodigoEstudioHelvetia;
}

export interface SaveOfferData {
  oferta: NewOffer;
  inputs: NewOfferInputs;
  outputs: Values | null;
  version_id: string | null;
}

export interface OfferWithPolicy extends Offer {
  poliza: Policy;
}

export interface PriceDetail {
  anual: number;
  semestral: number;
  semestralPrimerRecibo: number;
  trimestral: number;
  trimestralPrimerRecibo: number;
  mensual: number;
  mensualPrimerRecibo: number;
  neto?: NetPriceDetail;
}

export interface NetPriceDetail {
  anual: number;
  semestral: number;
  trimestral: number;
  mensual: number;
}

export interface GetOffersDTO {
  data: Offer[];
}

export interface GetOfferDTO {
  oferta: Offer;
}

export interface Guarantee {
  garantia: string;
  categoria: GuaranteeType;
  codigo_producto?: string;
  codigo_garantia: string;
  orden: number;
  importe: number;
  capital: number;
  capital_manual?: number;
  lineas: GuaranteeLine[];
  aplicable?: boolean;
  aplicar?: boolean;
  obligatoria?: boolean;
  editable?: boolean;
  bookPath?: string;
}

export type GuaranteeType = 'fallecimiento' | 'invalidez' | 'otros';
export interface ChangeOfferStateDTO {
  action?: OfferAction;
  offer: Offer;
}

export type GuaranteeLine = {
  concepto: string;
  codigo_linea?: string;
  orden?: number;
  bookPath?: string;
  detalle: MaybeDetalleGuaranteeLine;
} & (GuaranteeLineCapital | GuaranteeLineRenta | GuaranteeLineRentaDiaria);

export type MaybeDetalleGuaranteeLine = { [k: string]: number | null } | null;

export type GuaranteeLineCapital = {
  tipo: 'capital';
  capital: number;
  capital_suma?: number;
  capital_manual?: number;
  orden?: number;
};

export type GuaranteeLineRenta = {
  tipo: 'renta';
  capital: number;
  capital_manual: number;
  renta_manual: number;
  renta: number;
  duracion: number;
  duracion_manual: number;
  orden?: number;
  aplicar?: boolean;
  aplicable?: boolean;
};

export type GuaranteeLineRentaDiaria = {
  tipo: 'renta_diaria';
  renta_diaria: number;
  renta_diaria_manual?: number;
  franquicia: number;
  franquicia_manual: number;
  orden?: number;
};

export type GuaranteeWithBell =
  | 'Invalidez Permanente Absoluta'
  | 'Invalidez Permanente Total'
  | 'Incapacidad Temporal'
  | 'Enfermedad Grave'
  | 'Viudedad'
  | 'Orfandad';

export enum TipoCapitalOfertaEnum {
    'verOferta' = 'ver-oferta',
    'capitalesRecomendados' = 'capitales-recomendados',
    'capitalesModificados' = 'capitales-modificados',
}

export type TipoCapitalOferta = 
    | TipoCapitalOfertaEnum.verOferta 
    | TipoCapitalOfertaEnum.capitalesRecomendados 
    | TipoCapitalOfertaEnum.capitalesModificados;


export enum TipoTablaOfertaEnum {
    'RequestOffer' = 'RequestOffer',
    'ViewOffer' = 'ViewOffer',
    'Analysis' = 'Analysis',
    'CapitalModification' = 'CapitalModification',
}

export type TipoTablaOferta = 
    | TipoTablaOfertaEnum.RequestOffer 
    | TipoTablaOfertaEnum.ViewOffer 
    | TipoTablaOfertaEnum.Analysis 
    | TipoTablaOfertaEnum.CapitalModification;

export enum TipoColumnaExposicionMotivosEnum {
    'first' = 'first',
    'second' = 'second',
}

export type TipoColumnaExposicionMotivos = 
    | TipoColumnaExposicionMotivosEnum.first 
    | TipoColumnaExposicionMotivosEnum.second;

export type CodRevalorizacion =
  | '0'
  | '1'
  | '2'
  | '3';

export enum CodRevalorizacionEnum {
  'SIN_REVALORIZACION' = '0',
  'FIJA_3' = '1',
  'FIJA_5' = '2',
  'FIJA_10' = '3',
}

export enum RevalorizacionEnum {
  'SIN_REVALORIZACION' = 'Sin Revalorización',
  'FIJA_3' = 'Fija 3%',
  'FIJA_5' = 'Fija 5%',
  'FIJA_10' = 'Fija 10%',
}

export type CodPeriodicidad =
  | 'A'
  | 'M'
  | 'T'
  | 'S';

export enum CodPeriodicidadEnum {
  'ANUAL' = 'A',
  'MENSUAL' = 'M',
  'TRIMESTRAL' = 'T',
  'SEMESTRAL' = 'S',
}

export enum PeriodicidadEnum {
  'ANUAL' = 'Anual',
  'MENSUAL' = 'Mensual',
  'TRIMESTRAL' = 'Trimestral',
  'SEMESTRAL' = 'Semestral',
}
  
