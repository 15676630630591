import { RootState } from '../store';

export const selectChangeResponsable = (state: RootState) =>
  state.changeResponsable;

export const selectChangeResponsableErrors = (state: RootState) =>
  selectChangeResponsable(state)?.errors;

export const selectChangeResponsableLoading = (state: RootState) =>
  selectChangeResponsable(state)?.loading;

export const selectChangeResponsableActive = (state: RootState) =>
  selectChangeResponsable(state)?.active;

export const selectChangeResponsableUser = (state: RootState) =>
  selectChangeResponsable(state)?.user;
