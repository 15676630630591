import { RootState } from '../store';

export const isAreaValoracion = (state: RootState) =>
  state.router.location.pathname.startsWith('/valoracion');

export const isAreaExposicionMotivos = (state: RootState) =>
  state.router.location.pathname.startsWith('/valoracion/exposicion-motivos');

export const isAreaSegurpedia = (state: RootState) =>
  state.router.location.pathname.startsWith('/valoracion/segurpedia');

export const isAreaGestion = (state: RootState) =>
  !isAreaValoracion(state) &&
  !isAreaExposicionMotivos(state) &&
  !isAreaSegurpedia(state);
