import { Values } from 'ibs-cengine/dist/types/Calculator';

import { Guarantee } from '../../../types/offers/offers';
import { getValueAsBoolean, getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaIncapacidadTemporal(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.o.it.aplica', values)) {
    return null;
  }
  const garantia: Guarantee = {
    garantia: 'Incapacidad Temporal',
    categoria: 'otros',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.o.it.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.o.it.prima', values),
    capital: getValueAsNumber(
      '.a.' + who + '.e.n.it.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.o.it.cf', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.o.it.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.o.it.aplicar', values),
    editable: true,
    obligatoria: false,
    bookPath: 'incapacidad-temporal-it',
  };
  garantia.lineas.push({
    concepto: 'Renta diaria',
    tipo: 'renta_diaria',
    renta_diaria: getValueAsNumber(
      '.a.' + who + '.e.n.it.renta_recomendada',
      values
    ),
    renta_diaria_manual: getValueAsNumber(
      '.o.' + who + '.w12.o.it.diaria',
      values
    ),
    franquicia: getValueAsNumber(
      '.a.' + who + '.e.n.it.franquicia_recomendada',
      values
    ),
    franquicia_manual: getValueAsNumber(
      '.o.' + who + '.w12.o.it.franquicia',
      values
    ),
    orden: 1,
    detalle: {},
  });

  return garantia;
}
