import createApiModule from '../createApiModule';
import { getProjects } from '../../api/projects/getProjects';
import { GenericState } from '../../types/apiModule';
import { ProjectsDTO, Project } from '../../types/projects/projects';
import { LinksPage, PageInfo } from '../../types/pagination/pagination';

interface ClientProjectsModule extends GenericState<unknown> {
  data: ProjectsDTO;
}

const initialState: ClientProjectsModule = {
  errors: null,
  loading: false,
  data: {
    data: [] as Project[],
    links: {} as LinksPage,
    meta: {} as PageInfo,
  },
};

const clientProjectsModule = createApiModule({
  name: 'clientProjects',
  apiMethod: getProjects,
  initialState,
  parse: (clientProjects) => clientProjects,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar los projectos del cliente',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar los projectos del cliente',
  }),
});

export const {
  start: getClientProjects,
  success: getClientProjectsOk,
  error: getClientProjectsKo,
  reset: resetClientProjects,
} = clientProjectsModule.slice.actions;

export const { sagas } = clientProjectsModule;

export default clientProjectsModule;
