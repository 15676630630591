import { postOffer } from '../../api/offers/postOffer';
import { Offer } from '../../types/offers/offers';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import valoracionSlice from '../valoracion/valoracionSlice';

const newOfferModule = createApiModule({
  name: 'newOffer',
  apiMethod: postOffer,
  parse: (offer: Offer) => offer,
  onSuccess: (offer: Offer) => [
    valoracionSlice.actions.saveOfferSaved(offer.asegurado),
    showSnackBar({
      severity: 'success',
      message: `Se ha guardado la oferta ${offer.nombre}.`,
    }),
  ],
  onError: () => ({
    message: 'No hemos podido crear la oferta.',
  }),
  onValidationError: () => ({ message: 'No hemos podido crear la oferta.' }),
});

export const {
  start: newOffer,
  success: newOfferOk,
  error: newOfferKo,
  reset: resetNewOffer,
} = newOfferModule.slice.actions;

export default newOfferModule;
