import { put, takeLatest } from '@redux-saga/core/effects';
import snackBarSlice from '../snackBar/snackBarSlice';
import averageExpenditureSlice from './averageExpenditureSlice';

function* saveAverageExpenditure(): Generator<any, void, any> {
  yield put(
    snackBarSlice.actions.showSnackBar({
      message: 'El gasto medio se ha modificado correctamente.',
      severity: 'success',
    })
  );
}

const sagas = [
  takeLatest(
    averageExpenditureSlice.actions.saveAverageExpenditureData.type,
    saveAverageExpenditure
  ),
];

export default sagas;
