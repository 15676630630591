import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { formatTime } from '../../../helpers/formatTime';
import {
  hide,
  removeAppointment,
} from '../../../store/schedule/removeAppointmentModule';
import {
  selectRemoveAppointment,
  selectRemoveAppointmentActive,
  selectRemoveAppointmentLoading,
} from '../../../store/schedule/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { Appointment } from '../../../types/appointments/appointments';
import { UUID } from '../../../types/standard';
import Button from '../../Buttons/Button/Button';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';
import { ReactComponent as IconTrash } from '../../../assets/moreMenu/trash.svg';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';

interface DeleteAppointmentProps {
  appointment: Appointment | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  removeAppointment: (id: UUID) => void;
}

const DeleteAppointment: React.FC<DeleteAppointmentProps> = ({
  appointment,
  active,
  loading,
  closeModal,
  removeAppointment,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {appointment && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconTrash className={`${styles.icon} ${styles.isIconTrash}`} />
              Eliminar cita
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer eliminar la siguiente cita?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>
                  <span className={styles.appointmentSection}>Fecha:</span>
                  {` ${format(new Date(appointment.fecha_hora), 'dd-MM-yyyy')}`}
                </p>
                <p>
                  <span className={styles.appointmentSection}>Hora:</span>
                  {` ${formatTime(appointment.fecha_hora)}`}
                </p>
                <p>
                  <span className={styles.appointmentSection}>Cliente:</span>
                  {` ${appointment.nombre} ${appointment.apellido1} ${appointment.apellido2}
                `}
                </p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="positive"
                onClick={() => removeAppointment(appointment.id)}
                data-testid="submit_button"
              >
                Eliminar
              </Button>
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteAppointment = connect(
  (state: RootState) => ({
    appointment: selectRemoveAppointment(state),
    active: selectRemoveAppointmentActive(state),
    loading: selectRemoveAppointmentLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    removeAppointment: (payload: UUID) => dispatch(removeAppointment(payload)),
    closeModal: () => dispatch(hide()),
  })
)(DeleteAppointment);

export default renderWhen(
  ConnectedDeleteAppointment,
  selectRemoveAppointmentActive
);
