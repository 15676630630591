import { put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { updateUser } from '../../api/users/updateUser';
import { User } from '../../types/users/users';
import authSlice from '../auth/authSlice';
import { selectAuthId } from '../auth/selectors';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import usersSlice from './usersSlice';

const editUserModule = createApiModule({
  name: 'editUser',
  apiMethod: updateUser,
  onSuccess: (user) => [
    showSnackBar({
      message: `Datos de ${user.nombre} ${user.apellido1} actualizados.`,
      severity: 'success',
      path: '/usuarios',
    }),
    usersSlice.actions.getUsers(),
  ],
  onError: () => ({
    message: 'Error actualizando los datos del usuario',
  }),
  onValidationError: () => ({
    message: 'Error actualizando los datos del usuario',
  }),
});

export const {
  start: editUser,
  success: editUserOk,
  error: editUserKo,
} = editUserModule.slice.actions;

/**
 * Updates the logged-in user information if we have
 * just edited him.
 *
 * @param action user that has been modified.
 */
function* maybeRefreshProfile(
  action: PayloadAction<User>
): Generator<any, void, any> {
  const loggedInUserId = yield select(selectAuthId);
  if (loggedInUserId === action.payload.id) {
    yield put(authSlice.actions.editProfileOk(action.payload));
  }
}

editUserModule.sagas = [
  ...editUserModule.sagas,
  takeLatest<PayloadAction<User>>(editUserOk.type, maybeRefreshProfile),
];

export default editUserModule;
