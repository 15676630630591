import { getChallenges } from '../../api/challenges/getChallenges';
import createApiModule from '../createApiModule';

const challengesModule = createApiModule({
  name: 'challenges',
  apiMethod: getChallenges,
  parse: (response) => response.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar los retos',
  }),
  onValidationError: () => {},
});

export const {
  start: listChallenges,
  success: listChallengesOk,
  error: listChallengesKo,
} = challengesModule.slice.actions;

export const { sagas } = challengesModule;

export default challengesModule;
