import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { changeRole } from '../../api/users/changeRole';
import { ChangeRoleDTO } from '../../types/actions/actions';
import { Await } from '../../types/api/api';
import snackBarSlice from '../snackBar/snackBarSlice';
import changeRoleSlice from './changeRoleSlice';

function* registerSaga(
  action: PayloadAction<ChangeRoleDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(changeRole, action.payload)) as Await<
      ReturnType<typeof changeRole>
    >;
    switch (result.type) {
      case 'ok':
        yield put(changeRoleSlice.actions.changeRoleOk());
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'El cambio se ha realizado correctamente.',
            severity: 'success',
          })
        );
        yield put(changeRoleSlice.actions.hideChangeRole());
        return;
      case 'validation-error':
        yield put(changeRoleSlice.actions.changeRoleKo(result.value));
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'No se ha podido realizar el cambio.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(changeRoleSlice.actions.changeRoleKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'No se ha podido realizar el cambio.',
        severity: 'error',
      })
    );
  }
}

const sagas = [
  takeLatest<PayloadAction<ChangeRoleDTO>>(
    changeRoleSlice.actions.changeRole.type,
    registerSaga
  ),
];

export default sagas;
