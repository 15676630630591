import { Values } from 'ibs-cengine/dist/types/Calculator';
import { hRound } from 'ibs-cengine/dist/engine/utils';

import { Guarantee } from '../../../types/offers/offers';
import { getValueAsBoolean, getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaEnfermedadGrave(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.o.eg.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Enfermedad Grave',
    categoria: 'otros',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.o.eg.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.o.eg.prima', values),
    capital: hRound(getValueAsNumber('.o.' + who + '.w12.o.eg.cf_rec', values)),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.o.eg.cf_u', values) > 0
        ? getValueAsNumber('.o.' + who + '.w12.o.eg.cf_u', values)
        : getValueAsNumber('.o.' + who + '.w12.o.eg.cf_max', values)
    ),

    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.o.eg.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.o.eg.aplicar', values),
    editable: true,
    obligatoria: false,
  };
}
