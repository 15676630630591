import { Result, validatedApiCall } from '../api';
import { User } from '../../types/users/users';
import { APIValidationError } from '../../types/api/api';

interface UsersDTO {
  data: User[];
}

export const getUsers = async (): Promise<
  Result<UsersDTO, APIValidationError>
> => {
  return validatedApiCall<UsersDTO>(`/api/users`, {
    method: 'GET',
    auth: true,
  });
};
