import { APIValidationError } from '../../types/api/api';
import { ChangeOfferStateDTO, Offer } from '../../types/offers/offers';
import { Result, validatedApiCall } from '../api';

export const changeOfferState = async (
  data: ChangeOfferStateDTO
): Promise<Result<Offer, APIValidationError>> => {
  return validatedApiCall<Offer>(`/api/ofertas/${data.offer.id}/acciones`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  });
};
