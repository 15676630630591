import { selectAreas } from '../areas/selectors';
import { RootState } from '../store';

export const selectChangeArea = (state: RootState) => state.changeArea;

export const selectChangeAreaErrors = (state: RootState) =>
  selectChangeArea(state)?.errors;

export const selectChangeAreaLoading = (state: RootState) =>
  selectChangeArea(state)?.loading;

export const selectChangeAreaActive = (state: RootState) =>
  selectChangeArea(state)?.active;

export const selectChangeAreaUser = (state: RootState) =>
  selectChangeArea(state)?.user;

export const selectAvailableAreas = (state: RootState) => {
  return selectAreas(state).filter(
    (area) => area.id !== selectChangeAreaUser(state)?.area_id
  );
};
