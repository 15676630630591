import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../store/store';

const renderWhen = (
  Component: React.ComponentType,
  selector: (state: RootState) => boolean
) => {
  const isVisible = (state: RootState) => {
    try {
      return selector(state);
    } catch (err) {
      return false;
    }
  };

  const IfVisible: React.FC<{ visible: boolean }> = ({ visible }) =>
    visible ? <Component /> : null;

  return connect((state: RootState) => ({
    visible: isVisible(state),
  }))(IfVisible);
};

export default renderWhen;
