import { Values } from 'ibs-cengine/dist/types/Calculator';
import { AseguradoOferta } from '../../types/offers/asegurado_oferta';
import { TipoCapitalOferta, TipoCapitalOfertaEnum } from '../../types/offers/offers';
import {
  DataBaseReguladoraMedia,
  DataIPA,
  DataIPT,
  DataOrfandad,
  Viudedad,
} from '../../valoracion/types/explanatoryStatements/explanatoryStatements';
import { getValueAsNumber, getValueAsString } from './util';

export function getEstudioViudedad(
  values: Values, 
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) {
  const who = target === 'conyuge' ? 'c' : 'p';
  let duracion, renta_mensual, importe_rentas;
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      duracion = getValueAsNumber(`.a.${who}.e.n.f.cc.v.duracion_recomendada`, values);
      renta_mensual = getValueAsNumber(`.a.${who}.e.n.f.cc.v.renta_recomendada`, values);
      importe_rentas = getValueAsNumber(`.a.${who}.e.n.f.cc.v.capital_recomendado`, values);
    break;
    default:
      duracion = getValueAsNumber(`.o.${who}.w12.f.cc.v.duracion`, values);
      renta_mensual = getValueAsNumber(`.o.${who}.w12.f.cc.v.rm`, values);
      importe_rentas = getValueAsNumber(`.o.${who}.w12.f.cc.v.ci`, values);
  }
  const data: Viudedad = {
    ...getEstudioGastoMensualEfectivo(values, target),
    ...getEstudioBaseCotizacion(values, target),
    porcentaje_ss: getValueAsNumber('.ss.p.pct_v', values),
    porcentaje_cubierto_ss: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.pct_ss`,
      values
    ),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.pct_br`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.br`,
      values
    ),
    prestacion_ss: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.prestacion_ss`,
      values
    ),
    necesidad_gme: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.necesidad_gme`,
      values
    ),
    diferencia_mensual: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.diff_gme`,
      values
    ),
    complemento_orfandad_duracion: getValueAsNumber(
      `.a.${who}.e.n.f.cc.v.a_n_c_o`,
      values
    ),
    complemento_orfandad_porcentaje: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.pct_nc`,
      values
    ),
    complemento_orfandad_mensual: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.nec_nc_gme`,
      values
    ),
    complemento_orfandad_importe: getValueAsNumber(
      `.a.${who}.e.n.f.cc.cfi.complemento_orfandad`,
      values
    ),
    complemento_viudedad_duracion: getValueAsNumber(`.cn.f.v.a_cf`, values),
    complemento_viudedad_importe: getValueAsNumber(
      `.a.${who}.e.n.f.cc.cfi.complemento_viudedad`,
      values
    ),
    complemento_viudedad_mensual: getValueAsNumber(
      `.a.${who}.e.n.f.cc.cfi.complemento_viudedad_mensual`,
      values
    ),
    renta: renta_mensual,
    duracion_rentas: duracion,
    importe_rentas: importe_rentas,
    total: -1,
    numero_hijos: getValueAsNumber(`.a.n_hijos`, values),
    hay_complemento_viudedad: false,
    hay_complemento_orfandad: false,
  };
  data.hay_complemento_orfandad = data.complemento_orfandad_importe > 0;
  data.hay_complemento_viudedad = data.complemento_viudedad_importe > 0;
  if (data.hay_complemento_viudedad) {
    data.total = data.importe_rentas + data.complemento_viudedad_importe;
  } else {
    data.total = data.importe_rentas + data.complemento_orfandad_importe;
  }

  return data;
}

export function getEstudioEnfermedadGrave(
  values: Values,
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) {
  const who = target === 'conyuge' ? 'c' : 'p';
  let prestacion_total;
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      prestacion_total = getValueAsNumber(`.o.${who}.w12.o.eg.cf_rec`, values);
    break;
    default:
      prestacion_total = getValueAsNumber(`.o.${who}.w12.o.eg.cf`, values);
  }
  return {
    prestacion_total: prestacion_total,
  };
}

export function getEstudioIncapacidadTemporal(
  values: Values,
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
) {
  const who = target === 'conyuge' ? 'c' : 'p';
  let franquicia_dias, indemnizacion_maxima, renta_diaria, renta_mensual;
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      franquicia_dias = getValueAsNumber(`.a.${who}.e.n.it.franquicia_recomendada`, values);
      indemnizacion_maxima = getValueAsNumber(`.a.${who}.e.n.it.capital_recomendado`, values);
      renta_diaria = getValueAsNumber(`.a.${who}.e.n.it.diferencia_gme_diaria`, values);
      renta_mensual = getValueAsNumber(`.a.${who}.e.n.it.diferencia_gme`, values);
    break;
    default:
      franquicia_dias = getValueAsNumber(`.o.${who}.w12.o.it.franquicia`, values);
      indemnizacion_maxima = getValueAsNumber(`.o.${who}.w12.o.it.cf`, values);
      renta_diaria = getValueAsNumber(`.o.${who}.w12.o.it.diaria`, values);
      renta_mensual = getValueAsNumber(`.o.${who}.w12.o.it.resto_meses`, values);
  }
  return {
    ...getEstudioBaseCotizacion(values, target),
    necesidad_gme: getValueAsNumber(`.a.${who}.i.gmes_cubierto`, values),
    base_reguladora_media_importe: getValueAsNumber(
      `.a.${who}.e.ss.br.it.br`,
      values
    ),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `.a.${who}.e.ss.br.it.pct_br_bc`,
      values
    ),
    necesidad_porcentaje: 100,
    prestacion_ss: getValueAsNumber(`.a.${who}.e.n.it.prestacion_ss`, values),
    diferencia_mensual: getValueAsNumber(
      `.a.${who}.e.n.it.diferencia_gme`,
      values
    ),
    diferencia_diaria: getValueAsNumber(
      `.a.${who}.e.n.it.diferencia_gme_diaria`,
      values
    ),
    necesidad_diaria: getValueAsNumber(
      `.a.${who}.e.n.it.necesidad_diaria`,
      values
    ),
    prestacion_diaria_ss: getValueAsNumber(
      `.a.${who}.e.n.it.prestacion_diaria_ss`,
      values
    ),
    franquicia_dias: franquicia_dias,
    indemnizacion_maxima: indemnizacion_maxima,
    renta_diaria: renta_diaria,
    renta_mensual: renta_mensual
  };
}

export function getEstudioIPT(
  values: Values,
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
): DataIPT {
  const who = target === 'conyuge' ? 'c' : 'p';
  let total;
  let duracion = getValueAsNumber(`.a.${who}.e.n.i.pt.duracion_recomendada`, values);
  let renta_mensual = getValueAsNumber(`.a.${who}.e.n.i.pt.renta_recomendada`, values);
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      total = getValueAsNumber(`.a.${who}.e.n.i.pt.capital_recomendado`, values);
    break;
    default:
      total = getValueAsNumber(`.o.${who}.w12.i.pt.cf`, values);
      if (duracion > 0) {
        renta_mensual = total / duracion / 12;
      }
  }
  return {
    ...getEstudioGastoMensualEfectivo(values, target),
    ...getEstudioBaseCotizacion(values, target),
    prestacion_ss_porcentaje: getValueAsNumber(`.ss.p.pct_ipt`, values),
    porcentaje_prestacion: getValueAsNumber(
      `.a.${who}.e.n.i.pt.pct_ss`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(
      `.a.${who}.e.ss.br.i.br_i`,
      values
    ),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `.a.${who}.e.ss.br.i.pct_br_bc`,
      values
    ),
    base_reguladora_final_importe: getValueAsNumber(
      `.a.${who}.e.ss.br.i.br`,
      values
    ),
    base_reguladora_final_porcentaje: getValueAsNumber(
      `.a.${who}.e.ss.br.i.cc.req.pct_base`,
      values
    ),
    años_cotizados: Math.trunc(
      getValueAsNumber(`.a.${who}.e.ss.c.anos_cotizados_alta`, values)
    ),
    fecha_alta_ss: getValueAsString(`.a.${who}.d.alta_ss`, values),
    necesidad_porcentaje: 100,
    prestacion_ss_importe: getValueAsNumber(
      `.a.${who}.e.n.i.pt.prestacion_ss`,
      values
    ),
    necesidad_gme: getValueAsNumber(`.a.${who}.i.gmes_cubierto`, values),
    diferencia_mensual: getValueAsNumber(
      `.a.${who}.e.n.i.pt.renta_recomendada`,
      values
    ),
    total: total,
    duracion: duracion,
    renta_mensual: renta_mensual,
  };
}

export function getEstudioIPA(
  values: Values,
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
): DataIPA {
  const who = target === 'conyuge' ? 'c' : 'p';
  let duracion, renta_mensual, total;
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      duracion = getValueAsNumber(`.o.${who}.w12.i.pa.rm.duracion_recomendada`, values);
      renta_mensual = getValueAsNumber(`.o.${who}.w12.i.pa.rm.recomendada`, values);
      total = getValueAsNumber(`.o.${who}.w12.i.pa.rm.ci_recomendado`, values);
    break;
    default:
      duracion = getValueAsNumber(`.o.${who}.w12.i.pa.rm.anos`, values);
      renta_mensual = getValueAsNumber(`.o.${who}.w12.i.pa.rm.mensual`, values);
      total = getValueAsNumber(`.o.${who}.w12.i.pa.ci`, values);
  }
  return {
    ...getEstudioGastoMensualEfectivo(values, target),
    ...getEstudioBaseCotizacion(values, target),
    edad: getValueAsNumber(`.a.${who}.d.edad`, values),
    edad_actuarial: getValueAsNumber(`.a.${who}.d.edad_actuarial`, values),
    prestacion_ss_porcentaje: getValueAsNumber(`.ss.p.pct_ipa`, values),
    porcentaje_prestacion: getValueAsNumber(
      `.a.${who}.e.n.i.pt.pct_ss`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(
      `.a.${who}.e.ss.br.i.br_i`,
      values
    ),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `.a.${who}.e.ss.br.i.pct_br_bc`,
      values
    ),
    base_reguladora_final_importe: getValueAsNumber(
      `.a.${who}.e.ss.br.i.br`,
      values
    ),
    base_reguladora_final_porcentaje: getValueAsNumber(
      `.a.${who}.e.ss.br.i.cc.req.pct_base`,
      values
    ),
    años_cotizados: Math.trunc(
      getValueAsNumber(`.a.${who}.e.ss.c.anos_cotizados_alta`, values)
    ),
    fecha_alta_ss: getValueAsString(`.a.${who}.d.alta_ss`, values),
    necesidad_porcentaje: 100,
    prestacion_ss_importe: getValueAsNumber(
      `.a.${who}.e.n.i.pa.prestacion_ss`,
      values
    ),
    necesidad_gme: getValueAsNumber(`.a.${who}.i.gmes_cubierto`, values),
    diferencia_mensual: getValueAsNumber(
      `.a.${who}.e.n.i.pa.renta_recomendada`,
      values
    ),
    duracion: duracion,
    renta_mensual: renta_mensual,
    total: total,
  };
}

export function getEstudioOrfandad(
  values: Values,
  target?: AseguradoOferta,
  tipoCapitalOferta?: TipoCapitalOferta
): DataOrfandad {
  const who = target === 'conyuge' ? 'c' : 'p';

  const ages = [];
  const numChildren = getValueAsNumber(`.a.n_hijos`, values);
  for (let i = 0; i < numChildren; i++) {
    ages.push(getValueAsNumber(`.a.h`, values, `.${i}.edad`));
  }
  const hijos_duracion = [];
  for (let i = 0; i < numChildren; i++) {
    hijos_duracion.push({
      duracion_hasta_21: getValueAsNumber(
        `.a.${who}.e.n.f.cc.o.hijo`,
        values,
        `.${i}.duracion_hasta_21`
      ),
      duracion_hasta_25: getValueAsNumber(
        `.a.${who}.e.n.f.cc.o.hijo`,
        values,
        `.${i}.duracion_hasta_25`
      ),
      necesidad_hasta_21: getValueAsNumber(
        `.a.${who}.e.n.f.cc.o.hijo`,
        values,
        `.${i}.necesidad_hasta_21`
      ),
    });
  }
  let duracion, renta_mensual_por_hijo, renta_mensual, total;
  switch (tipoCapitalOferta) {
    case TipoCapitalOfertaEnum.capitalesRecomendados:
      duracion = getValueAsNumber(`.a.${who}.e.n.f.cc.o.duracion_recomendada`, values);
      renta_mensual_por_hijo = getValueAsNumber(`.a.${who}.e.n.f.cc.o.renta_mensual_total_h`, values);
      renta_mensual = getValueAsNumber(`.a.${who}.e.n.f.cc.o.renta_recomendada`, values);
      total = getValueAsNumber(`.a.${who}.e.n.f.cc.o.necesidad`, values);
    break;
    default:
      duracion = getValueAsNumber(`.o.${who}.w12.f.cc.o.anos`, values);
      renta_mensual = getValueAsNumber(`.o.${who}.w12.f.cc.o.rm`, values);
      renta_mensual_por_hijo = numChildren > 0 ? renta_mensual / numChildren : 0;
      total = getValueAsNumber(`.o.${who}.w12.f.cc.o.ci`, values);
  }

  return {
    ...getEstudioGastoMensualEfectivo(values, target),
    ...getEstudioBaseCotizacion(values, target),
    n_hijos: getValueAsNumber(`.a.n_hijos`, values),
    edad_hijos: ages,
    edad_media_hijos: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.edad_media`,
      values
    ),
    renta_mensual_hijo_hasta_21: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.dif_gme_hasta_21_h`,
      values
    ),
    hijos_duracion: hijos_duracion,
    edad_maxima: getValueAsNumber(`.ss.p.max_edad_o`, values),
    edad_recomendada: getValueAsNumber(`.cn.f.o.edad`, values),
    porcentaje_maximo_ss_por_hijo: getValueAsNumber(`.ss.p.pct_o_h`, values),
    porcentaje_maximo_ss_total: getValueAsNumber(`.ss.p.pct_o`, values),
    porcentaje_por_hijo: getValueAsNumber(`.a.${who}.e.n.f.cc.o.pct_h`, values),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.pct_br`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.br`,
      values
    ),
    prestacion_ss_edad_maxima: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.ss_hasta_21_h`,
      values
    ),
    prestacion_ss_edad_recomendada: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.ss_desde_21`,
      values
    ),
    necesidad_gme_edad_maxima: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.nec_gme_h`,
      values
    ),
    necesidad_gme_edad_recomedada: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.nec_gme_h`,
      values
    ),
    diferencia_mensual_edad_maxima: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.dif_gme_hasta_21_h`,
      values
    ),
    diferencia_mensual_edad_recomendada: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.dif_gme_desde_21_h`,
      values
    ),
    complemento_necesario_edad_maxima: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.necesidad_hasta_21`,
      values
    ),
    complemento_necesario_edad_recomendada: getValueAsNumber(
      `.a.${who}.e.n.f.cc.o.necesidad_hasta_25`,
      values
    ),
    complemento_necesario_total: total,
    complemento_necesario_redondeado: renta_mensual * duracion * 12,
    renta_mensual_total: renta_mensual,
    renta_mensual_por_hijo: renta_mensual_por_hijo,
    duracion_cobertura: duracion,
  };
}

export function getEstudioGastoMensualEfectivo(
  values: Values,
  target?: AseguradoOferta
) {
  const who = target === 'conyuge' ? 'c' : 'p';
  return {
    gastos_mensuales_efectivos: getValueAsNumber(
      `.a.${who}.i.gmes_cubierto`,
      values
    ),
  };
}

export function getEstudioBaseCotizacion(
  values: Values,
  target?: AseguradoOferta
) {
  const who = target === 'conyuge' ? 'c' : 'p';

  return {
    base_cotizacion: getValueAsNumber(`.a.${who}.e.bc.bc`, values),
  };
}

function getPorcentajeBRM(values: Values, target?: AseguradoOferta) {
  const who = target === 'conyuge' ? 'c' : 'p';

  const prefix = `.a.${who}.e.ss.br.f`;

  return {
    base_reguladora_fallecimiento: getValueAsNumber(`${prefix}.br`, values),
    porcentaje_brm_fallecimiento: getValueAsNumber(`${prefix}.pct_bc`, values),
    porcentaje_brm_invalidez: getValueAsNumber(
      `.a.${who}.e.ss.br.i.pct_br_bc`,
      values
    ),
    porcentaje_final_br_invalidez: getValueAsNumber(
      `.a.${who}.e.ss.br.i.cc.req.pct_base`,
      values
    ),
  };
}

export function getEstudioBaseReguladoraMediaIPT(
  values: Values,
  target?: AseguradoOferta
): DataBaseReguladoraMedia {
  const who = target === 'conyuge' ? 'c' : 'p';
  const prefix = `.a.${who}.e.ss.br.i`;

  return {
    base_reguladora_media_importe: getValueAsNumber(`${prefix}.br_i`, values),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `${prefix}.pct_br_bc`,
      values
    ),
    ...getEstudioBaseCotizacion(values, target),
    ...getPorcentajeBRM(values, target),
  };
}

export function getEstudioBaseReguladoraMediaIPA(
  values: Values,
  target?: AseguradoOferta
): DataBaseReguladoraMedia {
  const who = target === 'conyuge' ? 'c' : 'p';
  const prefix = `.a.${who}.e.ss.br.i`;

  return {
    base_reguladora_media_importe: getValueAsNumber(`${prefix}.br_i`, values),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `${prefix}.pct_br_bc`,
      values
    ),
    ...getEstudioBaseCotizacion(values, target),
    ...getPorcentajeBRM(values, target),
  };
}

export function getEstudioBaseReguladoraMediaOrfandad(
  values: Values,
  target?: AseguradoOferta
): DataBaseReguladoraMedia {
  const who = target === 'conyuge' ? 'c' : 'p';
  const prefix = `.a.${who}.e.n.f.cc.o`;

  return {
    base_reguladora_media_porcentaje: getValueAsNumber(
      `${prefix}.pct_br`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(`${prefix}.br`, values),
    ...getEstudioBaseCotizacion(values, target),
    ...getPorcentajeBRM(values, target),
  };
}

export function getEstudioBaseReguladoraMediaViudedad(
  values: Values,
  target?: AseguradoOferta
): DataBaseReguladoraMedia {
  const who = target === 'conyuge' ? 'c' : 'p';
  const prefix = `.a.${who}.e.n.f.cc.v`;

  return {
    base_reguladora_media_porcentaje: getValueAsNumber(
      `${prefix}.pct_br`,
      values
    ),
    base_reguladora_media_importe: getValueAsNumber(`${prefix}.br`, values),
    ...getEstudioBaseCotizacion(values, target),
    ...getPorcentajeBRM(values, target),
  };
}

export function getEstudioBaseReguladoraMediaIT(
  values: Values,
  target?: AseguradoOferta
): DataBaseReguladoraMedia {
  const who = target === 'conyuge' ? 'c' : 'p';
  const prefix = `.a.${who}.e.ss.br.it`;
  return {
    base_reguladora_media_importe: getValueAsNumber(`${prefix}.br`, values),
    base_reguladora_media_porcentaje: getValueAsNumber(
      `${prefix}.pct_br_bc`,
      values
    ),
    ...getEstudioBaseCotizacion(values, target),
    ...getPorcentajeBRM(values, target),
  };
}
