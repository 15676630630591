import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import alertsModule from './alerts/alertsModule';
import newAlertsModule from './alerts/newAlertsModule';
import readAlertsModule from './alerts/readAlertsModule';
import areasSlice from './areas/areasSlice';
import authSlice from './auth/authSlice';
import restrictionsReducer from './auth/restrictions/restrictionsReducer';
import averageExpenditureSlice from './averageExpenditure/averageExpenditureSlice';
import capitalModificationSlice from './capitalModification/capitalModificationSlice';
import challengesModule from './challenges/challengesModule';
import changeAreaSlice from './changeArea/changeAreaSlice';
import changeClientStateSlice from './changeClientState/changeClientStateSlice';
import changeResponsableSlice from './changeResponsable/changeResponsableSlice';
import changeRoleSlice from './changeRole/changeRoleSlice';
import clientModule from './client/clientModule';
import clientProjectsModule from './client/clientProjectsModule';
import clientSearchModule from './client/clientSearchModule';
import commentsModule from './client/commentsModule';
import editClientModule from './client/editClientModule';
import newCommentModule from './client/newCommentModule';
import updateClientModule from './client/updateClientModule';
import clientAppointmentsModule from './clientAppointments/clientAppointmentsModule';
import clientsSlice from './clients/clientsSlice';
import dataCollectionSlice from './dataCollection/dataCollectionSlice';
import dictionaryModule from './dictionary/dictionaryModule';
import familyResponsabilitiesSlice from './familyResponsabilites/familyResponsabilitiesSlice';
import changeOfferStateModule from './offer/changeOfferStateModule';
import newOfferModule from './offer/newOfferModule';
import sendPdfModule from './offer/sendPdfModule';
import getOffersModule from './offers/getOffersModule';
import poblacionesSlice from './poblacion/poblacionesSlice';
import updatePolicyModule from './policy/updatePolicyModule';
import changeProjectStateModule from './project/changeProjectStateModule';
import newProjectModule from './project/newProjectModule';
import projectModule from './project/projectModule';
import projectsModule from './projects/projectsModule';
import registerClientSlice from './registerClient/registerClientSlice';
import requestOfferModule from './requestOffer/requestOfferModule';
import rootSaga from './root-saga';
import newAppointmentModule from './schedule/newAppointmentModule';
import removeAppointmentModule from './schedule/removeAppointmentModule';
import scheduleModule from './schedule/scheduleModule';
import takenAppointmentsModule from './schedule/takenAppointmentsModule';
import upcomingAppointmentsModule from './schedule/upcomingAppointmentsModule';
import schemasSlice from './schemas/schemasSlice';
import snackBarSlice from './snackBar/snackBarSlice';
import getStatisticsModule from './statistics/getStatisticsModule';
import editUserModule from './users/editUserModule';
import registerUserModule from './users/registerUserModule';
import resetUserPasswordModule from './users/resetUserPasswordModule';
import usersSlice from './users/usersSlice';
import valoracionSlice from './valoracion/valoracionSlice';

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'authRestrictions'],
};

const appReducer = combineReducers({
  router: connectRouter(history),
  snackBar: snackBarSlice.reducer,

  auth: authSlice.reducer,
  authRestrictions: restrictionsReducer,

  areas: areasSlice.reducer,
  poblaciones: poblacionesSlice.reducer,

  users: usersSlice.reducer,
  registerUser: registerUserModule.slice.reducer,
  editUser: editUserModule.slice.reducer,
  resetUserPassword: resetUserPasswordModule.slice.reducer,
  schedule: scheduleModule.slice.reducer,

  clients: clientsSlice.reducer,
  client: clientModule.slice.reducer,
  clientProjects: clientProjectsModule.slice.reducer,
  registerClient: registerClientSlice.reducer,
  editClient: editClientModule.slice.reducer,
  updateClient: updateClientModule.slice.reducer,
  clientSearch: clientSearchModule.slice.reducer,
  changeResponsable: changeResponsableSlice.reducer,
  changeRole: changeRoleSlice.reducer,
  changeArea: changeAreaSlice.reducer,

  upcomingAppointments: upcomingAppointmentsModule.slice.reducer,
  newAppointment: newAppointmentModule.slice.reducer,
  removeAppointment: removeAppointmentModule.slice.reducer,
  takenAppointments: takenAppointmentsModule.slice.reducer,
  clientAppointments: clientAppointmentsModule.slice.reducer,
  clientComments: commentsModule.slice.reducer,
  newClientComment: newCommentModule.slice.reducer,
  changeClientState: changeClientStateSlice.reducer,

  projects: projectsModule.slice.reducer,
  project: projectModule.slice.reducer,
  changeProjectState: changeProjectStateModule.slice.reducer,
  newProject: newProjectModule.slice.reducer,

  newOffer: newOfferModule.slice.reducer,
  offers: getOffersModule.slice.reducer,
  changeOfferState: changeOfferStateModule.slice.reducer,
  updateOfferPolicy: updatePolicyModule.slice.reducer,

  valoracion: valoracionSlice.reducer,
  dataCollection: dataCollectionSlice.reducer,
  familyResponsabilities: familyResponsabilitiesSlice.reducer,
  averageExpenditure: averageExpenditureSlice.reducer,
  capitalModification: capitalModificationSlice.reducer,

  schemas: schemasSlice.reducer,

  statistics: getStatisticsModule.slice.reducer,
  challenges: challengesModule.slice.reducer,
  dictionary: dictionaryModule.slice.reducer,
  alerts: alertsModule.slice.reducer,
  readAlerts: readAlertsModule.slice.reducer,
  newAlerts: newAlertsModule.slice.reducer,
  requestOffer: requestOfferModule.slice.reducer,
  sendPdf: sendPdfModule.slice.reducer,
});

/**
 * Reducer that wraps the regular app reducer to guarantee
 * than all of the app's state gets reset when the user logs
 * out of the application.
 *
 * @param state application state
 * @param action action that has been perfomed
 * @return next state
 */
const rootReducer: typeof appReducer = (state, action): RootState => {
  if (action.type === authSlice.actions.logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const pReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: pReducer,
  middleware: [
    // getDefaultMiddleware needs to be called with the state type
    // ...getDefaultMiddleware<RootState>(),
    sagaMiddleware,
    routerMiddleware(history),

    // correctly typed middlewares can just be used
    // additionalMiddleware,
    // you can also manually type middlewares manually
    // untypedMiddleware as Middleware<
    //   (action: Action<'specialAction'>) => number,
    //   RootState
    // >
    routerMiddleware(history),
  ] as const, // prevent this from becoming just `Array<Middleware>`
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export const persistor = persistStore(store);

export default store;
