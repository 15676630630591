export type OfferAction =
  | 'crear'
  | 'aceptar'
  | 'solicitar'
  | 'descartarOferta'
  | 'aceptarPoliza'
  | 'firmarPoliza'
  | 'noFirmarPoliza'
  | 'pagarPoliza'
  | 'noPagarPoliza'
  | 'rechazarPoliza'
  | 'anularPoliza';

export enum OfferActionEnum {
  'crear' = 'Crear oferta',
  'aceptar' = 'Aceptar oferta',
  'solicitar' = 'Solicitar a la Cia.',
  'descartarOferta' = 'Descartar oferta',
  'aceptarPoliza' = 'Póliza aceptada',
  'firmarPoliza' = 'Póliza firmada',
  'noFirmarPoliza' = 'Póliza no firmada',
  'pagarPoliza' = 'Póliza pagada',
  'noPagarPoliza' = 'Póliza no pagada',
  'rechazarPoliza' = 'Póliza rechazada',
  'anularPoliza' = 'Póliza anulada',
}
