import React from 'react';
import styles from './th.module.scss';
import { ColumnVariant } from '../types';

interface ThProps {
  children?: React.ReactNode;
  hasEllipsis?: boolean;
  variant?: ColumnVariant;
  colSpan?: number;
}

const tableStyle = (variant: ColumnVariant): string => {
  switch (variant) {
    case 'dark':
      return styles.isDark;
    case 'big':
      return styles.isBigger;
    case 'green':
      return styles.isGreen;
    case 'greenEW2':
      return `${styles.isGreen} ${styles.isEqualWidth2}`;
    case 'greenEW3':
      return `${styles.isGreen} ${styles.isEqualWidth3}`;
    case 'greenEW4':
      return `${styles.isGreen} ${styles.isEqualWidth4}`;
    case 'subtitle':
      return styles.isSubtitle;
    case 'default':
      return '';
  }
};

const Th: React.FC<ThProps> = ({
  children,
  hasEllipsis,
  variant = 'default',
  colSpan,
}) => (
  <th
    className={`${styles.th} ${
      hasEllipsis ? styles.hasEllipsis : ''
    }  ${tableStyle(variant)}`}
    colSpan={colSpan}
  >
    {children}
  </th>
);

export default Th;
