import {
  SchemaDefinitionDTO,
  SchemaVersionDTO,
  TableDTO,
  TableReferenceDTO,
} from '../../types/schemas/schemas';
import { UUID } from '../../types/standard';
import { apiCall } from '../api';

export const getDefinitionById = async (
  id: UUID
): Promise<SchemaDefinitionDTO> => {
  return apiCall<SchemaDefinitionDTO>(`/api/schemas/definitions/${id}`, {
    method: 'GET',
    auth: true,
  });
};

export const getLatest = async (): Promise<SchemaVersionDTO> => {
  return apiCall<SchemaVersionDTO>('/api/schemas/versions/latest', {
    method: 'GET',
    auth: true,
  });
};

export const getTableVersionById = async (
  tableReference: TableReferenceDTO
): Promise<TableDTO> => {
  return apiCall<TableDTO>(
    `/api/schemas/tables/${tableReference.table_id}/${tableReference.table_version_id}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
