import React, { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';
import isFieldError from '../../../helpers/isFieldError';
import styles from './inputField.module.scss';

export type Variant = 'standard' | 'login' | 'noErrorColor';

export interface InputFieldProps {
  input: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  label: string | ReactElement;
  error?: string | FieldError;
  variant?: Variant;
}

const variantPicker = (variant: Variant) => {
  switch (variant) {
    case 'login':
      return styles.hasLoginError;
    case 'noErrorColor':
      return '';
    default:
      return styles.hasError;
  }
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  error,
  input,
  variant = 'standard',
}) => {
  const id = input.id || input.name;
  const errorMessage: string | JSX.Element = isFieldError(error)
    ? error.message || ''
    : error || '';

  return (
    <div className={styles.row}>
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
      <input
        data-testid={id}
        // login has specific styles for errors
        className={`${styles.inputText} ${error ? variantPicker(variant) : ''}`}
        id={id}
        {...input}
      />
      {error && <p className={styles.errorText}>{errorMessage}</p>}
    </div>
  );
};
export default InputField;
