import React from 'react';
import ChangeResponsable from './ChangeResponsable/ChangeResponsable';
import ChangeRole from './ChangeRole/ChangeRole';
import ChangeProjectState from './ChangeProjectState/ChangeProjectState';
import ChangeOfferState from './ChangeOfferState/ChangeOfferState';
import NewProject from './NewProject/NewProject';
import DeleteAppointment from './DeleteAppointment/DeleteAppointment';
import ChangeArea from './ChangeArea/ChangeArea';
import ChangeClientState from './ChangeClientState/ChangeClientState';
import SendOffer from './SendOffer/SendOffer';

const PopupActions = () => {
  return (
    <>
      <ChangeResponsable />
      <ChangeRole />
      <ChangeProjectState />
      <NewProject />
      <DeleteAppointment />
      <ChangeOfferState />
      <ChangeArea />
      <ChangeClientState />
      <SendOffer />
    </>
  );
};

export default PopupActions;
