import { getClientComments } from '../../api/clients/getComments';
import createApiModule from '../createApiModule';
import { CommentDataItem, CommentsDTO } from '../../types/clients/comments';

const commentsModule = createApiModule({
  name: 'getComments',
  initialData: [] as CommentDataItem[],
  apiMethod: getClientComments,
  parse: (response: CommentsDTO) => response.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'Error cargando comentarios',
  }),
});

export const {
  start: getComments,
  success: getCommentsOk,
  error: getCommentsKo,
  reset: resetComments,
} = commentsModule.slice.actions;

export default commentsModule;
