import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import GestionRoutes from '../../routes/GestionRoutes';
import { selectPasswordRestrictionRestricted } from '../../store/auth/restrictions/password/selectors';
import { RootState } from '../../store/store';
import { Role, RoleEnum } from '../../types/auth/roles.d';
import ExposicionRoutes from '../../valoracion/routes/ExposicionRoutes';
import ValoracionRoutes from '../../valoracion/routes/ValoracionRoutes';
import SegurpediaRoutes from '../../valoracion/routes/SegurpediaRoutes';

import PrivateRoute from '../Router/PrivateRoute/PrivateRoute';
import ChangePassword from './ChangePassword/ChangePassword';

export const allRoles = Object.keys(RoleEnum) as Role[];

interface Props {
  password: boolean;
}

const LoginRestrictions: React.FC<Props> = ({ password }) => {
  if (password) {
    return (
      <PrivateRoute
        allowedRoles={allRoles}
        path="/"
        component={ChangePassword}
      />
    );
  }

  return (
    <Switch>
      <PrivateRoute
        allowedRoles={allRoles}
        path="/valoracion/segurpedia"
        component={SegurpediaRoutes}
      />
      <PrivateRoute
        allowedRoles={allRoles}
        path="/valoracion/exposicion-motivos"
        component={ExposicionRoutes}
      />
      <PrivateRoute
        allowedRoles={allRoles}
        path="/valoracion"
        component={ValoracionRoutes}
      />

      <PrivateRoute
        allowedRoles={allRoles}
        path="/"
        component={GestionRoutes}
      />
    </Switch>
  );
};

export default connect((state: RootState) => ({
  password: selectPasswordRestrictionRestricted(state),
}))(LoginRestrictions);
