import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AseguradoOferta } from '../../types/offers/asegurado_oferta';
import { ExtendedOffer } from '../../types/offers/offers';
import { Project } from '../../types/projects/projects';

export type ValoracionStartData =
  | {
      type: 'project';
      project: Project;
    }
  | {
      type: 'offer';
      offer_id: string;
      from_offer?: ExtendedOffer;
      project: Project;
    };

type ValoracionStateNotReady = {
  state: 'initial';
  ready: false;
};
type ValoracionStateLoading = {
  state: 'loading';
  origin: ValoracionStartData;
  ready: false;
};
type ValoracionStateReady = {
  state: 'ready';
  ready: true;
  origin: ValoracionStartData;
  asegurado_principal_offer_saved: boolean;
  conyuge_offer_saved: boolean;
};

export type ValoracionState =
  | ValoracionStateNotReady
  | ValoracionStateLoading
  | ValoracionStateReady;

export const initialState: ValoracionState = {
  state: 'initial',
  ready: false,
};

const valoracionSlice = createSlice({
  name: 'valoracion',
  initialState: initialState as ValoracionState,
  reducers: {
    resetValoracion(status) {
      return { ...initialState };
    },
    loadValoracion(status, action: PayloadAction<ValoracionStartData>) {
      return {
        state: 'loading',
        origin: action.payload,
        ready: false,
      };
    },
    loadExtendedOffer(status, action: PayloadAction<ExtendedOffer>) {
      if (status.state === 'loading' && status.origin.type === 'offer') {
        return {
          ...status,
          origin: {
            ...status.origin,
            from_offer: action.payload,
          },
        };
      }
      return status;
    },
    loadValoracionOk(status) {
      if (status.state === 'loading') {
        return {
          state: 'ready',
          ready: true,
          origin: status.origin,
          asegurado_principal_offer_saved: false,
          conyuge_offer_saved: false,
        };
      }
      return status;
    },
    loadValoracionKo(status) {
      return initialState;
    },
    saveOfferSaved(status, action: PayloadAction<AseguradoOferta>) {
      if (action.payload === 'principal') {
        return { ...status, asegurado_principal_offer_saved: true };
      }
      if (action.payload === 'conyuge') {
        return { ...status, conyuge_offer_saved: true };
      }
      return status;
    },
  },
});

export const {
  resetValoracion,
  loadValoracion,
  loadExtendedOffer,
  loadValoracionOk,
  loadValoracionKo,
  saveOfferSaved,
} = valoracionSlice.actions;

export default valoracionSlice;
