import { selectAuthId, selectHasSomeRole } from '../auth/selectors';
import { RootState } from '../store';
import { selectUserById } from './selectors';

export const canEditUserGate = (state: RootState, params: any) => {
  if (!params || !params.id) return false;
  if (selectHasSomeRole(state, ['admin_ibs', 'admin_int'])) return true;

  const user = selectUserById(state, params.id);
  return user ? user.responsable_id === selectAuthId(state) : false;
};
