import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { put, takeLatest } from 'redux-saga/effects';
import { createProject } from '../../api/projects/createProject';
import { GenericState } from '../../types/apiModule';
import { Project } from '../../types/projects/projects';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import valoracionSlice from '../valoracion/valoracionSlice';

export interface NewProjectState extends GenericState<unknown> {
  visible: boolean;
  data: Project;
}
const initialState: NewProjectState = {
  errors: null,
  loading: false,
  visible: false,
  data: {} as Project,
};

const newProjectModule = createApiModule({
  name: 'newProject',
  apiMethod: createProject,
  initialState,
  parse: (project: Project) => project,
  onSuccess: (project: Project) => [
    valoracionSlice.actions.loadValoracion({
      type: 'project',
      project: project,
    }),
    push('/valoracion/toma-datos/'),
    showSnackBar({
      severity: 'success',
      message: 'El proyecto se ha creado correctamente.',
    }),
  ],
  onError: () => ({
    message: 'No hemos podido crear el proyecto.',
  }),
  onValidationError: () => ({
    message: 'No hemos podido crear el proyecto.',
  }),
  reducers: {
    show(state) {
      state.visible = true;
    },
    hide(state) {
      state.visible = false;
    },
  },
});

export const {
  start: newProject,
  success: newProjectOk,
  error: newProjectKo,
  hide,
  show,
  reset,
} = newProjectModule.slice.actions;

function* hideSaga() {
  yield put(hide());
}
newProjectModule.sagas = [
  takeLatest<PayloadAction<unknown>>(newProjectOk.type, hideSaga),
  ...newProjectModule.sagas,
];

export default newProjectModule;
