import { call, delay, put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { getClientById } from '../../api/clients/getClientById';
import { getOfferById } from '../../api/offers/getOfferById';
import { Await } from '../../types/api/api';
import { Client } from '../../types/clients/clients';
import { UUID } from '../../types/standard';
import averageExpenditureSlice from '../averageExpenditure/averageExpenditureSlice';
import capitalModificationSlice from '../capitalModification/capitalModificationSlice';
import dataCollectionSlice from '../dataCollection/dataCollectionSlice';
import familyResponsabilitiesSlice from '../familyResponsabilites/familyResponsabilitiesSlice';
import { getClientDataFromOffer } from '../schemas/Oferta';
import schemasSlice from '../schemas/schemasSlice';
import { selectEngineState } from '../schemas/selectors';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { selectValoracionState } from './selectors';
import valoracionSlice, { ValoracionStartData } from './valoracionSlice';

function* loadValoracionSaga(
  action: PayloadAction<ValoracionStartData>
): Generator<any, void, any> {
  const state = yield select(selectValoracionState);
  if (state !== 'loading') {
    return;
  }

  if (action.payload.type === 'offer') {
    const result = (yield call(getOfferById, action.payload.offer_id)) as Await<
      ReturnType<typeof getOfferById>
    >;
    if (result.type === 'ok') {
      //initialize valoracion with original offer data
      const fromOffer = result.value;
      yield put(
        dataCollectionSlice.actions.saveInitialData({
          oferta: fromOffer,
          conyuge: getClientDataFromOffer(result.value.outputs, 'conyuge'),
        })
      );
      yield put(valoracionSlice.actions.loadExtendedOffer(fromOffer));
      if (fromOffer.inputs.cargas) {
        yield put(
          familyResponsabilitiesSlice.actions.saveFamilyResponsabilitiesNoMessage(
            fromOffer.inputs.cargas
          )
        );
      } else {
        yield put(familyResponsabilitiesSlice.actions.reset());
      }
      if (fromOffer.inputs.gastos) {
        yield put(
          averageExpenditureSlice.actions.saveAverageExpenditureDataWithoutSnackbar(
            fromOffer.inputs.gastos
          )
        );
      } else {
        yield put(averageExpenditureSlice.actions.reset());
      }
      yield put(
        capitalModificationSlice.actions.saveCapitalModificationData(
          fromOffer.inputs.capitales
        )
      );
      // ensure that computed data corresponds with orifinal offer results computing
      // the offer now. Must wait for engine loaded.
      let engineState = yield select(selectEngineState);
      while (engineState !== 'loaded') {
        yield delay(500);
        engineState = yield select(selectEngineState);
      }
      yield put(schemasSlice.actions.computeOffer());
      yield put(valoracionSlice.actions.loadValoracionOk());
    } else {
      showSnackBar({
        severity: 'error',
        message: 'No hemos podido cargar la oferta',
      });
    }
  } else {
    //get fresh client data
    const client = yield call(
      getFreshClient,
      action.payload.project.cliente.id
    );
    if (client) {
      yield put(
        dataCollectionSlice.actions.saveInitialData({
          cliente: client,
        })
      );
      yield put(familyResponsabilitiesSlice.actions.reset());
      yield put(averageExpenditureSlice.actions.reset());
      yield put(capitalModificationSlice.actions.resetCapitalModification());
      yield put(valoracionSlice.actions.loadValoracionOk());
    }
  }
}

function* getFreshClient(clientId: UUID): Generator<any, Client | null, any> {
  //get fresh client data
  const result = (yield call(getClientById, clientId)) as Await<
    ReturnType<typeof getClientById>
  >;
  switch (result.type) {
    case 'ok':
      return result.value;

    case 'validation-error':
      showSnackBar({
        severity: 'error',
        message: 'No hemos podido cargar el cliente',
      });
      return null;
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    valoracionSlice.actions.loadValoracion.type,
    loadValoracionSaga
  ),
];
export default sagas;
