import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { getClients } from '../../api/clients/getClients';
import { Await } from '../../types/api/api';
import { GetClientsDTO } from '../../types/clients/clients';
import snackBarSlice from '../snackBar/snackBarSlice';
import clientsSlice from './clientsSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getClientsSaga(
  action: PayloadAction<GetClientsDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(getClients, action.payload)) as Await<
      ReturnType<typeof getClients>
    >;
    switch (result.type) {
      case 'ok':
        yield put(clientsSlice.actions.getClientsOk(result.value));
        return;
      case 'validation-error':
        yield put(clientsSlice.actions.getClientsKo(result.value));
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'Se ha producido un error.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(clientsSlice.actions.getClientsKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'Se ha producido un error.',
        severity: 'error',
      })
    );
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    clientsSlice.actions.getClients.type,
    getClientsSaga
  ),
];

export default sagas;
