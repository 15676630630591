import { put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import Engine from 'ibs-cengine/dist/engine/Engine';
import { CInputs, COutput, Values } from 'ibs-cengine/dist/types/Calculator';
import { DBTable } from 'ibs-cengine/dist/types/Database';
import { ED } from 'ibs-cengine/dist/types/EngineDefinition';
import { all, call, delay } from 'redux-saga/effects';
import {
  getDefinitionById,
  getLatest,
  getTableVersionById,
} from '../../api/schemas/schemas';
import { Await } from '../../types/api/api';
import { TableReferenceDTO } from '../../types/schemas/schemas';
import {
  Asegurado,
  Conyuge,
  Hijo,
} from '../../valoracion/types/dataCollection/dataCollection';
import { CargasFamiliares } from '../../valoracion/types/familyResponsabilities/familyResponsabilites';
import { CapitalModificationState } from '../capitalModification/capitalModificationSlice';
import { selectCapitalModification } from '../capitalModification/selectors';
import { DataCollectionState } from '../dataCollection/dataCollectionSlice';
import { selectDataCollection } from '../dataCollection/selectors';
import { selectFamilyResponsabilities } from '../familyResponsabilites/selectors';
import snackBarSlice from '../snackBar/snackBarSlice';
import { toCInputs } from './Cinputs';
import schemasSlice from './schemasSlice';
import { selectHasGlobalMessages, selectOfferState } from './selectors';
import { arrangeErrors, getValueAsBoolean } from './util';

type SagaState =
  | {
      status: 'initial';
    }
  | {
      status: 'loading';
    }
  | {
      status: 'loaded';
      id: string;
      engine: Engine;
    };
let sagaState: SagaState = { status: 'initial' };

export interface ComputedOffer {
  data: InputData;
  inputs: CInputs;
  values: Values;
}

export interface InputData {
  principal: Asegurado;
  conyuge: Conyuge;
  hijos: Hijo[];
  modificacion_capitales: CapitalModificationState;
  cargas: CargasFamiliares;
}

function* startEngine(change: LocationChangeAction): Generator<any, void, any> {
  if (!change.payload.location.pathname.startsWith('/valoracion')) {
    if (
      change.payload.location.pathname === '/' &&
      sagaState.status === 'loaded'
    ) {
      // After logout slice engine state is "initial", but engine is already loaded on saga.
      // Notify slice that engine is already loaded on login.
      const output = (yield call(
        [sagaState.engine, sagaState.engine.load],
        {}
      )) as Await<COutput>;
      yield put(
        schemasSlice.actions.loadEngineOk({
          version_id: sagaState.id,
          tables: sagaState.engine.getTables(),
          initialValues: output.values,
        })
      );
    }
    return;
  }
  //already loaded
  if (sagaState.status === 'loading' || sagaState.status === 'loaded') {
    return;
  }

  yield call(loadEngine);
}

function* loadEngine() {
  // All systems go, load the engine
  sagaState = { status: 'loading' };

  try {
    yield put(schemasSlice.actions.loadEngine());

    console.log('Arrancando engine...');
    const latest = (yield call(getLatest)) as Await<
      ReturnType<typeof getLatest>
    >;
    const definition = (yield call(
      getDefinitionById,
      latest.definition_id
    )) as Await<ReturnType<typeof getDefinitionById>>;

    const tables = yield all(latest.tables.map((t) => call(loadTable, t)));
    const engineDefinition: ED = {
      version: definition.description,
      root: definition.definition.root,
    };
    sagaState = {
      status: 'loaded',
      id: latest.version_id,
      engine: new Engine(engineDefinition, tables),
    };
    const output = (yield call(
      [sagaState.engine, sagaState.engine.load],
      {}
    )) as Await<COutput>;

    yield put(
      schemasSlice.actions.loadEngineOk({
        version_id: sagaState.id,
        tables: sagaState.engine.getTables(),
        initialValues: output.values,
      })
    );
  } catch (e) {
    yield put(schemasSlice.actions.loadEngineKo(e));
  }
}

function* loadTable(
  tableReference: TableReferenceDTO
): Generator<any, DBTable | null, any> {
  const table = (yield call(getTableVersionById, tableReference)) as Await<
    ReturnType<typeof getTableVersionById>
  >;

  return {
    Nombre: tableReference.table_id,
    Descripcion: table.description,
    Indices: '',
    Campos: table.columns,
    Datos: table.data,
  } as DBTable;
}

function* computeOffer(): Generator<any, void, any> {
  if (sagaState.status !== 'loaded') {
    console.log(
      'Called computeOffer without a loaded engine. This should not happen ever. Status is ' +
        sagaState.status
    );
    yield delay(10 * 1001);
    document.location.reload();
    return;
  }

  const dataCollection: DataCollectionState = yield select(
    selectDataCollection
  );
  const cargas: CargasFamiliares = yield select(selectFamilyResponsabilities);
  const modificacionCapitales = yield select(selectCapitalModification);
  const inputData: InputData = {
    principal: dataCollection.principal,
    conyuge: dataCollection.conyuge,
    hijos: dataCollection.hijos,
    cargas: cargas,
    modificacion_capitales: modificacionCapitales,
  };
  const inputs = toCInputs(inputData);
  if (isEmpty(inputs)) {
    yield put(
      schemasSlice.actions.offerComputedWithErrors({
        computedOffer: {
          inputs: {},
          data: yield selectDataCollection,
          values: {},
        },
        errors: {
          principal: {
            global: {
              '.0': {
                path: '',
                message: 'No puedes calcular una oferta sin datos',
                type: 'global-error',
              },
            },
            local: {},
          },
          conyuge: { local: {}, global: {} },
        },
      })
    );
    return;
  }
  const output = (yield call(
    [sagaState.engine, sagaState.engine.run],
    inputs
  )) as Await<COutput>;
  const offer: ComputedOffer = {
    inputs: inputs,
    data: inputData,
    values: output.values,
  };
  const c_errors = arrangeErrors(output.errors);
  const conyugePresent = getValueAsBoolean(
    '.a.necesidades_compartidas',
    output.values
  );

  let hasErrors =
    Object.keys(c_errors.principal.global).length > 0 ||
    Object.keys(c_errors.principal.local).length > 0;
  if (conyugePresent) {
    hasErrors =
      hasErrors ||
      Object.keys(c_errors.conyuge.global).length > 0 ||
      Object.keys(c_errors.conyuge.local).length > 0;
  }
  if (hasErrors) {
    yield put(
      schemasSlice.actions.offerComputedWithErrors({
        errors: c_errors,
        computedOffer: offer,
      })
    );
    return;
  }
  yield put(schemasSlice.actions.offerComputed(offer));
}

function* reloadEngine() {
  while (true) {
    if (sagaState.status === 'loaded') {
      try {
        const latest = (yield call(getLatest)) as Await<
          ReturnType<typeof getLatest>
        >;
        if (latest.version_id !== sagaState.id) {
          yield call(loadEngine);
        }
      } catch (err) {}
    }
    yield delay(60 * 1000);
  }
}

function* clearOffer(change: LocationChangeAction): Generator<any, void, any> {
  if (!change.payload.location.pathname.startsWith('/valoracion')) {
    if (yield select(selectHasGlobalMessages)) {
      yield put(snackBarSlice.actions.hideSnackBar());
    }
    const offerState = yield select(selectOfferState);
    if (offerState !== 'initial') {
      yield put(schemasSlice.actions.clearOffer());
    }
  }
}

const isEmpty = (o: object) => Object.keys(o).length === 0;

const sagas = [
  takeLatest<PayloadAction<never>>(
    schemasSlice.actions.computeOffer.type,
    computeOffer
  ),
  takeEvery(LOCATION_CHANGE, startEngine),
  takeEvery(LOCATION_CHANGE, clearOffer),
  reloadEngine(),
];

export default sagas;
