import { APIValidationError } from '../../types/api/api';
import { Policy, PolicyDTO } from '../../types/policies/policies';
import { Result, validatedApiCall } from '../api';

export const updatePolicy = async (
  policy: PolicyDTO
): Promise<Result<Policy, APIValidationError>> => {
  return validatedApiCall<Policy>(`/api/ofertas/${policy.id_oferta}/poliza`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(policy),
  });
};
