import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import renderWhen from '../../../helpers/renderWhen';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import { hide, newProject } from '../../../store/project/newProjectModule';
import {
  selectNewProjectErrors,
  selectNewProjectVisible,
} from '../../../store/project/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { APIError } from '../../../types/api/api';
import { NewProjectDTO } from '../../../types/projects/projects';
import { UUID } from '../../../types/standard';
import Button from '../../Buttons/Button/Button';
import ClientSearchField from '../../Form/ClientSearchField/ClientSearchField';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';

interface ModalNewClient {
  cliente_id: UUID;
}
const TypedClientSearchField = ClientSearchField<ModalNewClient>();

const selectClientSchema = yup
  .object()
  .shape({
    cliente_id: yup.string().required('Introduce un cliente').defined(),
  })
  .defined();

interface NewProjectProps {
  serverErrors: APIError | null;
  navigateTo: (path: string) => void;
  saveProject: (newProject: NewProjectDTO) => void;
  closeModal: () => void;
}

const NewProject: React.FC<NewProjectProps> = ({
  serverErrors,
  navigateTo,
  closeModal,
  saveProject,
}) => {
  const {
    register,
    errors,
    setValue,
    clearError,
    handleSubmit,
    watch,
    setError,
    formState,
  } = useOurForm<ModalNewClient>({
    validationSchema: selectClientSchema,
  });
  useValidatorAPI(serverErrors, setError, formState);

  const [selectedClient, setSelectedClient] = useState(false);
  useEffect(() => {
    if (watch('cliente_id')) {
      setSelectedClient(true);
    } else {
      setSelectedClient(false);
    }
  }, [watch]);

  const handleCreateClient = () => {
    closeModal();
    navigateTo('/clientes/nuevo');
  };

  const handleNewProject = (data: ModalNewClient) => {
    const newData: NewProjectDTO = {
      producto: 'HEL',
      cliente_id: data.cliente_id,
    };
    saveProject(newData);
  };

  return (
    <Modal
      closeModal={() => {
        closeModal();
      }}
    >
      <div className={styles.container}>
        <h1 className={styles.title}>Nuevo Proyecto</h1>

        <p className={styles.description}>
          Para crear un proyecto puedes seleccionar un cliente existente o crear
          uno nuevo.
        </p>
        <form onSubmit={handleSubmit(handleNewProject)}>
          <div className={styles.filter}>
            <TypedClientSearchField
              show="onlyDirectClients"
              estados={[
                'candidato',
                'presentacion',
                'guardado',
                'proyecto_finalizado',
              ]}
              schema={selectClientSchema}
              errors={errors}
              setValue={setValue}
              clearError={clearError}
              label=""
              input={{
                name: 'cliente_id',
                ref: register,
                type: 'text',
                placeholder: 'Selecciona un cliente',
              }}
            />
          </div>
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={handleCreateClient}
              variant="positive"
              type="button"
            >
              Crear Cliente
            </Button>
            <Button disabled={selectedClient ? false : true} variant="positive">
              Crear Proyecto
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const ConnectedNewProject = connect(
  (state: RootState) => ({
    serverErrors: selectNewProjectErrors(state),
  }),
  (dispatch: AppDispatch) => ({
    closeModal: () => dispatch(hide()),
    navigateTo: (payload: string) => dispatch(push(payload)),
    saveProject: (payload: NewProjectDTO) => dispatch(newProject(payload)),
  })
)(NewProject);

export default renderWhen(ConnectedNewProject, selectNewProjectVisible);
