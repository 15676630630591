import { NewCommentDTO, CommentDataItem } from '../../types/clients/comments';
import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export const newClientComment = async (
  comment: NewCommentDTO
): Promise<Result<CommentDataItem, APIValidationError>> => {
  console.log('Saving comment', comment);
  return validatedApiCall<CommentDataItem>(
    `/api/clientes/${comment.client_id}/comments`,
    {
      method: 'POST',
      auth: true,
      body: JSON.stringify(comment),
    }
  );
};
