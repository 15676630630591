import { put, select, takeLatest } from 'redux-saga/effects';
import { updatePolicy } from '../../api/policy/updatePolicy';
import createApiModule from '../createApiModule';
import { getProject } from '../project/projectModule';
import { selectProject } from '../project/selectors';
import { showSnackBar } from '../snackBar/snackBarSlice';

const updatePolicyModule = createApiModule({
  name: 'updatePolicy',
  apiMethod: updatePolicy,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido actualizar la póliza',
  }),
});

function* redirecToPoliciesList() {
  const project = (yield select(selectProject)) as ReturnType<
    typeof selectProject
  >;

  if (!project) {
    return yield put(
      showSnackBar({
        message: 'Error recargando los datos del proyecto',
        severity: 'error',
        path: '/proyectos',
      })
    );
  }

  yield put(getProject(project.id));

  return yield put(
    showSnackBar({
      message: 'Póliza actualizada correctamente',
      severity: 'success',
      path: `/proyectos/${project.id}/polizas`,
    })
  );
}

export const {
  start: updateOfferPolicy,
  success: updataOfferPolicyOk,
  error: updateOfferPolicyKo,
} = updatePolicyModule.slice.actions;

updatePolicyModule.sagas = [
  ...updatePolicyModule.sagas,
  takeLatest(
    updatePolicyModule.slice.actions.success.type,
    redirecToPoliciesList
  ),
];

export default updatePolicyModule;
