import { Appointment } from '../../types/appointments/appointments';
import createApiModule from '../createApiModule';
import { getAppointments as apiMethod } from '../../api/appointments/getAppointments';

const clientAppointmentsModule = createApiModule({
  name: 'clientAppointments',
  initialData: [] as Appointment[],
  apiMethod,
  parse: (appointments) => appointments.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No se han podido cargar las citas.',
  }),
});

export const {
  start: getClientAppointments,
  success: getClientAppointmentsOk,
  error: getClientAppointmentsKo,
  reset: resetClientAppointments,
} = clientAppointmentsModule.slice.actions;

export default clientAppointmentsModule;
