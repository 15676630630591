import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';
import { GetStatsDTO, StatsDTO } from '../../types/statistics/statistics';

export const getStats = async (
  data: GetStatsDTO
): Promise<Result<StatsDTO, APIValidationError>> => {
  const { user_id, responsable_id, area_id, filter_user_id } = data;
  return validatedApiCall<StatsDTO>(
    `/api/statistics?user_id=${
      user_id || filter_user_id
    }&responsable_id=${responsable_id}&area_id=${area_id}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
