import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EditOfferAmount,
  EditOfferAplica,
  ModificacionCapitales,
} from '../../types/offers/tabulated_offers';

export type CapitalModificationState = {
  principal: ModificacionCapitales;
  conyuge: ModificacionCapitales;
  applyGuarantees: ModificacionCapitales;
};

const initialState: CapitalModificationState = {
  principal: {},
  conyuge: {},
  applyGuarantees: {},
};

const capitalModificationSlice = createSlice({
  name: 'capitalModification',
  initialState,
  reducers: {
    saveCapitalModificationData(
      state,
      action: PayloadAction<CapitalModificationState>
    ) {
      return {
        principal: { ...action.payload.principal },
        conyuge: { ...action.payload.conyuge },
        applyGuarantees: { ...action.payload.applyGuarantees },
      };
    },
    saveCapitalModificationAndCompute(
      state,
      action: PayloadAction<EditOfferAmount>
    ) {
      if (action.payload.name) {
        if (action.payload.asegurado === 'principal') {
          state.principal[action.payload.name] = action.payload.value;
        }
        if (action.payload.asegurado === 'conyuge') {
          state.conyuge[action.payload.name] = action.payload.value;
        }
      }
    },
    saveApplyGuaranteeAndCompute(
      state,
      action: PayloadAction<EditOfferAplica>
    ) {
      if (action.payload.name) {
        state.applyGuarantees[action.payload.name] = action.payload.value;
      }
    },
    resetCapitalModification() {
      return { ...initialState };
    },
  },
});

export const {
  saveCapitalModificationData,
  saveCapitalModificationAndCompute,
  saveApplyGuaranteeAndCompute,
  resetCapitalModification,
} = capitalModificationSlice.actions;

export default capitalModificationSlice;
