import { Role } from '../../types/auth/roles';
import { UUID } from '../../types/standard';
import { selectAuthData, selectUserRole } from '../auth/selectors';
import { RootState } from '../store';

export const selectUsers = (state: RootState) => state.users?.users || [];

export const selectUsersByRole = (state: RootState, role: string) =>
  selectUsers(state).filter((user) => user.role_id === role);

export const selectAllComercialesAndGestores = (state: RootState) => {
  const allUsers = selectUsers(state).filter(
    (user) => user.role_id === 'comercial' || user.role_id === 'gestor_area'
  );
  const ownData = selectAuthData(state);
  if (selectUserRole(state) === 'gestor_area' && ownData) {
    allUsers.push(ownData);
  }
  return allUsers;
};

export const getUserName = (state: RootState, id: string) => {
  const user = selectUsers(state).find((user) => user.id === id) || null;
  return user ? `${user.nombre} ${user.apellido1} ${user.apellido2}` : null;
};

export const selectUserById = (state: RootState, id: string) => {
  return selectUsers(state).find((user) => user.id === id);
};

export const selectHasSomeRole = (
  state: RootState,
  id: UUID,
  roles: Role[]
) => {
  const user = selectUsers(state).find((user) => user.id === id) || null;
  const userRoles = user?.role_id || null;
  return userRoles ? roles.includes(userRoles) : false;
};

export const selectUsersLoading = (state: RootState) => state.users.loading;

export const selectEditUserLoading = (state: RootState) =>
  state.editUser.loading;

export const selectNewUser = (state: RootState) => state.registerUser;

export const selectNewUserLoading = (state: RootState) =>
  selectNewUser(state).loading;

export const selectNewUserErrors = (state: RootState) =>
  selectNewUser(state).errors;

export const selectResponsablesByArea = (state: RootState, area_id?: string) =>
  area_id
    ? selectUsersByRole(state, 'gestor_area').filter(
        (user) => user.area_id === area_id
      )
    : null;

export const selectComercialesByResponsable = (
  state: RootState,
  responsable_id?: string
) =>
  responsable_id
    ? selectUsersByRole(state, 'comercial').filter(
        (user) => user.responsable_id === responsable_id
      )
    : null;
