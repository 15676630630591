import { Result, validatedApiCall } from '../api';
import { RegisterClientDTO } from '../../types/clients/register';
import { Client } from '../../types/clients/clients';
import { APIValidationError } from '../../types/api/api';

export const registerClient = async (
  data: RegisterClientDTO
): Promise<Result<Client, APIValidationError>> => {
  return validatedApiCall<Client>(`/api/clientes`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
