export type Role =
  | 'admin_int'
  | 'admin_ibs'
  | 'director_comercial'
  | 'gestor_area'
  | 'comercial';

export enum RoleEnum {
  'admin_int' = 'Administrador Interficie',
  'admin_ibs' = 'Administrador Facilsure',
  'director_comercial' = 'Director Comercial',
  'gestor_area' = 'Gestor de Área',
  'comercial' = 'Comercial',
}
