import { Result, validatedApiCall } from '../api';
import { GetClientsDTO, ClientsDTO } from '../../types/clients/clients';
import { APIValidationError } from '../../types/api/api';

export const getClients = async (
  data: GetClientsDTO
): Promise<Result<ClientsDTO, APIValidationError>> => {
  const { page, size, buscar, usuario, estado } = data;
  let stringEstado = '';
  if (estado && estado.length > 0) {
    stringEstado = '&' + estado.map((estado) => `estado[]=${estado}`).join('&');
  }
  return validatedApiCall<ClientsDTO>(
    `/api/clientes?page=${page}&size=${size}&buscar=${buscar}&usuario=${usuario}${stringEstado}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
