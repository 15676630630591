import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectHasSomeRole } from '../../../store/auth/selectors';
import { RootState } from '../../../store/store';
import { Role } from '../../../types/auth/roles.d';

interface PrivateRouteProps extends RouteProps {
  allowedRoles?: Role[];
  canAccessRoute: boolean;
  gate?: (state: RootState, params: any) => boolean;
  state: RootState;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  render,
  allowedRoles = [],
  canAccessRoute,
  gate,
  state,
  ...rest
}) => {
  if (canAccessRoute) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const passValidation = gate ? gate(state, props.match.params) : true;

          if (!passValidation) {
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location },
                }}
              />
            );
          }

          return Component ? (
            <Component {...props} />
          ) : render ? (
            render(props)
          ) : null;
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )}
    />
  );
};

export default connect(
  (
    state: RootState,
    ownProps: Omit<PrivateRouteProps, 'canAccessRoute' | 'state'>
  ) => ({
    canAccessRoute: selectHasSomeRole(state, ownProps.allowedRoles || []),
    state,
  })
)(PrivateRoute);
