import React from 'react';
import styles from './globalError.module.scss';

interface GlobalErrorProps {
  message: null | string;
}

const GlobalError: React.FC<GlobalErrorProps> = ({ message }) => {
  return message ? <div className={styles.container}>{message}</div> : null;
};

export default GlobalError;
