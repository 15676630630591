"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hInt = exports.hMoney = exports.hRound = exports.select = exports.rentaFutura = exports.im = exports.arrayMove = exports.actuarialAge = exports.toDays = exports.birthday = exports.age = exports.isNumber = void 0;
const isNumber = (str) => /^[0-9]+$/.test(str);
exports.isNumber = isNumber;
const age = (value) => {
    const birthday = new Date(value);
    const today = new Date();
    if (today.getMonth() > birthday.getMonth() ||
        (today.getMonth() === birthday.getMonth() &&
            today.getDate() >= birthday.getDate())) {
        return today.getFullYear() - birthday.getFullYear();
    }
    return Math.max(0, today.getFullYear() - birthday.getFullYear() - 1);
};
exports.age = age;
/**
 * Sample birth date to reach an age
 * @param age
 */
const birthday = (age) => {
    const today = new Date();
    return (today.getFullYear() -
        age +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate());
};
exports.birthday = birthday;
const toDays = (value) => {
    const d = new Date(value);
    return Math.ceil(d.getTime() / (1000 * 60 * 60 * 24));
};
exports.toDays = toDays;
const actuarialAge = (value) => {
    const birthday = new Date(value);
    const today = new Date();
    let lastB = new Date(birthday);
    let nextB = new Date(birthday);
    nextB.setFullYear(today.getFullYear() + 1);
    if (today.getMonth() > birthday.getMonth() ||
        (today.getMonth() === birthday.getMonth() &&
            today.getDate() >= birthday.getDate())) {
        //last birthday was in this year
        lastB.setFullYear(today.getFullYear());
        nextB.setFullYear(today.getFullYear() + 1);
    }
    else {
        //last birthday was on past year
        lastB.setFullYear(today.getFullYear() - 1);
        nextB.setFullYear(today.getFullYear());
    }
    if (today.getTime() - lastB.getTime() > nextB.getTime() - today.getTime()) {
        //next birthday is closer than last birthday to today
        return exports.age(value) + 1;
    }
    return exports.age(value);
};
exports.actuarialAge = actuarialAge;
const arrayMove = (from, to, array) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
    return array;
};
exports.arrayMove = arrayMove;
const TIPO_INTERES = 0.01;
/**
 * (1+i)^(1/m) - 1
 */
const im = (meses) => {
    const base = 1 + TIPO_INTERES;
    const exp = 1 / meses;
    return Math.pow(base, exp) - 1;
};
exports.im = im;
/**
 * R * [ (1 - (1 + im)^(-n*m) ) / im]
 */
const rentaFutura = (renta, años) => {
    const i_m = exports.im(12);
    const unomasim = 1 + i_m;
    const exp = -años * 12;
    const pow = Math.pow(unomasim, exp);
    return (renta * (1 - pow)) / i_m;
};
exports.rentaFutura = rentaFutura;
exports.select = (() => {
    const cache = new Map();
    return (tables) => (from, label, value) => {
        const key = `${from}|${label}|${value}`;
        if (!cache.has(key)) {
            cache.set(key, tables[from].map((item) => ({
                label: item[label],
                value: item[value],
            })));
        }
        return cache.get(key);
    };
})();
/**
 * Redondeo por defecto con decimales, centralizado.
 * @param amount
 */
const hRound = (amount) => {
    return +Number(amount).toFixed(2);
};
exports.hRound = hRound;
const moneyFormat = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});
const hMoney = (amount) => {
    return `${moneyFormat.format(amount)} €`;
};
exports.hMoney = hMoney;
/**
 * Redondeo por defecto a entero, centralizado.
 * @param amount
 */
const hInt = (amount) => {
    return +Number(amount).toFixed();
};
exports.hInt = hInt;
