import { newAppointment as apiMethod } from '../../api/appointments/newAppointment';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { goBack } from 'connected-react-router';

const newAppointmentModule = createApiModule({
  name: 'newAppointment',
  apiMethod,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'La cita se ha agendado correctamente.',
    }),
    goBack(),
  ],
  onError: () => ({
    message: 'Se ha producido un error al agendar la cita.',
    path: '/agenda',
  }),
  onValidationError: () => ({
    message: 'Se ha producido un error al agendar la cita.',
    path: '/agenda',
  }),
});

export const {
  start: newAppointment,
  success: newAppointmentOk,
  error: newAppointmentKo,
} = newAppointmentModule.slice.actions;

export default newAppointmentModule;
