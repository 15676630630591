import { AlertsDTO, AlertURLDTO } from '../../types/alerts/alerts';
import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';

export const getAlerts = async (
  data: AlertURLDTO
): Promise<Result<AlertsDTO, APIValidationError>> => {
  const { page, size, read, sort, order } = data;

  return validatedApiCall<AlertsDTO>(
    `/api/alertas?page=${page}&size=${size}&read=${read}&sort=${sort}&order=${order}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
