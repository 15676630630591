import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../../types/clients/clients';
import { ExtendedOffer } from '../../types/offers/offers';
import {
  Asegurado,
  AseguradoDTO,
  Conyuge,
  ConyugeDTO,
  OtrosDatosDTO,
  FamilyDTO,
  Hijo,
  HijoDTO,
} from '../../valoracion/types/dataCollection/dataCollection';

export type DataCollectionState = {
  principal: Asegurado;
  conyuge: Conyuge;
  hijos: Hijo[];
  has_required_data: boolean;
};

export const initialState: DataCollectionState = {
  principal: {
    sexo: 'hombre',
    estado_civil: 'soltero',
    nombre: '',
    apellido1: '',
    apellido2: '',
    profesion_id: null,
    fecha_nacimiento: null,
    fumador: false,
    altura: null,
    peso: null,
    moto: false,
    cilindrada: null,
    necesidades: 0,
    cotizacion: {
      fecha_alta: null,
      rg: {
        porcentaje: null,
        importe: null,
      },
      reta: {
        cuota: null,
        base_cotizacion: null,
      },
      otros: {
        importe: null,
        cotiza: false,
      },
    },
  },
  conyuge: {
    nombre: '',
    apellido1: '',
    apellido2: '',
    sexo: 'mujer',
    profesion_id: null,
    edad: null,
    fecha_nacimiento: null,
    aportacion_porcentaje: null,
    necesidades_compartidas: false,
    fumador: null,
    altura: null,
    peso: null,
    moto: null,
    cilindrada: null,
    cotizacion: {
      rg: {
        porcentaje: null,
        importe: null,
      },
      reta: {
        cuota: null,
        base_cotizacion: null,
      },
      otros: {
        importe: null,
        cotiza: false,
      },
    },
  },
  hijos: [],
  has_required_data: false,
};

export interface InitialDataCollection {
  cliente?: Client;
  conyuge?: any;
  oferta?: ExtendedOffer;
}

const dataCollectionSlice = createSlice({
  name: 'dataCollection',
  initialState,
  reducers: {
    saveInitialData(state, action: PayloadAction<InitialDataCollection>) {
      if (action.payload.oferta) {
        if (action.payload.oferta.inputs) {
          return {
            ...action.payload.oferta.inputs.datos,
            has_required_data: true,
          };
        }
        return { ...initialState };
      }
      if (action.payload.conyuge) {
        state.conyuge = {
          ...state.conyuge,
          edad: action.payload.conyuge.edad,
          fecha_nacimiento: action.payload.conyuge.fecha_nacimiento,
          sexo: action.payload.conyuge.sexo,
        };
      }
      if (action.payload.cliente) {
        return {
          ...initialState,
          principal: {
            ...initialState.principal,
            nombre: action.payload.cliente.nombre,
            apellido1: action.payload.cliente.apellido1,
            apellido2: action.payload.cliente.apellido2,
            estado_civil: action.payload.cliente.estado_civil ?? 'soltero',
            fecha_nacimiento: action.payload.cliente.fecha_nacimiento,
            profesion_id: action.payload.cliente.profesion_id,
            sexo: action.payload.cliente.sexo ?? 'hombre',
          },
        };
      }
    },
    saveFamilyData(state, action: PayloadAction<FamilyDTO>) {
      state.principal = {
        ...state.principal,
        sexo: action.payload.sexo,
        estado_civil: action.payload.estado_civil ?? 'soltero',
      };
      state.conyuge = {
        ...state.conyuge,
        sexo: action.payload.sexo === 'mujer' ? 'hombre' : 'mujer',
      };
      state.hijos = action.payload.hijos;
      if (
        state.principal.estado_civil !== 'casado' &&
        state.principal.estado_civil !== 'pareja_hecho'
      ) {
        state.conyuge = initialState.conyuge;
      }
    },
    saveInsuredData(state, action: PayloadAction<AseguradoDTO>) {
      state.conyuge = {
        ...state.conyuge,
        edad: action.payload.edad_conyuge,
        fecha_nacimiento: action.payload.fecha_nacimiento_conyuge,
      };
      state.hijos = action.payload.hijos;
      state.principal = {
        ...state.principal,
        nombre: action.payload.nombre,
        apellido1: action.payload.apellido1,
        apellido2: action.payload.apellido2,
        profesion_id: action.payload.profesion_id,
        fecha_nacimiento: action.payload.fecha_nacimiento,
        necesidades: action.payload.necesidades,
        fumador: action.payload.fumador,
        altura: action.payload.altura,
        peso: action.payload.peso,
        moto: action.payload.moto,
        cilindrada: action.payload.cilindrada,
        cotizacion: {
          fecha_alta: action.payload.fecha_cotizacion_ss,
          rg: {
            porcentaje: action.payload.rg_porcentaje || null,
            importe: action.payload.rg_importe || null,
          },
          reta: {
            cuota: action.payload.reta_cuota || null,
            base_cotizacion: action.payload.reta_base_cotizacion || null,
          },
          otros: {
            importe: action.payload.otros_importe || null,
            cotiza: action.payload.otros_cotiza || false,
          },
        },
      };
      state.has_required_data = true;
    },
    saveOtrosDatos(state, action: PayloadAction<OtrosDatosDTO>) {
      state.principal = {
        ...state.principal
      };
      if (action.payload.revalorizacion)
      {
        state.principal.revalorizacion = action.payload.revalorizacion;
      }
      if (action.payload.periodicidad)
      {
        state.principal.periodicidad = action.payload.periodicidad;
      }
    },
    saveConyugeData(state, action: PayloadAction<ConyugeDTO>) {
      state.conyuge = {
        ...state.conyuge,
        nombre: action.payload.nombre,
        apellido1: action.payload.apellido1,
        apellido2: action.payload.apellido2,
        sexo: action.payload.sexo,
        profesion_id: action.payload.profesion_id,
        edad: action.payload.edad,
        fecha_nacimiento: action.payload.fecha_nacimiento,
        fumador: action.payload.fumador ?? null,
        altura: action.payload.altura ?? null,
        peso: action.payload.peso ?? null,
        moto: action.payload.moto ?? null,
        cilindrada: action.payload.cilindrada ?? null,
        aportacion_porcentaje:
          action.payload.aportacion_porcentaje ||
          (action.payload.aportacion_porcentaje === undefined
            ? null
            : action.payload.aportacion_porcentaje),
        necesidades_compartidas: action.payload.necesidades_compartidas,
        cotizacion: {
          fecha_alta: action.payload.fecha_cotizacion_ss,
          rg: {
            porcentaje: action.payload.rg_porcentaje || null,
            importe: action.payload.rg_importe || null,
          },
          reta: {
            cuota: action.payload.reta_cuota || null,
            base_cotizacion: action.payload.reta_base_cotizacion || null,
          },
          otros: {
            importe: action.payload.otros_importe || null,
            cotiza: action.payload.otros_cotiza || false,
          },
        },
      };
    },
    saveChildrenData(state, action: PayloadAction<HijoDTO>) {
      state.hijos[action.payload.hijo] = action.payload.data;
    },
  },
});

export const {
  saveInitialData,
  saveFamilyData,
  saveInsuredData,
  saveOtrosDatos,
  saveConyugeData,
  saveChildrenData,
} = dataCollectionSlice.actions;

export default dataCollectionSlice;
