import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { registerClient } from '../../api/clients/registerClient';
import { Await } from '../../types/api/api';
import { RegisterClientDTO } from '../../types/clients/register';
import snackBarSlice from '../snackBar/snackBarSlice';
import registerClientSlice from './registerClientSlice';

function* registerSaga(
  action: PayloadAction<RegisterClientDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(registerClient, action.payload)) as Await<
      ReturnType<typeof registerClient>
    >;
    switch (result.type) {
      case 'ok':
        yield put(registerClientSlice.actions.registerOk());
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'El cliente se ha creado correctamente.',
            path: `/clientes/${result.value.id}`,
            severity: 'success',
          })
        );
        return;
      case 'validation-error':
        yield put(registerClientSlice.actions.registerKo(result.value));
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'No se ha podido crear al cliente.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(registerClientSlice.actions.registerKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'No se ha podido crear al cliente.',
        severity: 'error',
      })
    );
  }
}

const sagas = [
  takeLatest<PayloadAction<RegisterClientDTO>>(
    registerClientSlice.actions.register.type,
    registerSaga
  ),
];

export default sagas;
