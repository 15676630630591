import { ChangeAreaDTO } from '../../types/actions/actions';
import { APIValidationError } from '../../types/api/api';
import { User } from '../../types/users/users';
import { Result, validatedApiCall } from '../api';

export const changeArea = async (
  data: ChangeAreaDTO
): Promise<Result<User, APIValidationError>> => {
  const { user_id, area_id } = data;

  return validatedApiCall<User>(`/api/users/${user_id}/area`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({
      area_id,
    }),
  });
};
