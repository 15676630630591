import { getClients } from '../../api/clients/getClients';
import { Client, GetClientsDTO } from '../../types/clients/clients';
import createApiModule from '../createApiModule';
import { UUID } from '../../types/standard';
import { EstadoCliente } from '../../types/clients/estado_cliente';

export interface SearchClientsDTO {
  query: string;
  usuario: UUID | null;
  estado: EstadoCliente[];
}
const apiMethod = ({ query, usuario, estado }: SearchClientsDTO) => {
  const params: GetClientsDTO = {
    buscar: query,
    page: 1,
    size: 10,
    estado: estado,
    usuario: usuario || '',
  };
  return getClients(params);
};

const clientSearchModule = createApiModule({
  name: 'clientSearch',
  apiMethod,
  debounceDelay: 200,
  initialData: [] as Client[],
  parse: (response) => response.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'Ha habido un problema cargando los horarios ocupados',
  }),
});

export const {
  start: searchClients,
  success: searchClientsOk,
  error: searchClientsKo,
  reset: searchClientsReset,
} = clientSearchModule.slice.actions;

export default clientSearchModule;
