import { getDefinitions } from '../../api/dictionary/getDefinitions';
import createApiModule from '../createApiModule';
import { DictionaryData, Dictionary } from '../../types/dictionary/dictionary';
import { GenericState } from '../../types/apiModule';

const initialState: GenericState<Dictionary[]> = {
  errors: null,
  loading: false,
  data: [] as Dictionary[],
};

const dictionaryModule = createApiModule({
  name: 'dictionary',
  apiMethod: getDefinitions,
  initialState,
  parse: (dictionary: DictionaryData) => dictionary.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar la Segurpedia',
    path: '/',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar la Segurpedia',
    path: '/',
  }),
});

export const {
  start: getDictionary,
  success: getDictionaryOk,
  error: getDictionaryKo,
  reset: resetDictionary,
} = dictionaryModule.slice.actions;

export default dictionaryModule;
