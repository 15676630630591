import { getAppointments as apiMethod } from '../../api/appointments/getAppointments';
import { Appointment } from '../../types/appointments/appointments';
import createApiModule from '../createApiModule';

const upcomingAppointmentsModule = createApiModule({
  name: 'upcomingAppointments',
  initialData: [] as Appointment[],
  apiMethod,
  parse: (appointments) => appointments.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No se han podido cargar las citas.',
    severity: 'error',
  }),
});

export const {
  start: getUpcomingAppointments,
  success: getUpcomingAppointmentsOk,
  error: getUpcomingAppointmentsKo,
} = upcomingAppointmentsModule.slice.actions;

export default upcomingAppointmentsModule;
