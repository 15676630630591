import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { Area } from '../../types/areas/areas';

export type AreasState = {
  loading: boolean;
  errors: APIError | null;
  areas: Area[];
};

export const initialState: AreasState = {
  loading: false,
  errors: null,
  areas: [],
};

const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    getAreas(state) {
      state.loading = true;
      state.errors = null;
      state.areas = [];
    },
    getAreasOk(state, action: PayloadAction<Area[]>) {
      state.loading = false;
      state.errors = null;
      state.areas = action.payload;
    },
    getAreasKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.areas = [];
    },
  },
});

export const { getAreas, getAreasOk, getAreasKo } = areasSlice.actions;

export default areasSlice;
