import React from 'react';
import styles from './buttonsGroup.module.scss';

export type Variant = 'profile' | 'center' | 'default';

interface ButtonsWrapperProps {
  variant?: Variant;
}

const theme = (variant: Variant): string => {
  switch (variant) {
    case 'profile':
      return styles.isProfile;
    case 'center':
      return styles.isCentered;
    case 'default':
      return '';
  }
};

const ButtonsGroup: React.FC<ButtonsWrapperProps> = ({
  children,
  variant = 'default',
}) => <div className={`${styles.root} ${theme(variant)}`}>{children}</div>;

export default ButtonsGroup;
