import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styles from '../../../../components/Layouts/Layout/layout.module.scss';
import {
  isAreaExposicionMotivos,
  isAreaValoracion,
} from '../../../../store/app/selectors';
import {
  selectCanEditOffer,
  selectCanViewAnalysis,
  selectHasRequiredData,
} from '../../../../store/dataCollection/selectors';
import { RootState } from '../../../../store/store';
import {
  selectInsuredOfferSaved,
  selectValoracionProject,
} from '../../../../store/valoracion/selectors';
import MenuAdministracion from '../../../../themes/active/valoracion/components/Menus/MainMenu/MenuAdministracion';
import MenuLogout from '../../../../themes/active/valoracion/components/Menus/MainMenu/MenuLogout';
import { Project } from '../../../../types/projects/projects';
import { ReactComponent as IconAnalisis } from '../../../assets/asideMenu/icon_analisis.svg';
import { ReactComponent as IconCapitales } from '../../../assets/asideMenu/icon_capitales.svg';
import { ReactComponent as IconCargas } from '../../../assets/asideMenu/icon_cargas.svg';
import { ReactComponent as IconGasto } from '../../../assets/asideMenu/icon_gasto.svg';
import { ReactComponent as IconOfertas } from '../../../assets/asideMenu/icon_ofertas.svg';
import { ReactComponent as IconSegurpedia } from '../../../assets/asideMenu/icon_segurpedia.svg';
import { ReactComponent as IconTomaDatos } from '../../../assets/asideMenu/icon_tomaDatos.svg';

interface MenuProps {
  isActive: boolean;
  isOpenMenu: boolean;
  setIsOpenMenu: (x: boolean) => void;
  styleRoute: (path: string) => string;
  project: Project | null;
  hasRequiredData: boolean;
  canViewAnalysis: boolean;
  canEditOffer: boolean;
  isNewOfferSaved: boolean;
}

const Menu: React.FC<MenuProps> = ({
  isActive,
  isOpenMenu,
  setIsOpenMenu,
  styleRoute,
  project,
  hasRequiredData,
  canViewAnalysis,
  canEditOffer,
  isNewOfferSaved,
}) => {
  const canViewCharges = canViewAnalysis && !isNewOfferSaved;
  const canViewCapitalModification = canEditOffer && !isNewOfferSaved;
  if (!isActive || (!hasRequiredData && !canEditOffer)) return null;
  return (
    <nav
      data-testid={isOpenMenu ? 'isMenuVisible' : 'isMenuHidden'}
      className={`${styles.navigation} ${isOpenMenu && styles.isOpen}`}
    >
      <button
        aria-label={isOpenMenu ? 'Cerrar menú' : 'Abrir menú'}
        onClick={() => setIsOpenMenu(!isOpenMenu)}
        className={`${styles.burger} ${styles.icon} ${
          isOpenMenu ? styles.isOpen : ''
        }`}
        type="button"
      >
        <span className={styles.burgerBox}>
          <span className={`${styles.burgerInner}`}></span>
        </span>
      </button>
      <ul className={`${styles.mainMenu}`}>
        <li className={`${styleRoute('/valoracion/toma-datos')}`}>
          <Link
            data-testid="tomaDatosLink"
            className={styles.menuLink}
            to="/valoracion/toma-datos"
          >
            <div className={styles.iconWrapper}>
              <IconTomaDatos className={styles.menuIcon} />
            </div>
            <span
              data-testid={
                isOpenMenu ? 'isTomaDatosVisible' : 'isTomaDatosHidden'
              }
              className={styles.menuText}
            >
              Toma de Datos
            </span>
          </Link>
        </li>
        {canViewAnalysis && (
          <li className={`${styleRoute('/valoracion/analisis')}`}>
            <Link
              data-testid="analisisLink"
              className={styles.menuLink}
              to="/valoracion/analisis"
            >
              <div className={styles.iconWrapper}>
                <IconAnalisis className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isAnalisisVisible' : 'isAnalisisHidden'
                }
                className={styles.menuText}
              >
                Análisis
              </span>
            </Link>
          </li>
        )}
        {canViewCapitalModification && (
          <li className={`${styleRoute('/valoracion/capitales')}`}>
            <Link
              data-testid="capitalesLink"
              className={styles.menuLink}
              to="/valoracion/capitales"
            >
              <div className={styles.iconWrapper}>
                <IconCapitales className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isCapitalesVisible' : 'isCapitalesHidden'
                }
                className={styles.menuText}
              >
                Modificar Capitales
              </span>
            </Link>
          </li>
        )}
        {canViewCharges && (
          <li className={`${styleRoute('/valoracion/cargas')}`}>
            <Link
              data-testid="cargasLink"
              className={styles.menuLink}
              to="/valoracion/cargas"
            >
              <div className={styles.iconWrapper}>
                <IconCargas className={styles.menuIcon} />
              </div>
              <span
                data-testid={isOpenMenu ? 'isCargasVisible' : 'isCargasHidden'}
                className={styles.menuText}
              >
                Cargas
              </span>
            </Link>
          </li>
        )}
        {canEditOffer && (
          <li className={`${styleRoute('/valoracion/gasto-medio')}`}>
            <Link
              data-testid="gastoMedioLink"
              className={styles.menuLink}
              to="/valoracion/gasto-medio"
            >
              <div className={styles.iconWrapper}>
                <IconGasto className={styles.menuIcon} />
              </div>
              <span
                data-testid={
                  isOpenMenu ? 'isGastoMedioVisible' : 'isGastoMedioHidden'
                }
                className={styles.menuText}
              >
                Gasto Medio
              </span>
            </Link>
          </li>
        )}
        <li
          className={`${styleRoute(
            `/valoracion/proyectos/${project && project.id}/ofertas`
          )}`}
        >
          <Link
            data-testid="ofertasLink"
            className={styles.menuLink}
            to={`/valoracion/proyectos/${project && project.id}/ofertas`}
          >
            <div className={styles.iconWrapper}>
              <IconOfertas className={styles.menuIcon} />
            </div>
            <span
              data-testid={isOpenMenu ? 'isOfertasVisible' : 'isOfertasHidden'}
              className={styles.menuText}
            >
              Ofertas
            </span>
          </Link>
        </li>
        <li className={`${styles.menuItem} ${styles.isSegurpedia}`}>
          <Link
            data-testid="dashboardLink"
            className={styles.menuLink}
            to="/valoracion/segurpedia"
          >
            <div className={styles.iconWrapper}>
              <IconSegurpedia className={styles.menuIcon} />
            </div>
            <span className={styles.menuText}>Segurpedia</span>
          </Link>
        </li>
        <MenuAdministracion styleRoute={styleRoute} />
        <MenuLogout styleRoute={styleRoute} />
      </ul>
    </nav>
  );
};

export default connect((state: RootState) => ({
  isActive: isAreaValoracion(state) || isAreaExposicionMotivos(state),
  project: selectValoracionProject(state),
  hasRequiredData: selectHasRequiredData(state),
  canViewAnalysis: selectCanViewAnalysis(state),
  canEditOffer: selectCanEditOffer(state),
  isNewOfferSaved: selectInsuredOfferSaved(state),
}))(Menu);
