import {
  CField,
  CGroup,
  CGroupInstance,
  CNode,
  ValidationErrors,
  Value,
  Values,
} from 'ibs-cengine/dist/types/Calculator';
import {
  CalculatorErrors,
  ConfigRETA,
  PersonErrors,
} from '../../types/schemas/schemas';
import { AverageExpenditures } from '../../valoracion/types/averageExpenditure/averageExpenditure';
import { capitalPathByName } from './Cinputs';

export const getValue = (root: CGroup, path: string): any => {
  const path_queue = path.split('.');
  let group_instance: CGroupInstance | null = root.children[0];
  let field: CField | null = null;
  while (path_queue.length && group_instance != null) {
    const name = path_queue.shift();
    if (path_queue.length === 0) {
      //must be a field
      const child: CNode = group_instance.children[name!];
      if (child.nodeType === 'field') {
        field = child as CField;
      }
    } else {
      if (typeof name === 'number') {
        group_instance = group_instance[name];
      } else {
        group_instance = extract(group_instance, [name!]);
      }
    }
  }
  return field ? field.value : null;
};

export function extract(
  group_instance: CGroupInstance,
  path: string[]
): CGroupInstance | null {
  const currentName = path.shift();
  const child = group_instance.children[currentName!];
  if (child.nodeType !== 'group') {
    return null;
  }
  if (child.def.groupType !== 'standard') {
    return null;
  }
  if (path.length) {
    return extract(child.children[0], path);
  } else {
    return child.children[0];
  }
}

export function transformPath(path: string, fixedPath: string = '') {
  return path.replace(/\./g, '.0.') + fixedPath;
}

export function getValueAsString(path: string, values: Values): string {
  const value: Value = values[transformPath(path)];
  if (value === undefined) {
    return '';
  }
  return value.toString();
}

export function getConfigRETA(values: Values): ConfigRETA {
  return {
    cuota_min: getValueAsNumber('.ss.reta_cuota_min', values),
    cuota_max: getValueAsNumber('.ss.reta_cuota_max', values),
    base_min: getValueAsNumber('.ss.reta_base_min', values),
    base_max: getValueAsNumber('.ss.reta_base_max', values),
  };
}

export function getValueAsNumber(
  path: string,
  values: Values,
  fixedPath: string = ''
): number {
  const value: Value = values[transformPath(path) + fixedPath];
  if (value === undefined) {
    return 0;
  }
  return Number(value);
}

export function getValueAsBoolean(path: string, values: Values): boolean {
  const value: Value = values[transformPath(path)];
  if (!value) {
    return false;
  }
  return true;
}

export function getAverageExpediture(values: Values): AverageExpenditures {
  return {
    gastos_mensuales_efectivos: getValueAsNumber('.a.gme', values),
    alimentos_bebidas: {
      INE_porcentaje: getValueAsNumber('.gm.alimentos_bebidas.pct_ine', values),
      INE_importe: getValueAsNumber(
        '.gm.alimentos_bebidas.importe_ine',
        values
      ),
      porcentaje: null,
      importe: null,
    },
    bebidas_tabaco: {
      INE_porcentaje: getValueAsNumber('.gm.bebidas_tabaco.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.bebidas_tabaco.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    vestido_calzado: {
      INE_porcentaje: getValueAsNumber('.gm.vestido_calzado.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.vestido_calzado.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    vivienda: {
      INE_porcentaje: getValueAsNumber('.gm.vivienda.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.vivienda.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    muebles_hogar: {
      INE_porcentaje: getValueAsNumber('.gm.muebles_hogar.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.muebles_hogar.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    sanidad: {
      INE_porcentaje: getValueAsNumber('.gm.sanidad.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.sanidad.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    transporte: {
      INE_porcentaje: getValueAsNumber('.gm.transporte.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.transporte.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    comunicaciones: {
      INE_porcentaje: getValueAsNumber('.gm.comunicaciones.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.comunicaciones.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    ocio_cultura: {
      INE_porcentaje: getValueAsNumber('.gm.ocio_cultura.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.ocio_cultura.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    enseñanza: {
      INE_porcentaje: getValueAsNumber('.gm.enseñanza.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.enseñanza.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    restaurantes_hoteles: {
      INE_porcentaje: getValueAsNumber(
        '.gm.restaurantes_hoteles.pct_ine',
        values
      ),
      INE_importe: getValueAsNumber(
        '.gm.restaurantes_hoteles.importe_ine',
        values
      ),
      porcentaje: null,
      importe: null,
    },
    seguros: {
      auto: {
        INE_porcentaje: getValueAsNumber('.gm.seguros.auto.pct_ine', values),
        INE_importe: getValueAsNumber('.gm.seguros.auto.importe_ine', values),
        porcentaje: null,
        importe: null,
      },
      hogar: {
        INE_porcentaje: getValueAsNumber('.gm.seguros.hogar.pct_ine', values),
        INE_importe: getValueAsNumber('.gm.seguros.hogar.importe_ine', values),
        porcentaje: null,
        importe: null,
      },
      personales: {
        INE_porcentaje: getValueAsNumber(
          '.gm.seguros.personales.pct_ine',
          values
        ),
        INE_importe: getValueAsNumber(
          '.gm.seguros.personales.importe_ine',
          values
        ),
        porcentaje: null,
        importe: null,
      },
    },
    otros: {
      INE_porcentaje: getValueAsNumber('.gm.otros.pct_ine', values),
      INE_importe: getValueAsNumber('.gm.otros.importe_ine', values),
      porcentaje: null,
      importe: null,
    },
    total: {
      INE_porcentaje: getValueAsNumber('.gm.total_pct', values),
      INE_importe: getValueAsNumber('.gm.total', values),
      porcentaje: null,
      importe: null,
    },
  };
}

export function arrangeErrors(errors: ValidationErrors): CalculatorErrors {
  let c_errors: CalculatorErrors = {
    principal: { local: {}, global: {} },
    conyuge: { local: {}, global: {} },
  };
  Object.keys(errors).forEach((path) => {
    const err = errors[path];
    if (isPathConyuge(path)) {
      if (err.type === 'global-error' || err.type === 'global-warning') {
        c_errors.conyuge.global[path] = err;
      } else {
        c_errors.conyuge.local[path] = err;
      }
    } else {
      if (err.type === 'global-error' || err.type === 'global-warning') {
        c_errors.principal.global[path] = err;
      } else {
        c_errors.principal.local[path] = err;
      }
    }
  });
  return c_errors;
}

export function globalErrorsAsArray(
  principal: boolean,
  conyuge: boolean,
  errors: CalculatorErrors
): string[] {
  const strArray: string[] = [];
  if (principal) {
    Object.keys(errors.principal.global).forEach((key) => {
      strArray.push(errors.principal.global[key].message);
    });
  }
  if (conyuge && errors.conyuge) {
    Object.keys(errors.conyuge.global).forEach((key) => {
      strArray.push(errors.conyuge.global[key].message);
    });
  }
  return strArray;
}

function isPathConyuge(path: string) {
  return path.substr(7, 1) === 'c';
}

export function getCapitalError(
  capitalName: string,
  asegurado: string,
  cErrors: CalculatorErrors
): string {
  const who = asegurado === 'conyuge' ? 'c' : 'p';
  if (asegurado === 'conyuge' && !('conyuge' in cErrors)) {
    return '';
  }
  const errors = asegurado === 'conyuge' ? cErrors.conyuge : cErrors.principal;
  const pathName = capitalPathByName(capitalName, who);
  const error = errors.local[pathName];
  const error_sin_u = errors.local[pathName.substring(0, pathName.indexOf("_u", pathName.length-2))];
  let message = '';
  if (error) message = error.message;
  else if (error_sin_u) message = error_sin_u.message;
  return message;
}

export function hasAnyLocalError(errors: CalculatorErrors) {
  return (errors && errors.principal && hasLocalErrors(errors.principal))
    || (errors && errors.conyuge && hasLocalErrors(errors.conyuge));
}

export function hasGlobalErrors(items: PersonErrors) {
  return Object.keys(items.global).some(
    (key) => items.global[key].type === 'global-error'
  );
}
export function hasLocalErrors(items: PersonErrors) {
  return Object.keys(items.local).some(
    (key) => items.local[key].type === 'local-error'
  );
}
export function hasGlobalWarnings(items: PersonErrors) {
  return Object.keys(items.global).some(
    (key) => items.global[key].type === 'global-warning'
  );
}
export function hasLocalWarnings(items: PersonErrors) {
  return Object.keys(items.local).some(
    (key) => items.local[key].type === 'local-warning'
  );
}
export function hasErrors(items: PersonErrors) {
  return hasGlobalErrors(items) || hasLocalErrors(items);
}
export function hasGlobalMessages(items: PersonErrors) {
  return hasGlobalErrors(items) || hasGlobalWarnings(items);
}
