export type ClientAction =
  | 'crear'
  | 'marcar_presentacion'
  | 'marcar_candidato'
  | 'marcar_toma_datos'
  | 'marcar_primera_oferta'
  | 'marcar_oferta_mejorada'
  | 'marcar_oferta_aceptada'
  | 'marcar_posible_comprador'
  | 'desmarcar_posible_comprador'
  | 'finalizar_proyecto'
  | 'guardar';

export enum ClientActionEnum {
  'crear' = 'Crear',
  'marcar_presentacion' = 'Presentación',
  'marcar_candidato' = 'Candidato',
  'marcar_toma_datos' = 'Toma de datos',
  'marcar_primera_oferta' = 'Primera oferta',
  'marcar_oferta_mejorada' = 'Oferta mejorada',
  'marcar_oferta_aceptada' = 'Oferta aceptada',
  'marcar_posible_comprador' = 'Posible comprador',
  'desmarcar_posible_comprador' = 'No posible comprador',
  'finalizar_proyecto' = 'Proyecto finalizado',
  'guardar' = 'Guardado',
}
