import { APIValidationError } from '../../types/api/api';
import { ChangeProjectStateDTO, Project } from '../../types/projects/projects';
import { Result, validatedApiCall } from '../api';

export const changeProjectState = async (
  data: ChangeProjectStateDTO
): Promise<Result<Project, APIValidationError>> => {
  return validatedApiCall<Project>(`/api/proyectos/${data.id}`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  });
};
