import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { selectIsLoggedIn } from '../../../store/auth/selectors';
import { RootState } from '../../../store/store';

interface PublicRouteProps extends RouteProps {
  isAuthenticated?: boolean;
}

const PublicRoute: React.FC<PublicRouteProps> = ({
  component,
  render,
  isAuthenticated = true,
  ...rest
}) => {
  return !isAuthenticated ? (
    <Route {...rest} component={component} render={render} />
  ) : (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location },
          }}
        />
      )}
    />
  );
};

export default connect((state: RootState) => ({
  isAuthenticated: selectIsLoggedIn(state),
}))(PublicRoute);
