"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
const eclog = false;
function findGroupInstance(node, path) {
    switch (node.def.groupType) {
        case 'standard':
            path.pop();
            return node.children[0];
        case 'repeat':
        case 'while':
            const next = path.pop() || '';
            if (!utils_1.isNumber(next)) {
                throw new Error('Formula incorrecta: accediendo a un nodo repetido desde otra rama');
            }
            return node.children[parseInt(next)];
    }
}
function val(instance, path, prop) {
    const child = instance.children[prop];
    if (!child) {
        eclog &&
            console.log('Value not found ' + prop + path.join('.') + ' on ' + instance);
        return '';
    }
    switch (child.nodeType) {
        case 'field':
            return child.value;
        case 'group':
            return new Proxy(child, buildHandler(path));
    }
}
function buildListHandler(path) {
    return {
        get: function (nodes, prop) {
            return nodes.map((node) => val(node, [...path], prop));
        },
    };
}
function buildExtractHandler(path) {
    return {
        get: function (nodes, prop) {
            if (nodes.length && nodes.length > 1) {
                eclog &&
                    console.log('extract trabaja obligatoriamente sobre nodos group standard');
                return [];
            }
            const node = nodes[0];
            if (node.def.nodeType === 'group' && node.def.groupType === 'standard') {
                let childInstances = Object.keys(node.children).map((k) => {
                    const child = node.children[k];
                    if (child.nodeType === 'group' &&
                        child.def.groupType === 'standard') {
                        const childInstance = findGroupInstance(child, [
                            ...path,
                            child.def.name,
                        ]);
                        return childInstance;
                    }
                    return null;
                });
                childInstances = childInstances.filter((x) => x != null && x !== undefined);
                return childInstances.map((childI) => {
                    if (childI && typeof childI.children[prop] !== 'undefined') {
                        return childI.children[prop].value;
                    }
                    return undefined;
                });
            }
            return [];
        },
    };
}
function buildIterationHandler(path) {
    return {
        get: function (node, prop) {
            return val(node, path, prop);
        },
    };
}
function buildHandler(path) {
    return {
        get: function (node, prop) {
            const pathCopy = [...path];
            if (utils_1.isNumber(prop)) {
                pathCopy.unshift(prop);
                const instance = findGroupInstance(node, pathCopy);
                return instance ? new Proxy(instance, buildIterationHandler(path)) : [];
            }
            if (prop === 'list') {
                return node.children.length
                    ? new Proxy(node.children, buildListHandler(pathCopy))
                    : [];
            }
            if (prop === 'extract') {
                return node.children.length
                    ? new Proxy(node.children, buildExtractHandler(pathCopy))
                    : [];
            }
            if (!node.children.length) {
                eclog &&
                    console.log('Undefined value ' + prop + ' in ' + path + ', no childs found');
                return undefined;
            }
            const instance = findGroupInstance(node, pathCopy);
            if (!(prop in instance.children)) {
                eclog && console.log('Undefined value ' + prop + ' in ' + path);
                return undefined;
            }
            const step = pathCopy.pop();
            if (step !== prop) {
                // Empty the path array because we have diverged
                pathCopy.splice(0, pathCopy.length);
            }
            return val(instance, pathCopy, prop);
        },
    };
}
function reversePath(path) {
    const reversedPath = path.split('.').slice(1);
    reversedPath.reverse();
    return reversedPath;
}
function childIteration(reversedPath) {
    for (let entry of reversedPath) {
        const i = parseInt(entry);
        if (!isNaN(i)) {
            return i;
        }
    }
    return undefined;
}
function buildContext(env, path, newValue, currVal) {
    const reversedPath = reversePath(path);
    const i = childIteration(reversedPath);
    return {
        newVal: newValue,
        val: currVal,
        v: new Proxy(env.root, buildHandler(reversedPath)),
        i: i,
        select: utils_1.select(env.tables),
        find: (from, field, condition) => {
            //eslint-disable-next-line
            let match = new Function('tv', 'return ' + condition);
            const found = from.find((tv) => {
                return match(tv);
            });
            if (found && typeof found[field] !== 'undefined') {
                return found[field];
            }
            return undefined;
        },
        findGt: (from, field, condition) => {
            //eslint-disable-next-line
            let match = new Function('tv', 'return ' + condition);
            let greatest;
            from.map((tv) => {
                if (match(tv)) {
                    if (greatest) {
                        //TODO 2 asumo number porque comparando strings los resultados no son buenos si tratamos números, hay que revisar
                        if (Number(greatest[field]) < Number(tv[field])) {
                            greatest = tv;
                        }
                    }
                    else {
                        greatest = tv;
                    }
                }
                return null;
            });
            return typeof greatest !== 'undefined' ? greatest[field] : undefined;
        },
        age: utils_1.age,
        actuarialAge: utils_1.actuarialAge,
        toDays: utils_1.toDays,
        rentaFutura: utils_1.rentaFutura,
        hRound: utils_1.hRound,
        hMoney: utils_1.hMoney,
        hInt: utils_1.hInt,
        t: env.tables,
        myPath: path,
    };
}
exports.default = buildContext;
