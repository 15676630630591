import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as IconSend } from '../../../assets/icon_send.svg';
import renderWhen from '../../../helpers/renderWhen';
import {
  selectSendPdfData,
  selectSendPdfLoading,
  selectSendPdfVisible,
} from '../../../store/offer/selectors';
import { hidePdf, sendPdf } from '../../../store/offer/sendPdfModule';
import { AppDispatch, RootState } from '../../../store/store';
import { Offer } from '../../../types/offers/offers';
import { UUID } from '../../../types/standard';
import Button from '../../Buttons/Button/Button';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';

interface SendOfferProps {
  offer: Offer | null;
  active: boolean;
  loading: boolean;
  closeModal: () => void;
  sendPdf: (id: UUID) => void;
}

const SendOffer: React.FC<SendOfferProps> = ({
  offer,
  active,
  loading,
  closeModal,
  sendPdf,
}) => {
  // 'active' controls if the modal is open or close
  if (!active) return null;
  return (
    <>
      {offer && (
        <Modal closeModal={closeModal}>
          <div className={styles.container}>
            <h1 className={styles.title}>
              <IconSend className={`${styles.icon} ${styles.isIconSend}`} />
              Enviar oferta
            </h1>
            <p className={styles.description}>
              ¿Estás seguro de querer enviar la siguiente oferta al cliente?
            </p>
            <div className={styles.appointmentWrapper}>
              <div className={styles.description}>
                <p>{`Oferta ${offer.human_id}: ${offer.nombre}`}</p>
              </div>
            </div>
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button
                variant="positive"
                onClick={() => sendPdf(offer.id)}
                data-testid="submit_button"
              >
                Enviar
              </Button>
            </ButtonsGroup>
            {loading && <Loading />}
          </div>
        </Modal>
      )}
    </>
  );
};

const ConnectedDeleteAppointment = connect(
  (state: RootState) => ({
    offer: selectSendPdfData(state),
    active: selectSendPdfVisible(state),
    loading: selectSendPdfLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    sendPdf: (payload: UUID) => dispatch(sendPdf(payload)),
    closeModal: () => dispatch(hidePdf()),
  })
)(SendOffer);

export default renderWhen(ConnectedDeleteAppointment, selectSendPdfVisible);
