import {
  APIBadRequestError,
  APIError,
  APIForbiddenError,
  APIServerError,
  APIUnauthorizedError,
  APIUnknownError,
  APIValidationError,
  FetchOptions,
} from '../types/api/api';

export let _token = localStorage.getItem('token') || null;

export function setToken(token: string | null) {
  _token = token;
  if (token === null) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', token);
  }
}

const processOptions = (options?: FetchOptions): FetchOptions => {
  if (!options) {
    return {};
  }
  let opts = {
    ...options,
  };
  // defaultContentType is true, no set 'Content-Type'
  if (
    !opts.defaultContentType &&
    opts.method &&
    opts.method.toUpperCase() !== 'GET'
  ) {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  }
  if (opts.auth && _token) {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${_token}`,
      },
    };
  }
  // remove flag defaultContentType, used when upload files
  delete opts.defaultContentType;
  return opts;
};

const _apiCall = async (input: RequestInfo, init: RequestInit) => {
  const response = await fetch(input, processOptions(init));
  if (response.status === 200 || response.status === 201) {
    return response;
  }
  if (response.status === 500) {
    const data = await response.json();
    const error: APIServerError = {
      errorType: 'server',
      message: data.message || 'Se ha producido un error validando la petición',
    };
    throw error;
  }
  if (response.status === 403) {
    const data = await response.json();
    const error: APIForbiddenError = {
      errorType: 'forbidden',
      message:
        data.message || 'No tienes permisos para realizar esta operación',
    };
    throw error;
  }
  if (response.status === 422) {
    const data = await response.json();
    const error: APIValidationError = {
      errorType: 'validation',
      message:
        data.message ||
        'Algunos de los datos introducidos no son correctos. Revíselos y vuelve a intentarlo.',
      ...{ errors: hasValidationErrors(data) ? data.errors : {} },
    };

    throw error;
  }
  if (response.status === 400) {
    const data = await response.json();
    const error: APIBadRequestError = {
      errorType: 'badRequest',
      message: data.message || 'Petición incorrecta, contacte con soporte.',
    };
    throw error;
  }

  if (response.status === 401) {
    const data = await response.json();
    const error: APIUnauthorizedError = {
      errorType: 'unauthorized',
      message:
        data.message || 'Datos de sesión incorrectos, inicie sesión de nuevo',
    };
    throw error;
  }

  // This is the fallback message for when the response is not json or
  // doesn't have a message on its own.
  let message = `Error HTTP ${response.status} - ${response.statusText}`;
  try {
    const data = await response.json();
    if (typeof data.message === 'string') {
      message = data.message;
    }
  } catch (err) {}
  const error: APIUnknownError = {
    errorType: 'unknown',
    message,
  };
  throw error;
};

export type Result<Ok, Error> =
  | {
      type: 'ok';
      value: Ok;
    }
  | {
      type: 'validation-error';
      value: Error;
    };

export const apiCall = async <T>(
  input: RequestInfo,
  init: RequestInit & { auth?: boolean }
): Promise<T> => {
  const response = await _apiCall(input, init);
  return response.json();
};

export const apiCallBlob = async (
  input: RequestInfo,
  init: RequestInit & { auth?: boolean }
): Promise<Blob> => {
  const response = await _apiCall(input, init);
  return response.blob();
};

export const validatedApiCall = async <T>(
  input: RequestInfo,
  init: RequestInit & { auth?: boolean }
): Promise<Result<T, APIValidationError>> => {
  try {
    const response = await apiCall<T>(input, init);
    return {
      type: 'ok',
      value: response,
    };
  } catch (e) {
    if (isAPIValidationError(e)) {
      return {
        type: 'validation-error',
        value: e,
      };
    }
    throw e;
  }
};

export function isApiError(param: any): param is APIError {
  return (
    param !== null &&
    typeof param === 'object' &&
    typeof param.errorType === 'string' &&
    typeof param.message === 'string'
  );
}

export function isAPIValidationError(param: any): param is APIValidationError {
  return isApiError(param) && param.errorType === 'validation';
}

export function isAPIUnauthorizedError(
  param: any
): param is APIUnauthorizedError {
  return isApiError(param) && param.errorType === 'unauthorized';
}

export function isAPIForbiddenError(param: any): param is APIUnauthorizedError {
  return isApiError(param) && param.errorType === 'forbidden';
}

export function isAPIServerError(param: any): param is APIServerError {
  return isApiError(param) && param.errorType === 'server';
}

export function isAPIBadRequestError(param: any): param is APIServerError {
  return isApiError(param) && param.errorType === 'badRequest';
}

function hasValidationErrors(
  data: any
): data is { errors: Record<string, string[]> } {
  // data must be an object
  if (data === null || typeof data !== 'object') return false;

  // data.errors must be an object too
  if (typeof data.errors !== 'object') return false;

  // all data.errors[k] must be arrays
  // and all data.errors[k][s] must be strings
  if (
    Object.keys(data.errors).some(
      (k) =>
        !Array.isArray(data.errors[k]) ||
        data.errors[k].some((e: any) => typeof e !== 'string')
    )
  ) {
    return false;
  }

  // Then we consider this to be an error messages array
  return true;
}
