import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import iconEmail from '../../assets/icon_email.svg';
import iconPassword from '../../assets/icon_password.svg';
import Button from '../../components/Buttons/Button/Button';
import ValidatedInput from '../../components/Form/ValidatedInput/ValidatedInput';
import Loading from '../../components/Loading/Loading';
import useOurForm from '../../hooks/useOurForm';
import useValidatorAPI from '../../hooks/useValidatorAPI';
import { login } from '../../store/auth/authSlice';
import {
  selectAuthErrors,
  selectAuthLoading,
} from '../../store/auth/selectors';
import { AppDispatch, RootState } from '../../store/store';
import LoginHeading from '../../themes/active/components/LoginHeading/LoginHeading';
import LoginLeftPanel from '../../themes/active/components/LoginLeftPanel/LoginLeftPanel';
import { APIError } from '../../types/api/api';
import { LoginDTO } from '../../types/auth/auth';
import styles from './login.module.scss';

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required('Indica tu e-mail')
    .email('Introduce un e-mail válido'),
  password: yup.string().required('Introduce tu contraseña'),
});

interface LoginProps {
  login: (payload: LoginDTO) => void;
  serverErrors: APIError | null;
  loading: boolean;
}

const Login: React.FC<LoginProps> = ({ login, serverErrors, loading }) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<LoginDTO>({
      mode: 'onSubmit',
      validationSchema: loginSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  return (
    <main className={styles.root}>
      <LoginLeftPanel />
      <div className={styles.wrapper}>
        <form onSubmit={handleSubmit(login)}>
          <LoginHeading />
          <div className={styles.inputWrapper}>
            <img
              src={iconEmail}
              className={`${styles.iconInput} ${styles.isMail}`}
              alt="icon email"
            />
            <ValidatedInput
              variant="login"
              errors={errors}
              schema={loginSchema}
              label=""
              input={{ name: 'email', ref: register }}
            />
          </div>
          <div className={styles.inputWrapper}>
            <img
              src={iconPassword}
              className={`${styles.iconInput} ${styles.isPassword}`}
              alt="icon password"
            />
            <ValidatedInput
              variant="login"
              errors={errors}
              schema={loginSchema}
              label=""
              input={{ name: 'password', type: 'password', ref: register }}
            />
          </div>
          {/* TODO: Not implemented yet
          <Link className={styles.rememberLink} to="/">
            Recordar mi cuenta
          </Link>
          */}

          {/* Client-side errors */}
          {errors.email && (
            <p className={styles.errorText}>{errors.email.message}</p>
          )}
          {errors.password && (
            <p className={styles.errorText}>{errors.password.message}</p>
          )}

          {/* Server-side global error */}
          {globalError && <p className={styles.errorText}>{globalError}</p>}
          <Button variant="positive">Iniciar Sesión</Button>
          {loading && <Loading />}
        </form>
      </div>
    </main>
  );
};

export default connect(
  (state: RootState) => ({
    serverErrors: selectAuthErrors(state),
    loading: selectAuthLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    login: (payload: LoginDTO) => {
      dispatch(login(payload));
    },
  })
)(Login);
