import { Values } from 'ibs-cengine/dist/types/Calculator';
import { Discount } from '../../../types/offers/offers';
import { getValueAsNumber } from '../util';

export function getDescuento(values: Values): Discount {
  return {
    comercial: getValueAsNumber('.o.p.w12.resumen.pct_desc_com', values),
    tecnico: getValueAsNumber('.o.p.w12.f.cc.pct_desc_tec', values),
    colectivo: getValueAsNumber('.o.p.w12.resumen.pct_desc_col', values),
  };
}
