import { delay } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { put, select, take, takeLatest } from 'redux-saga/effects';
import authSlice from '../auth/authSlice';
import { selectIsLoggedIn } from '../auth/selectors';
import alertsModule from './alertsModule';
import newAlertsModule from './newAlertsModule';

function* getAlerts(): Generator<any, void, any> {
  yield put(
    alertsModule.slice.actions.start({
      page: 1,
      size: 5,
      read: '',
      sort: '',
      order: '',
    })
  );
}

/**
 * Refreses the list of alerts every minute.
 *
 * The correct way to do this would be for the API to notify us
 * when there is a change and then refresh, but we are not there
 * yet.
 */
function* getAlertsEveryMinut() {
  while (true) {
    try {
      if (yield select(selectIsLoggedIn)) {
        yield put(
          newAlertsModule.slice.actions.start({
            page: 1,
            size: 5,
            read: 0,
            sort: '',
            order: '',
          })
        );
      }
    } catch (err) {}
    yield delay(60 * 1000);
  }
}

// retrieve alerts when the page is reloaded as long as the user remains logged in
function* reloadAlerts(): Generator<any, void, any> {
  yield take(LOCATION_CHANGE);
  if (selectIsLoggedIn(yield select())) {
    yield getAlerts();
    yield getAlertsEveryMinut();
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(authSlice.actions.loginOk.type, getAlerts),
  takeLatest<PayloadAction<never>>(
    authSlice.actions.loginOk.type,
    getAlertsEveryMinut
  ),
  getAlertsEveryMinut(),
  reloadAlerts(),
];

export default sagas;
