import { getAppointments as apiMethod } from '../../api/appointments/getAppointments';
import unique from '../../helpers/unique';
import { Appointment } from '../../types/appointments/appointments';
import createApiModule from '../createApiModule';

const scheduleModule = createApiModule({
  name: 'schedule',
  initialData: [] as Appointment[],
  apiMethod,
  parse: (appointments, currentAppointments) =>
    unique([...currentAppointments, ...appointments.data], 'id').sort(
      (a, b) =>
        new Date(a.fecha_hora).getTime() - new Date(b.fecha_hora).getTime()
    ),
  onSuccess: () => {},
  onError: () => ({
    message: 'No se han podido cargar las citas.',
    severity: 'error',
  }),
});

export const {
  start: getAppointments,
  success: getAppointmentsOk,
  error: getAppointmentsKo,
  reset: resetAppointments,
  refresh: refreshAppointments,
} = scheduleModule.slice.actions;

export default scheduleModule;
