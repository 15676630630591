import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { Poblacion } from '../../types/address/address';

export type PoblacionState = {
  loading: boolean;
  errors: APIError | null;
  poblaciones: Poblacion[];
};

const initialState: PoblacionState = {
  loading: false,
  errors: null,
  poblaciones: [],
};

const poblacionesSlice = createSlice({
  name: 'poblacion',
  initialState,
  reducers: {
    loadPoblaciones(state, _action: PayloadAction<string>) {
      state.loading = true;
      state.errors = null;
      state.poblaciones = [];
    },
    loadPoblacionesOk(state, action: PayloadAction<Poblacion[]>) {
      state.loading = false;
      state.errors = null;
      state.poblaciones = action.payload;
    },
    loadPoblacionesKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.poblaciones = [];
    },
    resetPoblaciones() {
      return { ...initialState };
    },
  },
});

export const {
  loadPoblaciones,
  loadPoblacionesOk,
  loadPoblacionesKo,
  resetPoblaciones,
} = poblacionesSlice.actions;

export default poblacionesSlice;
