import { APIError } from '../../types/api/api';
import { Permission } from '../../types/auth/permissions';
import { Role } from '../../types/auth/roles';
import { Project } from '../../types/projects/projects';
import { RootState } from '../store';

export const selectAuthSlice = (state: RootState) => state.auth;

export const selectAuthUser = (state: RootState) =>
  selectAuthSlice(state)?.user || null;

export const selectAuthData = (state: RootState) =>
  selectAuthUser(state)?.data || null;

export const selectAuthAvatar = (state: RootState) =>
  selectAuthUser(state)?.data.imagen_perfil || null;

export const selectIsLoggedIn = (state: RootState) => !!selectAuthUser(state);

export const selectUserRole = (state: RootState) =>
  selectAuthData(state)?.role_id || null;

export const selectUserArea = (state: RootState) =>
  selectAuthData(state)?.area_id || null;

export const selectUserPermissions = (state: RootState): Permission[] =>
  selectAuthUser(state)?.permissions || [];

export const selectHasSomeRole = (state: RootState, roles: Role[]) => {
  const role = selectUserRole(state);
  return role ? roles.includes(role) : false;
};

export const selectHasAnyPermission = (
  state: RootState,
  permissions: Permission[]
) => {
  const userPerms = selectUserPermissions(state);
  return permissions.some((p) => userPerms.includes(p));
};

export const selectAuthLoading = (state: RootState) =>
  selectAuthSlice(state)?.loading;

export const selectAuthErrors = (state: RootState) =>
  selectAuthSlice(state)?.errors;

export const selectServerErrors = (state: RootState): APIError | null =>
  selectAuthSlice(state)?.errors;

export const getOwnName = (state: RootState, id: string) => {
  if (selectAuthData(state)?.id === id) {
    const user = selectAuthData(state);
    return user ? `${user.nombre} ${user.apellido1} ${user.apellido2}` : null;
  } else {
    return null;
  }
};
export const selectAuthId = (state: RootState) =>
  selectAuthSlice(state)?.user?.data.id;

export const selectUserOwnsProject = (state: RootState, project?: Project) => {
  return (
    !!project &&
    !!project.cliente &&
    selectAuthId(state) === project.cliente.user_id
  );
};

export const selectUpdatedAt = (state: RootState): Date | null =>
  selectAuthSlice(state)?.updated_at;
