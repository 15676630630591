import { registerUser } from '../../api/users/registerUser';
import createApiModule from '../createApiModule';

const registerUserModule = createApiModule({
  name: 'registerUser',
  apiMethod: registerUser,
  onSuccess: (user) => ({
    message: `El usuario ${user.nombre} ${user.apellido1} ${user.apellido2} se ha creado correctamente.`,
    path: '/usuarios',
  }),
  onError: () => ({
    message: 'Error creando el usuario',
  }),
  onValidationError: () => ({
    message: 'Error creando el usuario',
  }),
});

export const {
  start: register,
  success: registerOk,
  error: registerKo,
} = registerUserModule.slice.actions;

export default registerUserModule;
