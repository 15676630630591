import { CommentsDTO } from '../../types/clients/comments';
import { UUID } from '../../types/standard';
import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export const getClientComments = async (
  id: UUID
): Promise<Result<CommentsDTO, APIValidationError>> => {
  return validatedApiCall<CommentsDTO>(
    `/api/clientes/${id}/comments?page=1&size=1000`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
