import { markAlertsAsRead } from '../../api/alerts/markAlertsAsRead';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { listAlerts } from './alertsModule';

const readAlertsModule = createApiModule({
  name: 'readAlerts',
  apiMethod: markAlertsAsRead,
  parse: (response) => response.data,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Las alertas se han marcado como leídas correctamente',
    }),
    listAlerts({ page: 1, size: 5, read: '', sort: '', order: '' }),
  ],
  onError: () => ({
    message: 'No hemos podido marcar como leídas las alertas',
  }),
  onValidationError: () => ({
    message: 'No hemos podido marcar como leídas las alertas',
  }),
});

export const {
  start: readAlerts,
  success: readAlertsOk,
  error: readAlertsKo,
} = readAlertsModule.slice.actions;

export const { sagas } = readAlertsModule;

export default readAlertsModule;
