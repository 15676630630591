import React from 'react';

interface MenuAdministracionProps {
  styleRoute: (route: string) => string;
}

const MenuAdministracion: React.FC<MenuAdministracionProps> = ({
  styleRoute,
}) => null;

export default MenuAdministracion;
