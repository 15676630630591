import { getProjectById } from '../../api/projects/getProjectById';
import createApiModule from '../createApiModule';

const projectModule = createApiModule({
  name: 'getProject',
  apiMethod: getProjectById,
  initialData: null,
  parse: (project) => project,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar el proyecto',
    path: '/proyectos',
  }),
  onValidationError: () => ({
    message: 'No hemos podido cargar el proyecto',
    path: '/proyectos',
  }),
});

export const {
  start: getProject,
  success: getProjectOk,
  error: getProjectKo,
  reset: resetProject,
} = projectModule.slice.actions;

export default projectModule;
