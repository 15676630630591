import { newClientComment } from '../../api/clients/newComment';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { getComments } from './commentsModule';

const newCommentModule = createApiModule({
  name: 'newClientComment',
  apiMethod: newClientComment,
  onSuccess: (comment) => {
    return [
      showSnackBar({
        message: 'Comentario enviado correctamente',
        severity: 'success',
        path: `/clientes/${comment.cliente_id}/comentarios`,
      }),
      getComments(comment.cliente_id),
    ];
  },
  onError: () => ({
    message: 'No hemos podido crear el comentario',
  }),
  onValidationError: () => ({
    message: 'No hemos podido crear el comentario',
  }),
});

export const {
  start: newComment,
  success: newCommentOk,
  error: newCommentKo,
} = newCommentModule.slice.actions;

export default newCommentModule;
