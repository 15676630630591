import { getStats } from '../../api/statistics/getStats';
import { StatsData } from '../../types/statistics/statistics';
import createApiModule from '../createApiModule';

const getStatisticsModule = createApiModule({
  name: 'stats',
  apiMethod: getStats,
  initialData: {} as StatsData,
  parse: (stats) => stats.data,
  onSuccess: () => {},
  onError: () => ({
    message: 'No hemos podido cargar las estadisticas.',
  }),
});

export const {
  start: getStatistics,
  success: getStatisticsOk,
  error: getStatisticsKo,
  reset: resetStatistics,
} = getStatisticsModule.slice.actions;

export default getStatisticsModule;
