import { Values } from 'ibs-cengine/dist/types/Calculator';
import {
  Guarantee,
  MaybeDetalleGuaranteeLine,
} from '../../../types/offers/offers';
import { getValueAsBoolean, getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaFallecimiento(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.f.cc.aplica', values)) {
    return null;
  }

  const garantia: Guarantee = {
    garantia: 'Fallecimento cualquier causa',
    categoria: 'fallecimiento',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.f.cc.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.f.cc.prima', values),
    capital: getValueAsNumber(
      '.a.' + who + '.e.n.f.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.f.cc.ci', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.f.cc.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.f.cc.aplicar', values),
    editable: true,
    obligatoria: true,
  };
  garantia.lineas.push({
    concepto: 'Capital fijo',
    codigo_linea: '01',
    tipo: 'capital',
    capital: getValueAsNumber(
      '.o.' + who + '.w12.f.cc.cfi.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.f.cc.cfi.ci', values)
    ),
    detalle: getCapitalDetails(who, values),
    orden: 1,
  });
  if (['C', 'P'].indexOf(getValueAsString('.a.estado_civil', values)) !== -1) {
    garantia.lineas.push({
      concepto: 'Viudedad',
      bookPath: 'viudedad',
      codigo_linea: '02',
      tipo: 'renta',
      renta: getValueAsNumber(
        '.a.' + who + '.e.n.f.cc.v.renta_recomendada',
        values
      ),
      renta_manual: getValueAsNumber('.o.' + who + '.w12.f.cc.v.rm', values),
      duracion: getValueAsNumber(
        '.a.' + who + '.e.n.f.cc.v.duracion_recomendada',
        values
      ),
      duracion_manual: getValueAsNumber(
        '.o.' + who + '.w12.f.cc.v.duracion',
        values
      ),
      capital: getValueAsNumber(
        '.a.' + who + '.e.n.f.cc.v.capital_recomendado',
        values
      ),
      capital_manual: Math.round(
        getValueAsNumber('.o.' + who + '.w12.f.cc.v.ci', values)
      ),
      orden: 2,
      detalle: {},
      aplicar: getValueAsBoolean('.o.' + who + '.w12.f.cc.v.aplicar', values),
      aplicable: getValueAsBoolean(
        '.o.' + who + '.w12.f.cc.v.aplicable',
        values
      ),
    });
  }
  if (getValueAsNumber('.a.n_hijos', values) > 0) {
    garantia.lineas.push({
      concepto: 'Orfandad',
      bookPath: 'orfandad',
      codigo_linea: '03',
      tipo: 'renta',
      renta: getValueAsNumber(
        '.a.' + who + '.e.n.f.cc.o.renta_recomendada',
        values
      ),
      renta_manual: getValueAsNumber('.o.' + who + '.w12.f.cc.o.rm', values),
      duracion: getValueAsNumber(
        '.a.' + who + '.e.n.f.cc.o.duracion_recomendada',
        values
      ),
      duracion_manual: getValueAsNumber(
        '.o.' + who + '.w12.f.cc.o.anos',
        values
      ),
      capital: getValueAsNumber('.a.' + who + '.e.n.f.cc.o.ci', values),
      capital_manual: Math.round(
        getValueAsNumber('.o.' + who + '.w12.f.cc.o.ci', values)
      ),
      orden: 3,
      detalle: {},
      aplicar: getValueAsBoolean('.o.' + who + '.w12.f.cc.o.aplicar', values),
      aplicable: getValueAsBoolean(
        '.o.' + who + '.w12.f.cc.o.aplicable',
        values
      ),
    });
  }
  return garantia;
}

export function getGarantiaAccidente(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.f.a.aplica', values)) {
    return null;
  }

  return {
    garantia: 'Fallecimiento por accidente',
    categoria: 'fallecimiento',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.f.a.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.f.a.prima', values),
    capital: getValueAsNumber(
      '.o.' + who + '.w12.f.a.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.f.a.cf', values)
    ),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.f.a.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.f.a.aplicar', values),
    editable: true,
    obligatoria: false,
  };
}

export function getGarantiaAccidenteCirculacion(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.f.ac.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Fallecimiento por accidente de circulación',
    categoria: 'fallecimiento',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.f.ac.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.f.ac.prima', values),
    capital: getValueAsNumber(
      '.o.' + who + '.w12.f.ac.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.f.ac.cf', values)
    ),

    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.f.ac.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.f.ac.aplicar', values),
    editable: true,
    obligatoria: false,
  };
}

export function getGarantiaFallecimientoConjunto(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  if (!getValueAsBoolean('.o.' + who + '.w12.f.con.aplica', values)) {
    return null;
  }
  return {
    garantia: 'Fallecimiento ambos cónyuges en accidente de circulación',
    categoria: 'fallecimiento',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.f.con.codigo', values),
    orden: order,
    capital: getValueAsNumber(
      '.o.' + who + '.w12.f.con.capital_recomendado',
      values
    ),
    capital_manual: Math.round(
      getValueAsNumber('.o.' + who + '.w12.f.con.cf', values)
    ),
    importe: getValueAsNumber('.o.' + who + '.w12.f.con.prima', values),
    lineas: [],
    aplicable: getValueAsBoolean('.o.' + who + '.w12.f.con.aplica', values),
    aplicar: getValueAsBoolean('.o.' + who + '.w12.f.con.aplicar', values),
    editable: false,
    obligatoria: false,
  };
}

function getCapitalDetails(
  who: string,
  values: Values
): MaybeDetalleGuaranteeLine | null {
  let detalleCapitalFijo: MaybeDetalleGuaranteeLine = {
    'Complemento por viudedad': null,
    'Capital fijo por cargas': null
  };
  const capitalFijoMinimo = getValueAsNumber(
    `.a.${who}.e.n.f.cc.cfi.rec_min`,
    values
  );
  const capitalRecomendadoViudedad = getValueAsNumber(
    `.a.${who}.e.n.f.cc.cfi.cv`,
    values
  );
  const capitalRecomendadoCargas = getValueAsNumber(
    `.a.${who}.e.n.f.cc.cfi.coc`,
    values
  );
  if (
    capitalRecomendadoCargas + capitalRecomendadoViudedad >
    capitalFijoMinimo
  ) {
    if (capitalRecomendadoViudedad > 0) {
      detalleCapitalFijo['Complemento por viudedad'] =
        capitalRecomendadoViudedad;
    }
    if (capitalRecomendadoCargas > 0) {
      detalleCapitalFijo['Capital fijo por cargas'] = capitalRecomendadoCargas;
    }
  }
  return detalleCapitalFijo;
}
