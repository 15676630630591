import { isAfter } from 'date-fns';
import { Alert } from '../../types/alerts/alerts';
import { RootState } from '../store';

export const selectAlerts = (state: RootState) =>
  state.alerts && state.alerts.data.data;

export const selectNewAlerts = (state: RootState) =>
  state.newAlerts && state.newAlerts.data.data;

export const selectAlertsMeta = (state: RootState) => state.alerts.data.meta;

export const selectAlertsLoading = (state: RootState) => state.alerts.loading;

export const selecthasUnreadAlerts = (state: RootState) => {
  return selectNewAlerts(state) && selectNewAlerts(state).length > 0;
};

export const selectHasNewAlerts = (state: RootState) => {
  const getLastNoReadAlertDate = (alerts: Alert[]) =>
    alerts && alerts.filter((alert) => !alert.read).shift()?.created_at;

  const noReadLastAlertDate = getLastNoReadAlertDate(selectAlerts(state));
  const noReadLastNewAlertDate = getLastNoReadAlertDate(selectNewAlerts(state));

  return noReadLastAlertDate && noReadLastNewAlertDate
    ? isAfter(new Date(noReadLastNewAlertDate), new Date(noReadLastAlertDate))
    : false;
};
