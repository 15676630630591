import { RootState } from '../../../store';
import { initialState } from './passwordRestrictionSlice';

export const selectPasswordRestriction = (state: RootState) => {
  return state?.authRestrictions?.password || initialState;
};

export const selectPasswordRestrictionErrors = (state: RootState) => {
  return selectPasswordRestriction(state).errors;
};

export const selectPasswordRestrictionLoading = (state: RootState) => {
  return selectPasswordRestriction(state).loading;
};

export const selectPasswordRestrictionRestricted = (state: RootState) => {
  return selectPasswordRestriction(state).restricted;
};
