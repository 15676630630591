import { APIValidationError } from '../../types/api/api';
import { Result, validatedApiCall } from '../api';
import { UUID } from '../../types/standard';

export const removeAppointment = async (
  id: UUID
): Promise<Result<void, APIValidationError>> => {
  return validatedApiCall<void>(`/api/citas/${id}`, {
    method: 'DELETE',
    auth: true,
  });
};
