import { RootState } from '../store';

export const selectChangeClientState = (state: RootState) =>
  state.changeClientState;

export const selectChangeClientStateErrors = (state: RootState) =>
  selectChangeClientState(state).errors;

export const selectChangeClientStateLoading = (state: RootState) =>
  selectChangeClientState(state).loading;

export const selectChangeClientStateActive = (state: RootState) =>
  selectChangeClientState(state).active;

export const selectChangeClientStateClient = (state: RootState) =>
  selectChangeClientState(state).client;
