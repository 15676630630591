import React from 'react';
import styles from './td.module.scss';
import { CellVariant } from '../types';

interface TdProps {
  children: React.ReactNode;
  hasEllipsis?: boolean;
  variant?: CellVariant;
}

const tableStyle = (variant: CellVariant): string => {
  switch (variant) {
    case 'bigWithBorder':
      return styles.isBigWithBorder;
    case 'noWrap':
      return styles.noWrap;
    case 'icon':
      return styles.icon;
    case 'bold':
      return styles.isBold;
    case 'notRead':
      return styles.isUnread;
    case 'default':
      return '';
  }
};

const Td: React.FC<TdProps> = ({
  children,
  hasEllipsis,
  variant = 'default',
}) => (
  <td
    className={`${styles.td} ${
      hasEllipsis ? styles.hasEllipsis : ''
    }  ${tableStyle(variant)}`}
  >
    {children}
  </td>
);

export default Td;
