import { format } from 'date-fns';

export const formatTime = (date: Date | string) => {
  try {
    const d = date instanceof Date ? date : new Date(date);
    return `${format(d, 'HH.mm')} h`;
  } catch (e) {
    return '-';
  }
};
