import React, { ReactElement } from 'react';
import {
  FieldError,
  FieldErrors,
  FieldValues,
} from 'react-hook-form/dist/types';
import { ObjectSchema } from 'yup';
import useIsRequired from '../../../hooks/useIsRequired';
import InputField, { InputFieldProps } from '../InputField/InputField';
import styles from '../InputField/inputField.module.scss';

export interface ValidatedTextInput<T extends FieldValues>
  extends InputFieldProps {
  input: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    name: keyof T;
  };
  errors: FieldErrors<T>;
  schema: ObjectSchema<T | undefined>;
  label: string | ReactElement;
  required? : boolean;
}

const ValidatedInput = <T extends FieldValues>({
  errors,
  schema,
  label,
  input,
  required,
  ...other
}: ValidatedTextInput<T>): ReactElement => {
  const fname = input.name as string;
  const error = errors && (errors[input.name] as FieldError);

  const useRequired = useIsRequired(fname, schema);

  return (
    <InputField
      input={input}
      error={error}
      // We change the label directly but we keep the option of passing the required prop
      label={
        label && (useRequired || required) ? (
          <React.Fragment>
            {label}
            <span className={styles.asterisk}>{'\u00a0*'}</span>
          </React.Fragment>
        ) : (
          label
        )
      }
      {...other}
    />
  );
};

export default ValidatedInput;
