import { updateClient } from '../../api/clients/updateClient';
import createApiModule from '../createApiModule';
import snackBarSlice from '../snackBar/snackBarSlice';
import { getClientOk } from './clientModule';

const editClientModule = createApiModule({
  name: 'editClient',
  apiMethod: updateClient,
  onSuccess: (client) => [
    snackBarSlice.actions.showSnackBar({
      message: 'Cliente actualizado correctamente.',
      path: '/clientes',
      severity: 'success',
    }),
    getClientOk(client),
  ],
  onError: () => ({
    message: 'Error al actualizar el cliente',
  }),
  onValidationError: () => ({
    message: 'Error al actualizar el cliente',
  }),
});

export const {
  start: editClient,
  success: editClientKo,
  error: editClientOk,
} = editClientModule.slice.actions;

export default editClientModule;
