import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  changeResponsable,
  hideChangeResponsable,
} from '../../../store/changeResponsable/changeResponsableSlice';
import {
  selectChangeResponsableActive,
  selectChangeResponsableErrors,
  selectChangeResponsableLoading,
  selectChangeResponsableUser,
} from '../../../store/changeResponsable/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { selectUsersByRole } from '../../../store/users/selectors';
import { ChangeResponsableDTO } from '../../../types/actions/actions';
import { APIError } from '../../../types/api/api';
import { User } from '../../../types/users/users';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';
import Button from '../../Buttons/Button/Button';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';

interface ChangeResponsableProps {
  selectedUser: User | null;
  responsables: User[];
  serverErrors: APIError | null;
  loading: boolean;
  changeResponsable: ({
    user_id,
    responsable_id,
  }: ChangeResponsableDTO) => void;
  closeModal: () => void;
}

const changeResponsableSchema = yup.object().shape({
  responsable_id: yup.string(),
});

const ChangeResponsable: React.FC<ChangeResponsableProps> = ({
  selectedUser,
  responsables,
  changeResponsable,
  serverErrors,
  loading,
  closeModal,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeResponsableSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  const onSubmit = (data: ChangeResponsableDTO) => {
    if (selectedUser) {
      data.user_id = selectedUser.id;
    }
    changeResponsable(data);
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Cambiar responsable
        </h1>
        <p className={styles.description}>
          Se va a realizar un cambio de responsable.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          <ValidatedSelect
            schema={changeResponsableSchema}
            errors={errors}
            label=""
            select={{
              name: 'responsable_id',
              ref: register,
            }}
            placeholder="Selecciona responsable"
          >
            {selectedUser && responsables
              ? responsables
                  .filter((resp) => resp.id !== selectedUser.responsable_id)
                  .map((resp) => (
                    <option key={resp.id} value={resp.id}>
                      {`${resp.nombre} ${resp.apellido1} ${resp.apellido2}`}
                    </option>
                  ))
              : null}
          </ValidatedSelect>
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeResponsable = connect(
  (state: RootState) => ({
    responsables: selectUsersByRole(state, 'gestor_area'),
    selectedUser: selectChangeResponsableUser(state),
    serverErrors: selectChangeResponsableErrors(state),
    loading: selectChangeResponsableLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changeResponsable: (payload: ChangeResponsableDTO) => {
      dispatch(changeResponsable(payload));
    },
    closeModal: () => {
      return dispatch(hideChangeResponsable());
    },
  })
)(ChangeResponsable);

export default renderWhen(
  ConnectedChangeResponsable,
  selectChangeResponsableActive
);
