import { useEffect, useRef, useState } from 'react';
import {
  FieldValues,
  FormStateProxy,
  ManualFieldError,
} from 'react-hook-form/dist/types';
import {
  isAPIForbiddenError,
  isAPIServerError,
  isAPIUnauthorizedError,
  isAPIValidationError,
  isAPIBadRequestError,
} from '../api/api';
import { APIError } from '../types/api/api';

function useValidatorAPI<FormValues extends FieldValues>(
  validationErrors: APIError | null,
  setErrors: (errors: ManualFieldError<FormValues>[]) => void,
  formState: FormStateProxy<FormValues>
): [string | null, React.Dispatch<React.SetStateAction<null | string>>] {
  const [globalError, setGlobalError] = useState(null as null | string);
  const lastError = useRef([null] as [null] | APIError);
  useEffect(() => {
    if (validationErrors !== lastError.current) {
      if (isAPIValidationError(validationErrors)) {
        setErrors(
          Object.keys(validationErrors.errors).map(
            (error) =>
              ({
                name: error,
                type: '',
                message: validationErrors.errors[error][0],
              } as ManualFieldError<FormValues>)
          )
        );
      }
    }
    if (
      isAPIUnauthorizedError(validationErrors) ||
      isAPIServerError(validationErrors)
    ) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIForbiddenError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIServerError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if (isAPIBadRequestError(validationErrors)) {
      setGlobalError(validationErrors.message);
    }
    if ((formState.isSubmitting && globalError) || !validationErrors) {
      setGlobalError(null);
    }
    lastError.current = validationErrors || [null];
  }, [formState.isSubmitting, globalError, setErrors, validationErrors]);

  return [globalError, setGlobalError];
}

export default useValidatorAPI;
