import { APIValidationError } from '../../types/api/api';
import {
  AppointmentsDTO,
  GetAppointmentsDTO,
} from '../../types/appointments/appointments';
import { Result, validatedApiCall } from '../api';
import toQueryParams from '../../utils/toQueryParams';

export const getAppointments = async (
  data: GetAppointmentsDTO
): Promise<Result<AppointmentsDTO, APIValidationError>> => {
  return validatedApiCall<AppointmentsDTO>(
    `/api/citas?${toQueryParams(data as any)}`,
    {
      method: 'GET',
      auth: true,
    }
  );
};
