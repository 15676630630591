export type EstadoProyecto =
  | 'en_creacion'
  | 'activo'
  | 'ofertas_aceptadas'
  | 'ofertas_descartadas'
  | 'solicitado'
  | 'poliza_rechazada'
  | 'polizas_aceptadas'
  | 'polizas_firmadas'
  | 'finalizado'
  | 'cancelado';

export enum EstadoProyectoEnum {
  'en_creacion' = 'En creación',
  'activo' = 'Activo',
  'ofertas_aceptadas' = 'Ofertas aceptadas',
  'ofertas_descartadas' = 'Ofertas descartadas',
  'solicitado' = 'Solicitado',
  'poliza_rechazada' = 'Póliza rechazada',
  'polizas_aceptadas' = 'Pólizas aceptadas',
  'polizas_firmadas' = 'Pólizas firmadas',
  'finalizado' = 'Finalizado',
  'cancelado' = 'Cancelado',
}
