import { ChangeClientStateDTO } from '../../types/actions/actions';
import { APIValidationError } from '../../types/api/api';
import { Client } from '../../types/clients/clients';
import { Result, validatedApiCall } from '../api';

export const changeClientState = async (
  data: ChangeClientStateDTO
): Promise<Result<Client, APIValidationError>> => {
  const { client_id, action } = data;

  return validatedApiCall<Client>(`/api/clientes/${client_id}/acciones`, {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ action: action }),
  });
};
