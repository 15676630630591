import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { sendPdfByEmail } from '../../api/offers/sendPdfByEmail';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Offer } from '../../types/offers/offers';

export interface SendPdfState extends GenericState<unknown> {
  visible: boolean;
  data: Offer | null;
}
const initialState: SendPdfState = {
  errors: null,
  loading: false,
  visible: false,
  data: null,
};

const sendPdfModule = createApiModule({
  name: 'sendPdf',
  apiMethod: sendPdfByEmail,
  initialState,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'Oferta enviada correctamente',
    }),
  ],
  onError: () => ({
    message: 'No hemos podido enviar la oferta',
  }),
  onValidationError: () => {},
  reducers: {
    hide(state) {
      state.visible = false;
    },
    show(state, offer: PayloadAction<Offer>) {
      state.data = offer.payload;
      state.visible = true;
    },
  },
});

export const {
  start: sendPdf,
  success: sendPdfOk,
  error: sendPdfKo,
  show: showPdf,
  hide: hidePdf,
} = sendPdfModule.slice.actions;

function* hideSaga() {
  yield put(hidePdf());
}
sendPdfModule.sagas = [
  takeLatest<PayloadAction<unknown>>(sendPdfOk.type, hideSaga),
  ...sendPdfModule.sagas,
];

export default sendPdfModule;
