import React from 'react';
import styles from './loginHeading.module.scss';
import logoHelvetia from '../../assets/logo.svg';

interface LoginHeadingProps {
  showWelcome?: boolean;
}

const LoginHeading: React.FC<LoginHeadingProps> = ({ showWelcome = true }) => (
  <React.Fragment>
    <img className={styles.logoHelvetia} src={logoHelvetia} alt="logo" />
    {showWelcome && (
      <p className={styles.mainText}>
        Bienvenido al simulador de seguros de Facilsure. Por favor, introduce
        tus datos.
      </p>
    )}
  </React.Fragment>
);

export default LoginHeading;
