import { addDays } from 'date-fns';
import { getAppointments } from '../../api/appointments/getAppointments';
import {
  Appointment,
  AppointmentsDTO,
  GetAppointmentsDTO,
} from '../../types/appointments/appointments';
import { ISO8601DateString } from '../../types/standard';
import createApiModule from '../createApiModule';

const getDayAppointments = (day: ISO8601DateString) => {
  const from = new Date(`${day} 00:00:00`);
  const to = addDays(from, 1);
  const params: GetAppointmentsDTO = {
    cliente_id: '',
    date_from: from.toISOString(),
    date_to: to.toISOString(),
    size: 100,
  };
  return getAppointments(params);
};

const takenAppointmentsModule = createApiModule({
  name: 'takenAppointments',
  initialData: [] as Appointment[],
  parse: (response: AppointmentsDTO) => response.data,
  apiMethod: getDayAppointments,
  onSuccess: () => {},
  onError: () => {},
});

export const {
  start: loadTakenAppointments,
  success: loadTakenAppointmentsOk,
  error: loadTakenAppointmentsKo,
  reset: resetTakenAppointments,
} = takenAppointmentsModule.slice.actions;

export default takenAppointmentsModule;
