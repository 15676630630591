import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import ImportOrderEnforcer from './ImportOrderEnforcer';
import Index from './routes';
import store, { history, persistor } from './store/store';
// See the module to understand what this is
ImportOrderEnforcer();

const App: React.FC<{}> = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <Index />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);

export default App;
