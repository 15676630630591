import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import renderWhen from '../../../helpers/renderWhen';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import {
  changeClientState,
  hideChangeClientState,
} from '../../../store/changeClientState/changeClientStateSlice';
import {
  selectChangeClientStateActive,
  selectChangeClientStateClient,
  selectChangeClientStateErrors,
  selectChangeClientStateLoading,
} from '../../../store/changeClientState/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { ChangeClientStateDTO } from '../../../types/actions/actions';
import { APIError } from '../../../types/api/api';
import { Client } from '../../../types/clients/clients';
import { ClientActionEnum } from '../../../types/clients/client_actions.d';
import Button from '../../Buttons/Button/Button';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';

interface ChangeClientStateProps {
  selectedClient: Client | null;
  serverErrors: APIError | null;
  loading: boolean;
  changeClientState: (data: ChangeClientStateDTO) => void;
  closeModal: () => void;
}

const changeClientStateSchema = yup.object().shape({
  action: yup.string(),
});

const ChangeClientState: React.FC<ChangeClientStateProps> = ({
  selectedClient,
  changeClientState,
  serverErrors,
  loading,
  closeModal,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeClientStateSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  const onSubmit = (data: ChangeClientStateDTO) => {
    if (selectedClient) {
      data.client_id = selectedClient.id;
    }
    changeClientState(data);
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Cambiar estado
        </h1>
        <p className={styles.description}>
          Se va a proceder a cambiar de estado al cliente.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          <ValidatedSelect
            schema={changeClientStateSchema}
            errors={errors}
            label=""
            select={{
              name: 'action',
              ref: register,
            }}
            placeholder="Selecciona estado"
          >
            {selectedClient ? (
              <>
                {selectedClient.canCheckPosibleComprador && (
                  <option value="marcar_posible_comprador">
                    {ClientActionEnum['marcar_posible_comprador']}
                  </option>
                )}
                {selectedClient.canUncheckPosibleComprador && (
                  <option value="desmarcar_posible_comprador">
                    {ClientActionEnum['desmarcar_posible_comprador']}
                  </option>
                )}
                {selectedClient.canGuardar && (
                  <option value="guardar">{ClientActionEnum['guardar']}</option>
                )}
              </>
            ) : null}
          </ValidatedSelect>
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeClientState = connect(
  (state: RootState) => ({
    selectedClient: selectChangeClientStateClient(state),
    serverErrors: selectChangeClientStateErrors(state),
    loading: selectChangeClientStateLoading(state),
  }),
  (dispatch: AppDispatch) => ({
    changeClientState: (payload: ChangeClientStateDTO) => {
      dispatch(changeClientState(payload));
    },
    closeModal: () => {
      return dispatch(hideChangeClientState());
    },
  })
)(ChangeClientState);

export default renderWhen(
  ConnectedChangeClientState,
  selectChangeClientStateActive
);
