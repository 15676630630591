import { Values } from 'ibs-cengine/dist/types/Calculator';
import { Guarantee } from '../../../types/offers/offers';
import { getValueAsNumber, getValueAsString } from '../util';

export function getGarantiaHelvetiaBienestar(
  values: Values,
  who: string,
  order: number
): Guarantee | null {
  return {
    garantia: 'Helvetia Bienestar',
    categoria: 'otros',
    codigo_producto: 'W12',
    codigo_garantia: getValueAsString('.p.w12.o.hb.codigo', values),
    orden: order,
    importe: getValueAsNumber('.o.' + who + '.w12.o.hb.prima', values),
    lineas: [],
    capital: 0,
    aplicable: true,
    aplicar: true,
    editable: false,
    obligatoria: true,
  };
}
