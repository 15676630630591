import React, { useRef, useCallback, useEffect } from 'react';
import styles from './modal.module.scss';
import { ReactComponent as IconClose } from '../../assets/close.svg';

interface ModalPops {
  children: React.ReactChild;
  closeModal: () => void;
}

const Modal: React.FC<ModalPops> = ({ children, closeModal }) => {
  const ref = useRef<HTMLHeadingElement>(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
      }
    },
    [closeModal]
  );

  const handlePressEsc = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handlePressEsc);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handlePressEsc);
    };
  }, [handleClickOutside, handlePressEsc]);

  return (
    <section className={styles.root}>
      <div className={styles.wrapper} ref={ref}>
        <button className={styles.closeButton} onClick={() => closeModal()}>
          <IconClose />
        </button>
        {children}
      </div>
    </section>
  );
};

export default Modal;
