import {
  useForm,
  FieldValues,
  UseFormOptions,
  FormContextValues,
} from 'react-hook-form';

const useOurForm: typeof useForm = <
  FormValues extends FieldValues,
  ValidationContext extends object = object
>(
  opts?: UseFormOptions<FormValues, ValidationContext>
): FormContextValues<FormValues> => {
  let newOpts = opts;
  if (
    process.env.REACT_APP_CLIENTSIDE_VALIDATION_DISABLED &&
    opts?.validationSchema
  ) {
    const { validationSchema, ...wat } = opts;
    newOpts = wat;
  }
  return useForm(newOpts);
};

export default useOurForm;
