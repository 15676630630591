import React from 'react';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ReactComponent as IconReverse } from '../../../assets/moreMenu/reverse.svg';
import useOurForm from '../../../hooks/useOurForm';
import useValidatorAPI from '../../../hooks/useValidatorAPI';
import { changeState, hide } from '../../../store/offer/changeOfferStateModule';
import {
  selectChangeOfferStateErrors,
  selectChangeOfferStateLoading,
  selectChangeOfferStateVisible,
  selectChangeOfferStateData,
} from '../../../store/offer/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { APIError } from '../../../types/api/api';
import { ChangeOfferStateDTO, Offer } from '../../../types/offers/offers';
import Button from '../../Buttons/Button/Button';
import GlobalError from '../../Form/GlobalError/GlobalError';
import ValidatedSelect from '../../Form/ValidatedSelect/ValidatedSelect';
import Loading from '../../Loading/Loading';
import Modal from '../../Modal/Modal';
import styles from '../changeActions.module.scss';
import ButtonsGroup from '../../Buttons/ButtonsGroup/ButtonsGroup';
import renderWhen from '../../../helpers/renderWhen';
import {
  OfferActionEnum,
  OfferAction,
} from '../../../types/offers/offer_actions.d';

const changeOfferStateSchema = yup.object().shape({
  action: yup.string(),
});

interface FormChangeOffer {
  action: OfferAction;
}

interface ChangeOfferStateProps {
  offer: Offer | null;
  serverErrors: APIError | null;
  loading: boolean;
  closeModal: () => void;
  changeOfferState: (dto: ChangeOfferStateDTO) => void;
}

const ChangeOfferState: React.FC<ChangeOfferStateProps> = ({
  offer,
  loading,
  serverErrors,
  closeModal,
  changeOfferState,
}) => {
  const { handleSubmit, register, errors, setError, formState } =
    useOurForm<any>({
      mode: 'onSubmit',
      validationSchema: changeOfferStateSchema,
    });

  const [globalError] = useValidatorAPI(serverErrors, setError, formState);

  const onSubmit = (data: FormChangeOffer) => {
    offer && changeOfferState({ action: data.action, offer: offer });
  };

  return (
    <Modal closeModal={closeModal}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <IconReverse className={styles.icon} />
          Selecciona una acción
        </h1>

        <p className={styles.description}>
          Se va a realizar un cambio de estado en la oferta.
        </p>

        <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
          <ValidatedSelect
            schema={changeOfferStateSchema}
            errors={errors}
            label=""
            select={{
              name: 'action',
              ref: register,
            }}
            placeholder="Seleccionar acción"
          >
            {offer &&
              offer.allowed_actions.map((state) => (
                <option key={state} value={state}>
                  {OfferActionEnum[state]}
                </option>
              ))}
          </ValidatedSelect>
          {/* Server-side global error */}
          {globalError && <GlobalError message={globalError} />}
          {loading ? (
            <Loading />
          ) : (
            <ButtonsGroup variant="center">
              <Button type="button" variant="negative" onClick={closeModal}>
                Cancelar
              </Button>
              <Button variant="positive">Aceptar</Button>
            </ButtonsGroup>
          )}
        </form>
      </div>
    </Modal>
  );
};

const ConnectedChangeOfferState = connect(
  (state: RootState) => ({
    loading: selectChangeOfferStateLoading(state),
    serverErrors: selectChangeOfferStateErrors(state),
    offer: selectChangeOfferStateData(state),
  }),
  (dispatch: AppDispatch) => ({
    changeOfferState: (payload: ChangeOfferStateDTO) =>
      dispatch(changeState(payload)),
    closeModal: () => dispatch(hide()),
  })
)(ChangeOfferState);

export default renderWhen(
  ConnectedChangeOfferState,
  selectChangeOfferStateVisible
);
