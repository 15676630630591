import React, { ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';
import MenuGestion from '../../../gestion/components/Layouts/Menu/Menu';
import isProduction from '../../../helpers/isProduction';
import {
  isAreaExposicionMotivos,
  isAreaSegurpedia,
  isAreaValoracion,
} from '../../../store/app/selectors';
import { logout } from '../../../store/auth/authSlice';
import { selectAuthAvatar } from '../../../store/auth/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import logo from '../../../themes/active/assets/logo.svg';
import NavTopRight from '../../../themes/active/components/Layouts/Layout/NavTopRight';
import MenuValoracion from '../../../valoracion/components/Menus/MainMenu/Menu';
import PopupActions from '../../PopupActions/PopupActions';
import SnackBar from '../../SnackBar/SnackBar';
import DemoEnv from './DemoEnv/DemoEnv';
import styles from './layout.module.scss';

const makeStyleRoute = (location: string) => (path: string) => {
  if (location === '/' && path === location) {
    return `${styles.menuItem} ${styles.isCurrentPage}`;
  } else if (location.startsWith(path) && path !== '/') {
    return `${styles.menuItem} ${styles.isCurrentPage}`;
  } else {
    return `${styles.menuItem}`;
  }
};

interface LayoutProps {
  children: ReactNode;
  userAvatar: string | null;
  styleRoute: (route: string) => string;
  logout: () => void;
  isValoracion: boolean;
  isExposicionMotivos: boolean;
  isSegurpedia: boolean;
  title?: string;
  askBeforeExit: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  userAvatar,
  isValoracion,
  title,
  isExposicionMotivos,
  isSegurpedia,
  askBeforeExit,
  styleRoute,
  logout,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  return (
    <div
      className={`${styles.root} ${
        (isValoracion || isExposicionMotivos || isSegurpedia) &&
        styles.isValoracionRoot
      }`}
    >
      {!isProduction() && <DemoEnv />}
      {askBeforeExit && (
        <Prompt
          message={(location) => {
            return !location.pathname.includes('valoracion') &&
              !location.pathname.includes('ver-oferta')
              ? 'Si sales de la sección perderás los datos no guardados. ¿Quieres continuar?'
              : true;
          }}
        />
      )}
      <header className={styles.header}>
        <Link className={styles.logoLink} to="/">
          <img
            className={styles.logoHelvetiaImg}
            src={logo}
            alt="Logotipo de Helvetia"
          />
        </Link>
        <NavTopRight
          isExposicionMotivos={isExposicionMotivos}
          isSegurpedia={isSegurpedia}
          isValoracion={isValoracion}
          title={title}
          logout={logout}
        />
      </header>
      <MenuGestion
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        styleRoute={styleRoute}
        userAvatar={userAvatar}
      />
      <MenuValoracion
        isOpenMenu={isOpenMenu}
        setIsOpenMenu={setIsOpenMenu}
        styleRoute={styleRoute}
      />
      <main className={`${styles.mainContent}`}>{children}</main>
      <PopupActions />
      <SnackBar />
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    askBeforeExit:
      !state.router.location.pathname.includes('/valoracion/proyectos') &&
      state.router.location.pathname.includes('/valoracion'),
    styleRoute: makeStyleRoute(state.router.location.pathname),
    isValoracion: isAreaValoracion(state),
    isSegurpedia: isAreaSegurpedia(state),
    isExposicionMotivos: isAreaExposicionMotivos(state),
    userAvatar: selectAuthAvatar(state),
  }),
  (dispatch: AppDispatch) => ({
    logout: () => {
      dispatch(logout());
    },
  })
)(Layout);
