import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../components/Layouts/Layout/Layout';
import { allRoles } from '../components/LoginRestrictions/LoginRestrictions';
import { canEditUserGate } from '../store/users/gates';

const Home = lazy(() => import('./Home/Home'));
const NotFound = lazy(() => import('./NotFound/NotFound'));
const NewUser = lazy(() => import('./NewUser/NewUser'));
const NewClient = lazy(() => import('./NewClient/NewClient'));
const UsersList = lazy(() => import('./UsersList/UsersList'));
const ClientsList = lazy(() => import('./ClientsList/ClientsList'));
const Client = lazy(() => import('./Client/Client'));
const PrivateRoute = lazy(
  () => import('../components/Router/PrivateRoute/PrivateRoute')
);
const EditUserWrapper = lazy(() => import('./EditUserWrapper/EditUserWrapper'));
const Profile = lazy(() => import('./Profile/Profile'));
const Schedule = lazy(() => import('./Schedule/Schedule'));
const NewAppointment = lazy(() => import('./NewAppointment/NewAppointment'));
const ProjectsList = lazy(() => import('./ProjectsList/ProjectsList'));
const Project = lazy(() => import('./Project/Project'));
const Statistics = lazy(() => import('./Statistics/Statistics'));
const Challenges = lazy(() => import('./Challenges/Challenges'));
const Alerts = lazy(() => import('./Alerts/Alerts'));
const RequestOffer = lazy(() => import('./Project/RequestOffer/RequestOffer'));

const AppRoutes = () => (
  <Layout>
    <Switch>
      <PrivateRoute allowedRoles={allRoles} exact path="/" component={Home} />
      <PrivateRoute
        exact
        allowedRoles={[
          'admin_int',
          'admin_ibs',
          'gestor_area',
          'director_comercial',
        ]}
        path="/usuarios"
        component={UsersList}
      />
      <PrivateRoute
        exact
        allowedRoles={['admin_int', 'admin_ibs', 'gestor_area']}
        path="/usuarios/nuevo"
        component={NewUser}
      />
      <PrivateRoute
        allowedRoles={['admin_int', 'admin_ibs', 'gestor_area']}
        path="/usuarios/:id/editar"
        gate={canEditUserGate}
        component={EditUserWrapper}
      />
      <PrivateRoute
        allowedRoles={allRoles}
        path="/perfil"
        component={Profile}
      />
      <PrivateRoute
        exact
        allowedRoles={allRoles}
        path="/clientes"
        component={ClientsList}
      />
      <PrivateRoute
        exact
        allowedRoles={['admin_int', 'admin_ibs', 'gestor_area', 'comercial']}
        path="/clientes/nuevo"
        component={NewClient}
      />
      <PrivateRoute
        allowedRoles={allRoles}
        path="/clientes/:id"
        component={Client}
      />
      <PrivateRoute
        exact
        allowedRoles={['gestor_area', 'comercial']}
        path="/agenda"
        component={Schedule}
      />
      <PrivateRoute
        allowedRoles={['gestor_area', 'comercial']}
        path="/agenda/nuevo"
        component={NewAppointment}
      />
      <PrivateRoute
        exact
        allowedRoles={allRoles}
        path="/proyectos"
        component={ProjectsList}
      />
      <PrivateRoute
        allowedRoles={allRoles}
        path="/proyectos/:id"
        component={Project}
      />
      <PrivateRoute
        exact
        allowedRoles={allRoles}
        path="/estadisticas"
        component={Statistics}
      />
      <PrivateRoute
        exact
        allowedRoles={allRoles}
        path="/notificaciones"
        component={Alerts}
      />
      <PrivateRoute
        exact
        allowedRoles={allRoles}
        path="/retos"
        component={Challenges}
      />
      <PrivateRoute
        allowedRoles={[
          'comercial',
          'gestor_area',
          'admin_ibs',
          'admin_int',
          'director_comercial',
        ]}
        path="/ver-oferta/:id"
        component={RequestOffer}
      />
      <Route path="/" component={NotFound} />
    </Switch>
  </Layout>
);

export default AppRoutes;
