import { selectHasErrors, selectHasGlobalErrors } from '../schemas/selectors';
import { RootState } from '../store';

export const selectDataCollection = (state: RootState) => state.dataCollection;

export const selectInsuredData = (state: RootState) =>
  selectDataCollection(state)?.principal;

export const selectConyugeData = (state: RootState) =>
  selectDataCollection(state)?.conyuge;

export const selectChildrenData = (state: RootState) =>
  selectDataCollection(state)?.hijos;

export const selectDataCollectionClientId = (state: RootState) =>
  state.valoracion.ready ? state.valoracion.origin.project.cliente.id : null;

export const selectHasConyuge = (state: RootState) =>
  state.dataCollection.principal.estado_civil === 'casado' ||
  state.dataCollection.principal.estado_civil === 'pareja_hecho';

export const gastosCompartidos = (state: RootState) =>
  selectHasConyuge(state) &&
  state.dataCollection.conyuge.necesidades_compartidas;

export const selectComputeDoubleOffer = (state: RootState) =>
  selectConyugeData(state)?.necesidades_compartidas === true;

export const selectHasRequiredData = (state: RootState) =>
  selectDataCollection(state)?.has_required_data;

export const selectCanViewAnalysis = (state: RootState) =>
  selectHasRequiredData(state) && !selectHasErrors(state);

export const selectCanEditOffer = (state: RootState) =>
  selectHasRequiredData(state) && !selectHasGlobalErrors(state);

export const selectHasChildren = (state: RootState) =>
  selectDataCollection(state)?.hijos.length > 0;
