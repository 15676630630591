import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { resetPassword } from '../../../../api/auth/auth';
import { Await } from '../../../../types/api/api';
import { ResetPasswordDTO } from '../../../../types/auth/auth';
import {
  resetPassword as resetPasswordAction,
  resetPasswordKo,
  resetPasswordOk,
} from './passwordRestrictionSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* resetPasswordSaga(
  action: PayloadAction<ResetPasswordDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(resetPassword, {
      password: action.payload.password,
      password_confirmation: action.payload.password_confirmation,
    })) as Await<ReturnType<typeof resetPassword>>;
    switch (result.type) {
      case 'ok':
        yield put(resetPasswordOk(result.value));
        return;
      case 'validation-error':
        yield put(resetPasswordKo(result.value));
        return;
    }
  } catch (e) {
    yield put(resetPasswordKo(e));
  }
}

const sagas = [
  takeLatest<PayloadAction<ResetPasswordDTO>>(
    resetPasswordAction,
    resetPasswordSaga
  ),
];

export default sagas;
