import { User } from '../../types/users/users';
import { validatedApiCall, Result } from '../api';
import { APIValidationError } from '../../types/api/api';

export const resetUserPassword = async (
  data: User
): Promise<Result<User, APIValidationError>> =>
  validatedApiCall<User>(`/api/users/${data.id}/reset_password`, {
    method: 'PATCH',
    auth: true,
  });
