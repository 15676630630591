import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { call } from 'redux-saga/effects';
import { changeResponsable } from '../../api/users/changeResponsable';
import { ChangeResponsableDTO } from '../../types/actions/actions';
import { Await } from '../../types/api/api';
import snackBarSlice from '../snackBar/snackBarSlice';
import changeResponsableSlice from './changeResponsableSlice';

function* registerSaga(
  action: PayloadAction<ChangeResponsableDTO>
): Generator<any, void, any> {
  try {
    const result = (yield call(changeResponsable, action.payload)) as Await<
      ReturnType<typeof changeResponsable>
    >;
    switch (result.type) {
      case 'ok':
        yield put(changeResponsableSlice.actions.changeResponsableOk());
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'El cambio de responsable se ha realizado correctamente.',
            severity: 'success',
          })
        );
        yield put(changeResponsableSlice.actions.hideChangeResponsable());
        return;
      case 'validation-error':
        yield put(
          changeResponsableSlice.actions.changeResponsableKo(result.value)
        );
        yield put(
          snackBarSlice.actions.showSnackBar({
            message: 'No se ha podido realizar el cambio.',
            severity: 'error',
          })
        );
        return;
    }
  } catch (e) {
    yield put(changeResponsableSlice.actions.changeResponsableKo(e));
    yield put(
      snackBarSlice.actions.showSnackBar({
        message: 'No se ha podido realizar el cambio.',
        severity: 'error',
      })
    );
  }
}

const sagas = [
  takeLatest<PayloadAction<ChangeResponsableDTO>>(
    changeResponsableSlice.actions.changeResponsable.type,
    registerSaga
  ),
];

export default sagas;
