import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { User } from '../../types/users/users';

export type UsersState = {
  loading: boolean;
  errors: APIError | null;
  users: User[];
};

const initialState: UsersState = {
  loading: false,
  errors: null,
  users: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers(state) {
      state.loading = true;
      state.errors = null;
    },
    getUsersOk(state, action: PayloadAction<User[]>) {
      state.loading = false;
      state.errors = null;
      state.users = action.payload;
    },
    getUsersKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
      state.users = [];
    },
  },
});

export const { getUsers, getUsersOk, getUsersKo } = usersSlice.actions;

export default usersSlice;
