import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIError } from '../../types/api/api';
import { ChangeAreaDTO } from '../../types/actions/actions';
import { User } from '../../types/users/users';

export type ChangeAreaState = {
  loading: boolean;
  errors: APIError | null;
  active: boolean;
  user: User | null;
};

const initialState: ChangeAreaState = {
  loading: false,
  errors: null,
  active: false,
  user: null,
};

const changeAreaSlice = createSlice({
  name: 'changeArea',
  initialState,
  reducers: {
    showChangeArea(state, action: PayloadAction<User>) {
      state.user = action.payload;
      state.active = true;
    },
    hideChangeArea() {
      return { ...initialState };
    },
    changeArea(state, _action: PayloadAction<ChangeAreaDTO>) {
      state.loading = true;
      state.errors = null;
    },
    changeAreaOk(state) {
      state.loading = false;
      state.errors = null;
    },
    changeAreaKo(state, action: PayloadAction<APIError>) {
      state.loading = false;
      state.errors = action.payload;
    },
  },
});

export const {
  showChangeArea,
  hideChangeArea,
  changeArea,
  changeAreaOk,
  changeAreaKo,
} = changeAreaSlice.actions;

export default changeAreaSlice;
