import React from 'react';
import styles from './tooltip.module.scss';

interface TooltipProps {
  text: string | JSX.Element;
}

const Tooltip: React.FC<TooltipProps> = ({ text }) => (
  <div className={styles.tooltip}>{text}</div>
);

export default Tooltip;
