import { all } from '@redux-saga/core/effects';
import getAlertsModule from './alerts/alertsModule';
import newAlertsModule from './alerts/newAlertsModule';
import readAlertsModule from './alerts/readAlertsModule';
import alertsSagas from './alerts/sagas';
import areasSagas from './areas/sagas';
import authSagas from './auth/sagas';
import averageExpenditureSagas from './averageExpenditure/sagas';
import capitalModificationSagas from './capitalModification/sagas';
import challengesModule from './challenges/challengesModule';
import changeAreaSagas from './changeArea/sagas';
import changeClientStateSagas from './changeClientState/sagas';
import changeResponsableSagas from './changeResponsable/sagas';
import changeRoleSagas from './changeRole/sagas';
import clientModule from './client/clientModule';
import clientProjectsModule from './client/clientProjectsModule';
import clientSearchModule from './client/clientSearchModule';
import commentsModule from './client/commentsModule';
import editClientModule from './client/editClientModule';
import newCommentModule from './client/newCommentModule';
import updateClientModule from './client/updateClientModule';
import clientAppointmentsModule from './clientAppointments/clientAppointmentsModule';
import clientsSagas from './clients/sagas';
import dataCollectionSagas from './dataCollection/sagas';
import dictionaryModule from './dictionary/dictionaryModule';
import familyResponsabilitiesSagas from './familyResponsabilites/sagas';
import changeOfferStateModule from './offer/changeOfferStateModule';
import newOfferModule from './offer/newOfferModule';
import sendPdfModule from './offer/sendPdfModule';
import getOffersModule from './offers/getOffersModule';
import poblacionSagas from './poblacion/sagas';
import updatePolicyModule from './policy/updatePolicyModule';
import changeProjectStateModule from './project/changeProjectStateModule';
import newProjectModule from './project/newProjectModule';
import projectModule from './project/projectModule';
import projectsModule from './projects/projectsModule';
import registerClientSagas from './registerClient/sagas';
import requestOfferModule from './requestOffer/requestOfferModule';
import newAppointmentModule from './schedule/newAppointmentModule';
import removeAppointmentModule from './schedule/removeAppointmentModule';
import scheduleModule from './schedule/scheduleModule';
import takenAppointmentsModule from './schedule/takenAppointmentsModule';
import upcomingAppointmentsModule from './schedule/upcomingAppointmentsModule';
import schemasSagas from './schemas/sagas';
import getStatisticsModule from './statistics/getStatisticsModule';
import editUserModule from './users/editUserModule';
import registerUserModule from './users/registerUserModule';
import resetUserPasswordModule from './users/resetUserPasswordModule';
import usersSagas from './users/sagas';
import valoracionSagas from './valoracion/sagas';

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...registerUserModule.sagas,
    ...registerClientSagas,
    ...areasSagas,
    ...usersSagas,
    ...editUserModule.sagas,
    ...resetUserPasswordModule.sagas,
    ...changeResponsableSagas,
    ...changeRoleSagas,
    ...poblacionSagas,
    ...clientsSagas,
    ...clientSearchModule.sagas,
    ...clientProjectsModule.sagas,
    ...scheduleModule.sagas,
    ...upcomingAppointmentsModule.sagas,
    ...newAppointmentModule.sagas,
    ...takenAppointmentsModule.sagas,
    ...clientAppointmentsModule.sagas,
    ...clientModule.sagas,
    ...editClientModule.sagas,
    ...commentsModule.sagas,
    ...newCommentModule.sagas,
    ...projectsModule.sagas,
    ...projectModule.sagas,
    ...changeProjectStateModule.sagas,
    ...updatePolicyModule.sagas,
    ...getOffersModule.sagas,
    ...removeAppointmentModule.sagas,
    ...schemasSagas,
    ...changeOfferStateModule.sagas,
    ...getStatisticsModule.sagas,
    ...dictionaryModule.sagas,
    ...capitalModificationSagas,
    ...newProjectModule.sagas,
    ...newOfferModule.sagas,
    ...challengesModule.sagas,
    ...getAlertsModule.sagas,
    ...readAlertsModule.sagas,
    ...alertsSagas,
    ...newAlertsModule.sagas,
    ...valoracionSagas,
    ...changeAreaSagas,
    ...changeClientStateSagas,
    ...requestOfferModule.sagas,
    ...updateClientModule.sagas,
    ...familyResponsabilitiesSagas,
    ...averageExpenditureSagas,
    ...sendPdfModule.sagas,
    ...dataCollectionSagas,
  ]);
}
