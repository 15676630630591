import {
  selectCanViewOfferList,
  selectHasGlobalErrors,
  selectIsOfferComputed,
} from '../schemas/selectors';
import { RootState } from '../store';
import {
  selectValoracionProject,
  selectValoracionState,
} from '../valoracion/selectors';
import { selectHasRequiredData } from './selectors';

export const canAccessDataCollection = (state: RootState) =>
  selectValoracionProject(state) !== null;

export const canAccessAnalisis = (state: RootState) =>
  selectHasRequiredData(state);

export const canViewComputedOffer = (state: RootState) =>
  selectIsOfferComputed(state) && !selectHasGlobalErrors(state);

export const canViewOfferList = (state: RootState) =>
  selectCanViewOfferList(state);

export const canModifyOfferCapital = (state: RootState) =>
  selectValoracionState(state) === 'loading' ||
  selectValoracionState(state) === 'ready';
