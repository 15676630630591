import React from 'react';
import styles from './table.module.scss';
import { TableVariant } from '../types';

interface TableProps {
  children: React.ReactNode;
  variant?: TableVariant;
  nColumns?: number;
}

const tableStyle = (variant: TableVariant): string => {
  switch (variant) {
    case 'box':
      return styles.isBox;
    case 'default':
      return '';
  }
};

const Table: React.FC<TableProps> = ({
  children,
  variant = 'default',
  nColumns,
}) => {
  return (
    <table
      className={`${styles.table} ${tableStyle(variant)} ${
        nColumns ? styles[`is${nColumns}Columns`] : ''
      }`}
    >
      {children}
    </table>
  );
};

export default Table;
