import { removeAppointment as apiMethod } from '../../api/appointments/removeAppointment';
import { put, takeLatest } from '@redux-saga/core/effects';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { refreshAppointments } from './scheduleModule';
import { GenericState } from '../../types/apiModule';
import { Appointment } from '../../types/appointments/appointments';
import { PayloadAction } from '@reduxjs/toolkit';

export interface removeAppointmentState extends GenericState<unknown> {
  active: boolean;
  data: Appointment[];
  selectedAppointment: Appointment | null;
}
const initialState: removeAppointmentState = {
  errors: null,
  loading: false,
  data: [],
  active: false,
  selectedAppointment: null,
};

const removeAppointmentModule = createApiModule({
  name: 'removeAppointment',
  initialState,
  apiMethod,
  onSuccess: () => [
    showSnackBar({
      severity: 'success',
      message: 'La cita se ha eliminado correctamente.',
    }),
    refreshAppointments(),
  ],
  onError: () => ({
    message: 'Se ha producido un error al eliminar la cita.',
  }),
  onValidationError: () => ({
    message: 'Se ha producido un error al eliminar la cita.',
  }),
  reducers: {
    show(state, action: PayloadAction<Appointment>) {
      state.active = true;
      state.selectedAppointment = action.payload;
    },
    hide(state) {
      state.active = false;
    },
  },
});

export const {
  start: removeAppointment,
  success: removeAppointmentOk,
  error: removeAppointmentKo,
  show,
  hide,
} = removeAppointmentModule.slice.actions;

function* hideSaga() {
  yield put(hide());
}
removeAppointmentModule.sagas = [
  takeLatest<PayloadAction<unknown>>(removeAppointmentOk.type, hideSaga),
  ...removeAppointmentModule.sagas,
];

export default removeAppointmentModule;
