import React, { ReactElement, useEffect } from 'react';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema } from 'yup';
import useIsRequired from '../../../hooks/useIsRequired';
import { selectAuthId } from '../../../store/auth/selectors';
import {
  searchClients,
  SearchClientsDTO,
} from '../../../store/client/clientSearchModule';
import {
  selectClientSearchFound,
  selectClientSearchLoading,
} from '../../../store/client/selectors';
import { AppDispatch, RootState } from '../../../store/store';
import { Client } from '../../../types/clients/clients';
import { EstadoCliente } from '../../../types/clients/estado_cliente';
import { UUID } from '../../../types/standard';
import InputField, { InputFieldProps } from '../InputField/InputField';
import styles from '../InputField/inputField.module.scss';

type Show = 'allClients' | 'onlyDirectClients';

interface ClientSearchFieldProps<T extends FieldValues>
  extends InputFieldProps {
  input: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    name: keyof T;
  };
  errors: FieldErrors<T>;
  schema: ObjectSchema<T>;
  label: string | ReactElement;
  estados?: EstadoCliente[];
  show: Show;
  loading: boolean;
  foundClients: Client[];
  userId: UUID;
  searchClient: (payload: SearchClientsDTO) => void;
  setValue: (field: keyof T, value: string) => void;
  clearError: (field: keyof T) => void;
}

const UnconnectedClientSearchField = <T extends FieldValues>({
  show,
  foundClients,
  loading,
  userId,
  estados,
  searchClient,
  errors,
  schema,
  input,
  label,
  setValue,
  clearError,
  ...props
}: ClientSearchFieldProps<T>) => {
  const id = input.id || input.name;
  const datalistId = `${id}-suggestions`;
  const fname = input.name as string;
  const error = errors && (errors[input.name] as FieldError);

  const required = useIsRequired(fname, schema);

  useEffect(() => {
    searchClient({
      query: '',
      usuario: show === 'onlyDirectClients' ? userId : '',
      estado: estados ? estados : [],
    });
  }, [estados, searchClient, show, userId]);

  return (
    <React.Fragment>
      <datalist id={datalistId}>
        {loading ? (
          <option key="loading" value="Buscando..."></option>
        ) : foundClients.length ? (
          foundClients.map((client) => (
            <option
              key={client.id}
              data-id={client.id}
              value={`${client.nombre} ${client.apellido1} ${client.apellido2}`.trim()}
            >
              {client.nombre} {client.apellido1} {client.apellido2}
            </option>
          ))
        ) : (
          <option
            key="notfound"
            value="No se ha encontrado ningún cliente"
          ></option>
        )}
      </datalist>
      <InputField
        {...props}
        error={error}
        variant="noErrorColor"
        // We change the label directly intead of passing the required prop
        label={
          label && required ? (
            <React.Fragment>
              {label}
              <span className={styles.asterisk}>{'\u00a0*'}</span>
            </React.Fragment>
          ) : (
            label
          )
        }
        input={{
          ...input,
          name: input.name + '-display',
          ref: undefined,
          onChange: (e) => {
            const value = e.currentTarget.value;
            const client = foundClients.find(
              (c) =>
                value === `${c.nombre} ${c.apellido1} ${c.apellido2}`.trim()
            );
            if (client) {
              setValue(input.name, client.id);
              clearError(input.name);
            } else {
              searchClient({
                query: e.currentTarget.value,
                usuario: show === 'onlyDirectClients' ? userId : '',
                estado: estados ? estados : [],
              });
              setValue(input.name, '');
            }
          },
          list: datalistId,
          autoComplete: 'off',
        }}
      />
      <input
        type="hidden"
        data-testid={input.name}
        name={input.name}
        ref={input.ref}
      />
    </React.Fragment>
  );
};

const ClientSearchField = <T extends FieldValues>() =>
  connect(
    (state: RootState) => ({
      loading: selectClientSearchLoading(state),
      foundClients: selectClientSearchFound(state),
      userId: selectAuthId(state)!,
    }),
    (dispatch: AppDispatch) => ({
      searchClient: (payload: SearchClientsDTO) =>
        dispatch(searchClients(payload)),
    })
  )(
    UnconnectedClientSearchField as (
      props: ClientSearchFieldProps<T>
    ) => JSX.Element
  );

export default ClientSearchField;
