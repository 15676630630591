import { delay, put, select, take, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_CHANGE } from 'connected-react-router';
import { call } from 'redux-saga/effects';
import { getAreas } from '../../api/areas/getAreas';
import { Await } from '../../types/api/api';
import authSlice from '../auth/authSlice';
import { selectIsLoggedIn } from '../auth/selectors';
import areasSlice from './areasSlice';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getAreasSaga(): Generator<any, void, any> {
  try {
    yield put(areasSlice.actions.getAreas());
    const result = (yield call(getAreas)) as Await<ReturnType<typeof getAreas>>;
    switch (result.type) {
      case 'ok':
        yield put(areasSlice.actions.getAreasOk(result.value.data));
        return;
      case 'validation-error':
        yield put(areasSlice.actions.getAreasKo(result.value));
        return;
    }
  } catch (e) {
    yield put(areasSlice.actions.getAreasKo(e));
  }
}

// retrieve areas when the page is reloaded as long as the user remains logged in
function* reloadAreas(): Generator<any, void, any> {
  yield take(LOCATION_CHANGE);
  if (selectIsLoggedIn(yield select())) {
    yield getAreasSaga();
  }
}

// For now, we reload this information every minute, but the correct way to do this would be for the API to notify us when there is a change.
function* getAreasEveryMinute(): Generator<any, void, any> {
  while (true) {
    try {
      // We only make the request when the user is logged in
      if (yield select(selectIsLoggedIn)) {
        yield call(getAreas);
      }
      // And this should never stop
    } catch (err) {}
    yield delay(60 * 1000);
  }
}

const sagas = [
  takeLatest<PayloadAction<never>>(
    authSlice.actions.loginOk.type,
    getAreasSaga
  ),
  reloadAreas(),
  getAreasEveryMinute(),
];

export default sagas;
