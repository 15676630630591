import { NewProjectDTO, Project } from '../../types/projects/projects';
import { Result, validatedApiCall } from '../api';
import { APIValidationError } from '../../types/api/api';

export const createProject = async (
  project: NewProjectDTO
): Promise<Result<Project, APIValidationError>> => {
  return validatedApiCall<Project>(`/api/proyectos`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify(project),
  });
};
