import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { changeOfferState } from '../../api/offers/changeOfferState';
import { GenericState } from '../../types/apiModule';
import createApiModule from '../createApiModule';
import { showSnackBar } from '../snackBar/snackBarSlice';
import { Offer } from '../../types/offers/offers';
import { getOffers } from '../offers/getOffersModule';
import { getProject } from '../project/projectModule';

export interface ChangeOfferState extends GenericState<unknown> {
  visible: boolean;
  data: Offer | null;
}
const initialState: ChangeOfferState = {
  errors: null,
  loading: false,
  visible: false,
  data: null,
};

const changeOfferStateModule = createApiModule({
  name: 'changeOfferState',
  apiMethod: changeOfferState,
  initialState,
  onSuccess: (offer: Offer) => [
    getProject(offer.proyecto_id),
    getOffers(offer.proyecto_id),
    showSnackBar({
      severity: 'success',
      message: 'Oferta actualizada correctamente',
      path:
        (offer.estado === 'poliza_aceptada' &&
          `/proyectos/${offer.proyecto_id}/polizas/nueva`) ||
        undefined,
    }),
  ],
  onError: () => ({
    message: 'No hemos podido cambiar el estado de la oferta',
  }),
  onValidationError: () => {},
  reducers: {
    hide(state) {
      state.visible = false;
    },
    show(state, offer: PayloadAction<Offer>) {
      state.data = offer.payload;
      state.visible = true;
    },
  },
});

export const {
  start: changeState,
  success: changeStateOk,
  error: changeStateKo,
  show,
  hide,
} = changeOfferStateModule.slice.actions;

function* hideSaga() {
  yield put(hide());
}
changeOfferStateModule.sagas = [
  takeLatest<PayloadAction<unknown>>(changeStateOk.type, hideSaga),
  ...changeOfferStateModule.sagas,
];

export default changeOfferStateModule;
